import React, { useEffect, useState } from 'react';
import Table from './HTMLTable';
import GroupHtmlTable from './groupHTMLTable';
import AlertBox from './alert';
import EmailPopup from './emailPopup';
import moment from 'moment';
import { Redirect } from 'react-router';
import { getExcelSpecific, getExcelStandard } from '../../helper/excel';
const HIDE_DIALOG = 'hide-dialog';
const SHOW_DIALOG = 'show-dialog';
const REPORT_RESULT = 'report-result';
let JWT;

/**
 * 
 * @param {*} props 
 * Params: 
 *      1. url to fetch the data from api and show in the table.
 *      2. titles for the table head.
 *      3. dataNames are the attribute names in the object.
 *
 */

const ReportDialog = (props) => {
	const hyperLinks = props.hyperLinks;
	const body = props.body;
	const URL = props.url;
	const PDF_URL = props.pdfUrl;
	const SKU_URL = props.skuUrl;
	const EMAIL_URL = props.emailUrl;

	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [title, setTitle] = useState('');
	const [closeReport, setCloseReport] = useState();
	const [reportNumber, setReportNumber] = useState('');
	//Error Modal
	const [visible, setVisible] = useState(false);

	const handleCancel = () => {
		setVisible(false);
	};
	//Email Modal
	const [emailVisible, setEmailVisible] = useState(false);

	const handleEmailCancel = () => {
		setEmailVisible(false);
	};


	useEffect(() => {
		JWT = localStorage.getItem('access_token');
	}, []);

	useEffect(
		() => {
			if (props.display == true) {
				showDialog();
				fetchData();
			}
		},
		[props.display]
	);

	async function fetchData() {
		setLoading(true);
		let url = URL;
		// url += '&fromDate=' + moment(props.fromDate).format('MM/DD/YYYY') + '&toDate=' + moment(props.toDate).format('MM/DD/YYYY');
		url += '&fromDate=' + "01/01/2021" + '&toDate=' + moment(props.fromDate).format('MM/DD/YYYY');
		const response = await fetch(url, {
			method: 'POST',
			headers: { Authorization: JWT, Accept: 'application/json', 'Content-Type': 'application/json' },
			body: JSON.stringify(body)
		}).catch((err) => {
			setVisible(true);
			return;
		});

		if (response) {
			const data = await response.json();
			setData(data);
			setLoading(false);
			setTitle(data.reportName);
			setReportNumber(data.reportNumber);
			setVisible(false);
		}
	}

	function showDialog() {
		if (document != null && document.getElementById(REPORT_RESULT) != null) {
			const element = document.getElementById(REPORT_RESULT);
			if (element.classList.contains(HIDE_DIALOG)) {
				element.classList.add(SHOW_DIALOG);
				element.classList.remove(HIDE_DIALOG);
			}
		}
	}

	function hideDialog() {
		setData([]);
		if (document != null && document.getElementById(REPORT_RESULT) != null) {
			const element = document.getElementById(REPORT_RESULT);
			if (element.classList.contains(SHOW_DIALOG)) {
				element.classList.add(HIDE_DIALOG);
				element.classList.remove(SHOW_DIALOG);
				if (props.closeReport) {
					setCloseReport(true);
				}
				props.callBack();
			}
		}
	}

	async function getPDF() {
		const win = window.open(PDF_URL + '&payload=' + JSON.stringify(body), '_blank');
		win.focus();
	}

	//Show Email popup

	async function showEmailPopup() {
		setEmailVisible(true);
	}

	//Send Email

	async function sendEmail(to, subject, message) {
		body.to = to;
		body.subject = subject;
		body.message = message;
		let url = EMAIL_URL + '&payload=' + JSON.stringify(body);
		let fetchRes = fetch(url);
		fetchRes.then(res =>
			res.json()).then(d => {
				if (d) {

				}
			})
	}

	return (
		<div id="report-result" className="report-result hide-dialog">
			<div className="report-result-head">
				<h2>{title}</h2><br></br>
				<h2>{reportNumber ? reportNumber : '-'}</h2>
				<div className="report-result-right-top">
					{
						data.rows && data.rows.length > 0 ? <button
							id="download-excel-button"
							onClick={() => {
								props.excelVersion ? getExcelSpecific(data, props.TableClassName) : getExcelStandard(data, props.TableClassName);
							}}
						>
							Download EXCEL
						</button> :
							null}
					{
						PDF_URL && data.rows && data.rows.length > 0 ? <button
							id="download-pdf-button"
							onClick={getPDF}
						>
							Download PDF
						</button> :
							null}

					{
						EMAIL_URL && data.rows && data.rows.length > 0 ? <button
							id="email-button"
							onClick={showEmailPopup}
						>
							Send Email
						</button> :
							null}


					<button id="close-dialog-btn" onClick={() => hideDialog(true)} >Close</button>
					{closeReport == true ? <Redirect to="/dashboard" /> : null}
				</div>
			</div>

			<div className="report-data">
				{
					props.table ? <GroupHtmlTable
						sorter={props.sorter}
						table={props.table}
						display={props.display}
						callBack={props.callBack}
						tableHeight={props.tableHeight}
						TableClassName={props.TableClassName}
						reorder={props.reorder}
						defaultPageSize={props.defaultPageSize}
						skuUrl={SKU_URL}
						data={data}
						loading={loading}
						body={body}
						columnAttributes={props.columnAttributes}
						hyperLinks={hyperLinks}
						hyperLinksUrl={props.hyperLinksUrl}
						popupTableTitle={props.popupTableTitle}

					/> :
						<Table
							columnAttributes={props.columnAttributes}
							sorter={props.sorter}
							display={props.display}
							callBack={props.callBack}
							tableHeight={props.tableHeight}
							TableClassName={props.TableClassName}
							defaultPageSize={props.defaultPageSize}
							skuUrl={SKU_URL}
							data={data}
							loading={loading}
							body={body}
							hyperLinks={hyperLinks}
							hyperLinksUrl={props.hyperLinksUrl}
							popupTableTitle={props.popupTableTitle}
							popUpClassName={props.popUpClassName}

						/>}
			</div>
			<AlertBox handleCancel={handleCancel} visible={visible} />
			<EmailPopup
				loading={loading}
				callBack={(a) => {
					let to = a.to.map((x) => x.value).join(', ');
					sendEmail(to, a.subject, a.message)
					setTimeout(() => {
						setEmailVisible(false);
					}, 5000)
				}}
				handleCancel={handleEmailCancel} visible={emailVisible} />
		</div>
	);
};
export default ReportDialog;