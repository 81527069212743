import React, { useState, useLayoutEffect } from "react";
import AutoCompleteSelect from "../../common/autoCompleteSelect";
import useDidMountEffect from "../../common/useDidMountEffect";
import config from "../../../config/config";
import moment from "moment";
const LOCAL_URL = config.GET_ALL_URL;
let JWT;

const SalesComparisonReportFilter = (props) => {
	const [otherThanFirst, setOtherThanFirst] = useState(true);
	const [dateTypeId, setDateTypeId] = useState("-1");
	const [monthId, setMonthId] = useState("-1");
	const [yearId, setYearId] = useState("-1");
	const [dateTypes, setDateTypes] = useState([
		{
			label: "Year To Date",
			value: "ytd",
		},
		{
			label: "Year To End",
			value: "yte",
		},
		{
			label: "Month To Date",
			value: "mtd",
		},
		{
			label: "Month To End",
			value: "mte",
		},
		{
			label: "Spring",
			value: "spr",
		},
		{
			label: "Fall",
			value: "fal",
		},
	]);

	const [months, setMonths] = useState([
		{
			label: "January",
			value: "01",
		},
		{
			label: "February",
			value: "02",
		},
		{
			label: "March",
			value: "03",
		},
		{
			label: "April",
			value: "04",
		},

		{
			label: "May",
			value: "05",
		},
		{
			label: "June",
			value: "06",
		},

		{
			label: "July",
			value: "07",
		},
		{
			label: "August",
			value: "08",
		},
		{
			label: "September",
			value: "09",
		},
		{
			label: "October",
			value: "10",
		},
		{
			label: "November",
			value: "11",
		},
		{
			label: "December",
			value: "12",
		},
	]);

	const [years, setYears] = useState([
		{
			label: "2020",
			value: 2020,
		},
		{
			label: "2021",
			value: 2021,
		},
		{
			label: "2022",
			value: 2022,
		},
		{
			label: "2023",
			value: 2023,
		},
	]);

	const [dateTypeLoading, setDateTypeLoading] = useState(false);
	const [monthsLoading, setMonthsLoading] = useState(false);
	const [yearsLoading, setYeasrLoading] = useState(false);

	const [categoryId, setcategoryId] = useState("-1");
	const [regionId, setRegionId] = useState("-1");
	const [salePersonId, setSalesPersonId] = useState("-1");
	const [genderId, setGenderId] = useState("-1");

	const [customers, setCustomers] = useState([]);
	const [regions, setRegions] = useState([]);
	const [salesPersons, setSalesPersons] = useState([]);
	const [genders, setGenders] = useState([]);

	const [customersLoading, setCustomersLoading] = useState(true);
	const [regionsLoading, setRegionsLoading] = useState(true);
	const [salesPersonLoading, setSalesPersonLoading] = useState(true);
	const [genderLoading, setGenderLoading] = useState(true);

	const [currentYearStartDate, setCurrentYearStartDate] = useState(moment().startOf("year").format("MM/DD/YYYY"));
	const [currentYearEndDate, setCurrentYearEndDate] = useState(moment().format("MM/DD/YYYY"));

	const [lastYearStartDate, setLastYearStartDate] = useState(moment().startOf("year").subtract(1, "year").format("MM/DD/YYYY"));
	const [lastYearEndDate, setLastYearEndDate] = useState(moment().subtract(1, "year").format("MM/DD/YYYY"));

	const currentYearStartDateYte = moment().startOf("year").format("MM/DD/YYYY");
	const currentYearEndDateYte = moment().endOf("year").format("MM/DD/YYYY");
	const currentYearEndDateYtd = moment().format("MM/DD/YYYY");

	const lastYearStartDateYte = moment().startOf("year").subtract(1, "year").format("MM/DD/YYYY");
	const lastYearEndDateYte = moment().endOf("year").subtract(1, "year").format("MM/DD/YYYY");
	const lastYearEndDateYtd = moment().subtract(1, "year").format("MM/DD/YYYY");

	const currentYearStartDateMte = monthId + "/" + "01" + "/" + yearId;
	const currentYearEndDateMte = monthId + "/" + moment(`${yearId} / ${monthId}`, "YYYY/MM").daysInMonth() + "/" + yearId;

	const lastYearStartDateMte = monthId + "/" + "01" + "/" + (yearId - 1);
	const lastYearEndDateMte = monthId + "/" + moment(`${yearId - 1} / ${monthId}`, "YYYY/MM").daysInMonth() + "/" + (yearId - 1);

	useState(() => {
		JWT = localStorage.getItem("access_token");
	}, []);

	function getNewState() {
		return {
			currentYearStartDateYte: currentYearStartDateYte,
			currentYearEndDateYte: currentYearEndDateYte,
			currentYearEndDateYtd: currentYearEndDateYtd,
			lastYearStartDateYte: lastYearStartDateYte,
			lastYearEndDateYte: lastYearEndDateYte,
			lastYearEndDateYtd: lastYearEndDateYtd,
			currentYearStartDateMte: currentYearStartDateMte,
			currentYearEndDateMte: currentYearEndDateMte,
			lastYearStartDateMte: lastYearStartDateMte,
			lastYearEndDateMte: lastYearEndDateMte,

			currentYearStartDate: currentYearStartDate,
			currentYearEndDate: currentYearEndDate,
			lastYearStartDate: lastYearStartDate,
			lastYearEndDate: lastYearEndDate,
			categoryId: categoryId,
			regionId: regionId,
			salePersonId: salePersonId,
			genderId: genderId,
		};
	}

	useLayoutEffect(() => {
		if (otherThanFirst) {
			const newState = {
				currentYearStartDateYte: currentYearStartDateYte,
				currentYearEndDateYte: currentYearEndDateYte,
				currentYearEndDateYtd: currentYearEndDateYtd,
				lastYearStartDateYte: lastYearStartDateYte,
				lastYearEndDateYte: lastYearEndDateYte,
				lastYearEndDateYtd: lastYearEndDateYtd,
				currentYearStartDateMte: currentYearStartDateMte,
				currentYearEndDateMte: currentYearEndDateMte,
				lastYearStartDateMte: lastYearStartDateMte,
				lastYearEndDateMte: lastYearEndDateMte,

				currentYearStartDate: currentYearStartDate,
				currentYearEndDate: currentYearEndDate,
				lastYearStartDate: lastYearStartDate,
				lastYearEndDate: lastYearEndDate,
				categoryId: "-1",
				salePersonId: "-1",
				regionId: "-1",
				genderId: "-1",
			};
			props.filterData(newState);
			getCustomer(newState);
		}
	}, []);

	useDidMountEffect(() => {
		const newState = getNewState();
		props.filterData(newState);
	}, [categoryId, regionId, salePersonId, genderId]);

	useDidMountEffect(() => {
		let year = yearId;
		let month = monthId;

		let currentYearStartDate = moment().startOf("year").format("MM/DD/YYYY");
		let currentYearEndDate = moment().endOf("year").format("MM/DD/YYYY");
		let lastYearStartDate = moment().startOf("year").subtract(1, "year").format("MM/DD/YYYY");
		let lastYearEndDate = moment().endOf("year").subtract(1, "year").format("MM/DD/YYYY");

		if (dateTypeId === "yte") {
			currentYearStartDate = moment().startOf("year").format("MM/DD/") + year;
			currentYearEndDate = moment().endOf("year").format("MM/DD/") + year;
			lastYearStartDate = moment().startOf("year").subtract(1, "year").format("MM/DD/") + (year - 1);
			lastYearEndDate = moment().subtract(1, "year").endOf("year").format("MM/DD/") + (year - 1);
		} else if (dateTypeId === "ytd") {
			currentYearStartDate = moment().startOf("year").format("MM/DD/") + year;
			currentYearEndDate = moment().format("MM/DD/") + year;
			lastYearStartDate = moment().startOf("year").subtract(1, "year").format("MM/DD/") + (year - 1);
			lastYearEndDate = moment().subtract(1, "year").format("MM/DD/") + (year - 1);
		} else if (dateTypeId === "mtd") {
			currentYearStartDate = month + "/" + "01" + "/" + year;
			currentYearEndDate = moment().format("MM") + "/" + moment().format("DD") + "/" + year;
			lastYearStartDate = month + "/" + "01" + "/" + (year - 1);
			lastYearEndDate = moment().format("MM") + "/" + moment().format("DD") + "/" + (year - 1);
		} else if (dateTypeId === "mte") {
			currentYearStartDate = month + "/" + "01" + "/" + year;
			currentYearEndDate = month + "/" + moment(`${year} / ${month}`, "YYYY/MM").daysInMonth() + "/" + year;
			lastYearStartDate = month + "/" + "01" + "/" + (year - 1);
			lastYearEndDate = month + "/" + moment(`${year - 1} / ${month}`, "YYYY/MM").daysInMonth() + "/" + (year - 1);
		} else if (dateTypeId === "spr") {
			currentYearStartDate = "01" + "/" + "01" + "/" + year;
			currentYearEndDate = "06" + "/" + "30" + "/" + year;
			lastYearStartDate = "01" + "/" + "01" + "/" + (year - 1);
			lastYearEndDate = "06" + "/" + "30" + "/" + (year - 1);
		} else if (dateTypeId === "fal") {
			currentYearStartDate = "07" + "/" + "01" + "/" + year;
			currentYearEndDate = "12" + "/" + "31" + "/" + year;
			lastYearStartDate = "07" + "/" + "01" + "/" + (year - 1);
			lastYearEndDate = "12" + "/" + "31" + "/" + (year - 1);
		}

		setCurrentYearStartDate(currentYearStartDate);
		setCurrentYearEndDate(currentYearEndDate);
		setLastYearStartDate(lastYearStartDate);
		setLastYearEndDate(lastYearEndDate);

		const newState = {
			currentYearStartDateYte: currentYearStartDateYte,
			currentYearEndDateYte: currentYearEndDateYte,
			currentYearEndDateYtd: currentYearEndDateYtd,
			lastYearStartDateYte: lastYearStartDateYte,
			lastYearEndDateYte: lastYearEndDateYte,
			lastYearEndDateYtd: lastYearEndDateYtd,
			currentYearStartDateMte: currentYearStartDateMte,
			currentYearEndDateMte: currentYearEndDateMte,
			lastYearStartDateMte: lastYearStartDateMte,
			lastYearEndDateMte: lastYearEndDateMte,

			currentYearStartDate: currentYearStartDate,
			currentYearEndDate: currentYearEndDate,
			lastYearStartDate: lastYearStartDate,
			lastYearEndDate: lastYearEndDate,
			categoryId: categoryId,
			regionId: regionId,
			salePersonId: salePersonId,
			genderId: genderId,
		};
		props.filterData(newState);
	}, [dateTypeId, monthId, yearId]);

	useDidMountEffect(() => {
		const newState = {
			currentYearStartDateYte: currentYearStartDateYte,
			currentYearEndDateYte: currentYearEndDateYte,
			currentYearEndDateYtd: currentYearEndDateYtd,
			lastYearStartDateYte: lastYearStartDateYte,
			lastYearEndDateYte: lastYearEndDateYte,
			lastYearEndDateYtd: lastYearEndDateYtd,
			currentYearStartDateMte: currentYearStartDateMte,
			currentYearEndDateMte: currentYearEndDateMte,
			lastYearStartDateMte: lastYearStartDateMte,
			lastYearEndDateMte: lastYearEndDateMte,

			currentYearStartDate: currentYearStartDate,
			currentYearEndDate: currentYearEndDate,
			lastYearStartDate: lastYearStartDate,
			lastYearEndDate: lastYearEndDate,
			categoryId: categoryId,
			regionId: "-1",
			salePersonId: "-1",
			genderId: "-1",
		};
		getRegion(newState);
	}, [categoryId]);

	useDidMountEffect(() => {
		const newState = {
			currentYearStartDateYte: currentYearStartDateYte,
			currentYearEndDateYte: currentYearEndDateYte,
			currentYearEndDateYtd: currentYearEndDateYtd,
			lastYearStartDateYte: lastYearStartDateYte,
			lastYearEndDateYte: lastYearEndDateYte,
			lastYearEndDateYtd: lastYearEndDateYtd,
			currentYearStartDateMte: currentYearStartDateMte,
			currentYearEndDateMte: currentYearEndDateMte,
			lastYearStartDateMte: lastYearStartDateMte,
			lastYearEndDateMte: lastYearEndDateMte,

			currentYearStartDate: currentYearStartDate,
			currentYearEndDate: currentYearEndDate,
			lastYearStartDate: lastYearStartDate,
			lastYearEndDate: lastYearEndDate,
			categoryId: categoryId,
			regionId: regionId,
			salePersonId: "-1",
			genderId: "-1",
		};
		getSalesPerson(newState);
	}, [regionId]);

	useDidMountEffect(() => {
		const newState = {
			currentYearStartDateYte: currentYearStartDateYte,
			currentYearEndDateYte: currentYearEndDateYte,
			currentYearEndDateYtd: currentYearEndDateYtd,
			lastYearStartDateYte: lastYearStartDateYte,
			lastYearEndDateYte: lastYearEndDateYte,
			lastYearEndDateYtd: lastYearEndDateYtd,
			currentYearStartDateMte: currentYearStartDateMte,
			currentYearEndDateMte: currentYearEndDateMte,
			lastYearStartDateMte: lastYearStartDateMte,
			lastYearEndDateMte: lastYearEndDateMte,

			currentYearStartDate: currentYearStartDate,
			currentYearEndDate: currentYearEndDate,
			lastYearStartDate: lastYearStartDate,
			lastYearEndDate: lastYearEndDate,
			categoryId: categoryId,
			regionId: regionId,
			salePersonId: salePersonId,
			genderId: "-1",
		};
		getGender(newState);
	}, [salePersonId]);

	function getCustomer(state) {
		setCustomersLoading(true);
		fetch(LOCAL_URL + "compareSalesCustomerType", {
			method: "POST",
			headers: {
				Authorization: JWT,
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(state),
		})
			.then((res) => res.json())
			.then((result) => {
				setCustomersLoading(false);
				let options = [];
				// Set Items according to the requirement of AutoCompleteSelect component to render options.
				result.forEach((item) => {
					options.push({ value: item.categoryid, label: item.category });
				});
				setCustomers(options); // Set types array in the state so it can call next component to render the options.
			});
		getRegion(state);
	}

	function getRegion(state) {
		setRegionsLoading(true);
		fetch(LOCAL_URL + "compareSalesRegion", {
			method: "POST",
			headers: {
				Authorization: JWT,
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(state),
		})
			.then((res) => res.json())
			.then((result) => {
				setRegionsLoading(false);
				let options = [];
				// Set Items according to the requirement of AutoCompleteSelect component to render options.
				result.forEach((item) => {
					options.push({ value: item.regionid, label: item.region });
				});
				setRegions(options); // Set types array in the state so it can call next component to render the options.
			});
		getSalesPerson(state);
	}

	function getSalesPerson(state) {
		setSalesPersonLoading(true);
		fetch(LOCAL_URL + "compareSalesSalePerson", {
			method: "POST",
			headers: {
				Authorization: JWT,
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(state),
		})
			.then((res) => res.json())
			.then((result) => {
				setSalesPersonLoading(false);
				let options = [];
				// Set Items according to the requirement of AutoCompleteSelect component to render options.
				result.forEach((item) => {
					options.push({ value: item.salepersonid, label: item.saleperson });
				});

				setSalesPersons(options); // Set types array in the state so it can call next component to render the options.
			});
		getGender(state);
	}

	function getGender(state) {
		setGenderLoading(true);
		fetch(LOCAL_URL + "compareSalesGender", {
			method: "POST",
			headers: {
				Authorization: JWT,
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(state),
		})
			.then((res) => res.json())
			.then((result) => {
				setGenderLoading(false);
				let options = [];
				// Set Items according to the requirement of AutoCompleteSelect component to render options.
				result.forEach((item) => {
					options.push({ value: item.genderid, label: item.gender });
				});
				setGenders(options); // Set types array in the state so it can call next component to render the options.
			});
		//getRegion(state);
	}

	return (
		<>
			<div className="slt-filters" id="mainfilters">
				<h3 className="filter-title">Date Filter</h3>
				<div>
					<span className="b-ftr-inr">
						<label>Condition</label>
						<AutoCompleteSelect
							disabled={true}
							defaultValue={1}
							list={dateTypes}
							loading={dateTypeLoading}
							callBack={(a) => {
								setDateTypeId(a);
							}}
						/>
					</span>
				</div>
				<div>
					<span className="b-ftr-inr">
						<label>Month</label>
						<AutoCompleteSelect
							disabled={true}
							defaultValue={1}
							list={months}
							loading={monthsLoading}
							callBack={(a) => {
								setMonthId(a);
							}}
						/>
					</span>
				</div>

				<div>
					<span className="b-ftr-inr">
						<label>Year</label>
						<AutoCompleteSelect
							disabled={true}
							defaultValue={3}
							list={years}
							loading={yearsLoading}
							callBack={(a) => {
								setYearId(a);
							}}
						/>
					</span>
				</div>
			</div>
			<div className="slt-filters" id="mainfilters">
				<h3 className="filter-title">Filter</h3>
				<div>
					<span className="b-ftr-inr">
						<label>Customer Type</label>
						<AutoCompleteSelect
							list={customers}
							loading={customersLoading}
							callBack={(a) => {
								setcategoryId(a);
							}}
						/>
					</span>
				</div>
				<div>
					<span className="b-ftr-inr">
						<label>Region</label>
						<AutoCompleteSelect
							list={regions}
							loading={regionsLoading}
							callBack={(a) => {
								setRegionId(a);
							}}
						/>
					</span>
				</div>
				<div>
					<span className="b-ftr-inr">
						<label>Sales Person</label>
						<AutoCompleteSelect
							list={salesPersons}
							loading={salesPersonLoading}
							callBack={(a) => {
								setSalesPersonId(a);
							}}
						/>
					</span>
				</div>

				<div>
					<span className="b-ftr-inr">
						<label>Gender</label>
						<AutoCompleteSelect
							list={genders}
							loading={genderLoading}
							callBack={(a) => {
								setGenderId(a);
							}}
						/>
					</span>
				</div>
			</div>
		</>
	);
};
export default SalesComparisonReportFilter;
