import React, {useEffect, useState} from 'react';
import Header from './../../../header/header';
import config from './../../../../config/config';
import {Redirect} from 'react-router';
import Table from './../../../common/HTMLTable';
import {getExcelStandard} from '../../../../helper/excel';

let JWT;

const BGradeStockReport = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [closeReport, setCloseReport] = useState(false);
	const URL = config.B_GRADE_STOCK_REPORT;
	useEffect(() => {
		JWT = localStorage.getItem('access_token');
		fetchData();
	}, []);

	async function fetchData(){
		setLoading(true);
		let url = URL;
		const response = await fetch(url, {
			method: 'POST',
			headers: {Authorization: JWT, Accept: 'application/json', 'Content-Type': 'application/json'}
		});
		const data = await response.json();
		data.reportName = 'B GRADE STOCK REPORT';
		setData(data);
		setLoading(false);
	}

	return (
		<div>
			<Header />
			<div className="dash-body goods-required-filter">
				<div style={{paddingLeft: '0%', paddingRight: '0%', marginTop: '-2%'}}>
					<div className="report-result-head">
						<h2>B GRADE STOCK REPORT</h2>
						<div className="report-result-right-top">
							<button id="close-dialog-btn" onClick={() => setCloseReport(true)}>
								Close
							</button>
							<button
								id="download-excel-button"
								onClick={() => {
									getExcelStandard(data);
								}}
							>
								Download EXCEL
							</button>
							{
								closeReport == true ? <Redirect to="/dashboard" /> :
								null}
						</div>
					</div>
					<div className="report-data">
						<Table
							TableClassName="goodRequiredTable b-grade-stock-report"
							display={true}
							data={data}
							loading={loading}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
export default BGradeStockReport;