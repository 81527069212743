import React, {useEffect, useState} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {jwtDecode} from '../../helper/jwt-decode';
import companyLogo from './../../images/DL1961_Charcoal.png';
import './../../fonts/font-awesome-4.7.0/css/font-awesome.min.css';
import '../../css/admin.css';
const MOUSE_DOWN = 'mousedown';
const OPTIONS_LIST_INNNER = 'options-list-inner';
const SHOW = 'show';
const NAV_LINK = '.nav-link';
const DROPDOWN_MENU = 'dropdown-menu';

const Header = () => {
	const [loggedIn, setloggedIn] = useState(localStorage.getItem('access_token'));
	const token = jwtDecode(loggedIn);
	const [logout, setLogout] = useState(false); // Boolean state to be set when user logout from the application.
	const [closeReport, setCloseReport] = useState(false);

	/**
	 * Close list of account options on outise click. 
	 */
	function handleClickOutside(){
		window.onclick = function(event){
			if (!event.target.matches(NAV_LINK)) {
				var dropdowns = document.getElementsByClassName(DROPDOWN_MENU);
				var i;
				for (i = 0; i < dropdowns.length; i++) {
					var openDropdown = dropdowns[i];
					if (openDropdown.classList.contains(SHOW)) {
						openDropdown.classList.remove(SHOW);
					}
				}
			}
		};
	}

	/**
	 * Set event listener of mousedown on first render of component and remove when component destroy.
	 */

	useEffect(() => {
		//Set Page Title
		let pageTitle;
		pageTitle =
			document.querySelector('.report-result-head') ? document.querySelector('.report-result-head').firstChild
				.innerHTML :
			'';
		if (!pageTitle)
			pageTitle =
				document.querySelector('.report-container-header') ? document.querySelector('.report-container-header')
					.firstChild.innerHTML :
				'';
		document.title =
			pageTitle ? pageTitle :
			'Dashboard';
		//End Page Title
		document.addEventListener(MOUSE_DOWN, handleClickOutside);
		// Function to be called on when component destroy.
		return () => {
			document.removeEventListener(MOUSE_DOWN, handleClickOutside);
		};
	}, []); // Passing empty array will only call useEffect on first render of component.

	/**
	 * Class to be added on list to show on click.
	 */
	function showOptions(){
		document.getElementById(OPTIONS_LIST_INNNER).classList.toggle(SHOW);
	}

	/**
	 * Function to set state of user to logout.
	 */
	function logoutUser(){
		setLogout(true);
		localStorage.removeItem('access_token');
	}

	if (loggedIn == null || token.exp < new Date().getTime() / 1000) {
		return <Redirect to="#/admin/login" />;
	}

	return (
		<div className="header-main">
			<div className="header-inner">
				<div>
					<a href="#" onClick={(e) => e.preventDefault()} className="f-logo">
						OMS ADMIN PANEL
					</a>
				</div>
				<a href="#" onClick={(e) => e.preventDefault()} className="company-logo">
					<img src={companyLogo} />
				</a>
				<div className="header-right">
					<a
						href="#/admin/login"
						onClick={(e) => {
							e.preventDefault();
							logoutUser();
						}}
						className="dropdown-item feed-back"
					>
						<i className="fa fa-power-off" aria-hidden="true" /> Logout
					</a>
					{
						logout ? <Redirect to="/admin/login" /> :
						null /** Redirect to login page when state changes to true in logout. */}
					{/* <Link to="/admin/login" className="feed-back">
						<i className="fa fa-power-off" aria-hidden="true" /> LOGOUT
					</Link> */}
				</div>
			</div>
            <div className="f-main-menu">
				<div id="menu_area" className="menu-area">
					<div className="container-fluid">
						<div className="row">
							<nav className="navbar navbar-light navbar-expand-lg mainmenu">
								<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
								<span className="navbar-toggler-icon"></span>
								</button>
								<div className="collapse navbar-collapse" id="navbarSupportedContent">
									<ul className="navbar-nav mr-auto">
										<li className="dropdown">
											<a href="#" className="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Dashboard <span className="sr-only">(current)</span></a>
											<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
												<li><a href="#">Sales</a></li>
												<li><a href="#">Purchase</a></li>
												<li><a href="#">Profit</a></li>
												<li><a href="#">Inventory</a></li>
												<li><a href="#">Leader Board</a></li>
												<li><a href="#">Top Products</a></li>
												<li><a href="#">Top Customers  - Year End</a></li>
												<li><a href="#">Booked</a></li>
												<li><a href="#">Shipped</a></li>
												<li><a href="#">Pending</a></li>
											</ul>
										</li>
										<li className="dropdown">
											<a href="#" className="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Sales</a>
											<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
												<li><a href="#">Booked</a></li>
												<li><a href="#">Shipped</a></li>
												<li><a href="#">Pending</a></li>
												<li><a href="#">Returns</a></li>
											</ul>
										</li>
										<li className="dropdown">
											<a href="#" className="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Inventory</a>
											<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
												<li><a href="stock-report.html">Stock</a></li>
											</ul>
										</li>
										<li className="dropdown">
											<a className="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Accounts</a>
											<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
											<li><a href="#">Receivable</a></li>
											<li><a href="#">Payable</a></li>
											<li><a href="#">Expenses</a></li>
											<li><a href="#">Net Position</a></li>
											</ul>
										</li>
										<li className="dropdown">
											<a href="#" className="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Master</a>
											<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
												<li><a href="#">Products</a></li>
												<li><a href="#">Customers</a></li>
											</ul>
										</li>
										<li className="dropdown">
											<a href="#" className="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Analysis</a>
											<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
												<li className="dropdown">
												<a className="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Booking</a>
												<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
													<li><a href="#">Last Year</a></li>
													<li><a href="#">Date Range</a></li>
												</ul>
											</li>
											<li className="dropdown">
												<a className="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Shipping</a>
												<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
													<li><a href="#">Last Year</a></li>
													<li><a href="#">Date Range</a></li>
												</ul>
											</li>
											<li className="dropdown">
												<a className="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Pending</a>
												<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
													<li><a href="#">Last Year</a></li>
													<li><a href="#">Date Range</a></li>
												</ul>
											</li>
											</ul>
										</li>
									</ul>
								</div>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Header;