import React, {useState,useEffect} from 'react';
import Header from '../../header/header';
import DetailReportFilter from './skuWiseSearchFilter';
import config from '../../../config/config';
import ReportDialog from '../../common/reportDialog';
import {Redirect} from 'react-router';

const URL = config.STOCK_REPORT;
const PDF_URL = config.PRODUCT_INVENTORY_PDF_URL;
const SKU_URL = config.GET_ALL_URL;

const DetailReport = () => {
	const [reorder, setReorder] = useState({srno: 0, sku: 1, inseam: 2, wash: 3, location: 4});
	const [closeReport, setCloseReport] = useState(false);
	// Report filters
	const [searchState, setSearchState] = useState(false);
	const [formState, setFormState] = useState({});
	const [display, setDisplay] = useState(false);
	const [reportUrl, setReportUrl] = useState();
	const [pdfUrl, setPDFUrl] = useState();
	const [skuUrl, setSKUUrl] = useState(SKU_URL);
	const [pdfReportId, setPdfReportId] = useState();
	const hyperLinks = [];
	const hyperLinksUrl = ['', '', 'productionDetail'];
	const popupUrl = 'productionDetail';

	useEffect(
		() => {
			const button = document.querySelector('#genreport');
			if (formState.sku === undefined || formState.sku == '-1') {
				button.disabled = true;
				button.style.opacity = '0.5';
			}
			else {
				button.disabled = false;
				button.style.opacity = '1';
			}
		},
		[formState.sku]
	);

	function showTable(){
		setFormState({...formState});
		setPDFUrl(PDF_URL);
		setReportUrl(URL);
		setDisplay(true);
	}
	return (
		<div>
			<Header />
			<div className="main-content stock-filter-layout">
				<div className="report-container group">
					<div className="report-container-header">
						<h1>Stock Report</h1>
						<button id="close-report-btn" onClick={() => setCloseReport(true)}>
							Close
						</button>
						{
							closeReport == true ? <Redirect to="/dashboard" /> :
							null}
					</div>
					<div className="report-container-inner">
						<div className="r-left">
							<DetailReportFilter
								filterData={(state) => {
									setFormState(state);
								}}
								searchState={searchState}
							/>
							<button
								style={{
									width: '25%',
									float: 'right'
								}}
								className="report-submit-btn"
								id="genreport"
								onClick={showTable}
							
							>
								SEARCH
							</button>
						</div>
					</div>
				</div>
				<ReportDialog
					reorder={reorder}
					excelVersion="Specific"
					TableClassName="skuTable"
					table="groupHTMLTable"
					defaultPageSize={100}
					display={display}
					callBack={() => {
						setDisplay(false);
					}}
					url={reportUrl}
					pdfUrl=""
					skuUrl={skuUrl}
					hyperLinks={hyperLinks}
					hyperLinksUrl={hyperLinksUrl}
					popupUrl={popupUrl}
					body={formState}
					pdfReportId={pdfReportId}
				/>
			</div>
		</div>
	);
};
export default DetailReport;