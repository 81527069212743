import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import MainTable from "./mainTable";
import GroupHtmlTable from "./groupHTMLTable";
import AlertBox from "./alert";
import config from "../../config/config";

const PopUp = (props) => {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const JWT = localStorage.getItem("access_token");
  const skuId = props.skuId;
  const genderID = props.genderID;

  const regionId = props.regionId;
  const size = props.size;
  const styleId = props.styleId;
  const salesPersonId = props.salesPersonId;
  const orderNo = props.orderNo;
  const displayPopup = props.display;
  const inseam = props.inseam;
  const exfactdate = props.exfactdate;
  const [reportNumber, setReportNumber] = useState("");
  const reportType = props.reportType ? props.reportType : "";
  const type = props.type;
  const yearDesc = props.yearDesc ? props.yearDesc : "";
  const location = props.location ? props.location : "";



  let body = props.body;
  //Error Modal
  const [visible, setVisible] = useState(false);

  const handleCancelError = () => {
    setVisible(false);
  };

  const handleOk = () => {
    props.callBack();
  };

  const handleCancel = () => {
    props.callBack();
  };

  useEffect(() => {
    if (displayPopup) fetchData();
  }, [displayPopup]);

  async function fetchData() {
    setLoading(true);
    let detailUrl = props.popupUrl ? props.popupUrl : "otsDetail";
    let url = config.GET_ALL_URL + detailUrl + "&skuId=" + skuId + "&genderId=" + genderID;
    let order = props.body && props.body.order ? props.body.order : "";
    let orderBy = props.body && props.body.orderBy ? props.body.orderBy : "";
    url +="&order=" + order + "&orderBy=" + orderBy + "&location=" + props.location;

    body.reporttype = reportType ? reportType : "";

    body.type = type;
    body.yeardesc = yearDesc;
    body.location = location ? location.toLowerCase() : "";
    body.inseam = inseam;

    body.exfactdate = exfactdate;
    if(genderID){
      body.genderId = genderID;
    }
    body.regionId = regionId;
    body.styleId = styleId;
    if (size) {
      body.size = size;
    }
    body.salesPersonId = salesPersonId;
    body.orderno = orderNo;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: JWT,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).catch((err) => {
      props.callBack();
      setVisible(true);
      return;
    });

    if (response.status == 500 || response.status == 503) {
      props.callBack();
      setVisible(true);
      return;
    }
    const data = await response.json();
    const reportNumber = props.reportNumber
      ? props.reportNumber
      : data.reportNumber == undefined
        ? "-"
        : data.reportNumber;
    setData(data);
    setReportNumber(reportNumber);
    setLoading(false);
  }


  return (
    <>
      <AlertBox handleCancel={handleCancelError} visible={visible} />
      <Modal
        title={
          location +
          " " +
          props.popupTitle +
          " " +
          reportType +
          "\n" +
          reportNumber
        }
        visible={props.display}
        onOk={handleOk}
        width={2000}
        onCancel={handleCancel}
      >
        {props.table == "groupTable" ? (
          <GroupHtmlTable
            TableClassName={props.TableClassName}
            popupTable='popupTable'
            reorder={props.reorder}
            defaultPageSize='1000'
            columnAttribute={props.columnAttribute}
            className={props.className}
            popupNavigation={props.popupNavigation}
            display={props.display}
            popupTitle={props.popupTitle}
            body={body}
            type={type}
            data={data}
            loading={loading}
            callBack={() => {
              setVisible(true);
            }}
            callBackFetchData={(param) => {
              body.size = param;
              fetchData();
            }}
          />
        ) : (
          <MainTable
            TableClassName={props.TableClassName}
            columnAttribute={props.columnAttribute}
            className={props.className}
            popupNavigation={props.popupNavigation}
            display={props.display}
            popupTitle={props.popupTitle}
            body={body}
            type={type}
            data={data}
            loading={loading}
            callBack={() => {
              setVisible(true);
            }}
            callBackFetchData={(param) => {
              body.size = param;
              fetchData();
            }}
          />
        )}
      </Modal>
    </>
  );
};
export default PopUp;
