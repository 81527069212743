import React, { useState } from 'react';
import Header from '../../../header/header';
import ProductInventoryReportFilter from './productInventoryReportFilter';
import ProductStatus from '../../../common/productStatus'
import Active from '../../../common/active';
import Date from '../../../common/date'
import config from '../../../../config/config';
import GroupOn from '../../../common/groupOn';
import SortOrder from '../../../common/sortOrder';
import Group from '../../../common/group';
import ReportDialog from '../../../common/reportDialog';
import { Redirect } from 'react-router';
import moment from 'moment';

const groupsOn = require('../../../../data/productInventory/groupOn.json');
const sortOrder = require('../../../../data/productInventory/sortOn.json');

const URL = config.PRODUCT_INVENTORY_URL;
const PDF_URL = config.PRODUCT_INVENTORY_PDF_URL;
const SKU_URL = config.GET_ALL_URL;
const PRODUCT_STATUS = "&productStatus=";
const IS_INACTIVE = "&isInActive=";
const SORT_ON = "&sortOn=";

const ProductInventoryReport = () => {


    const [stockReportSortsOn, setStockReportSortsOn] = useState('');

    const [groupOnArray, setGroupOnArray] = useState([]);
    const [OrderOnArray, setOrderOnArray] = useState([]);

     const [Order, setOrder] = useState('DESC');

    const [closeReport, setCloseReport] = useState(false);

    const [date, setDate] = useState({});

    // Report filters
    const [isInactive, setIsInActive] = useState("F");
    const [selectedProductStatus, setSelectedProductStatus] = useState('1');
    const [searchState, setSearchState] = useState(false);
    const [formState, setFormState] = useState({});

    const [display, setDisplay] = useState(false);
    const [reportUrl, setReportUrl] = useState();
    const [pdfUrl, setPDFUrl] = useState();
    const [skuUrl, setSKUUrl] = useState(SKU_URL);
    const [pdfReportId, setPdfReportId] = useState();
    const stockReportHyperLinks = [9];

    const [isGroup, setIsGroup] = useState(true);
    const [IsOrder, setIsOrder] = useState(false);
    
    //SET RADIO BUTTON IDS
    const [id, setId] = useState(['DESC','ASC']);
   
    const [stockReportGroup, setStockReportGroup] = useState('');
    const [OrderReportGroup, setOrderReportGroup] = useState('');


    function showTable() {
        // let selectedArray = groupOnArray;
        // let selectedArray = [];
        // // console.log({ length: groupOnArray.length });
        // // console.log('formState',formState);
        // if (groupOnArray.length == 0) {
        //     selectedArray = ['sku'];
        //     for (var i in formState) {
        //         if (formState[i] != '-1') {
        //             selectedArray = groupOnArray
        //         }
        //     }
        // }
        // else {
        //     selectedArray = groupOnArray;
        // }
        let url = URL + PRODUCT_STATUS + selectedProductStatus;
        let pdfUrl = PDF_URL + PRODUCT_STATUS + selectedProductStatus;
        
        // let url = config.PRODUCT_INVENTORY_URL + PRODUCT_STATUS + selectedProductStatus;
        // let pdfUrl = config.PRODUCT_INVENTORY_PDF_URL + PRODUCT_STATUS + selectedProductStatus;
        if (stockReportSortsOn != '') {
            url += SORT_ON + stockReportSortsOn;
            pdfUrl += SORT_ON + stockReportSortsOn;
        }
        // console.log({ selectedArray });



        setFormState({ ...formState, "groupBy": groupOnArray,"order":Order,"orderBy": OrderOnArray,"fromdate":moment(date.fromDate).format('MM/DD/YYYY'),});
        url += IS_INACTIVE + isInactive;
        pdfUrl += IS_INACTIVE + isInactive;
        url += "&fromDate=" + moment(date.fromDate).format('MM/DD/YYYY') + "&toDate=" + moment(date.toDate).format('MM/DD/YYYY');
        pdfUrl += "&fromDate=" + moment(date.fromDate).format('MM/DD/YYYY') + "&toDate=" + moment(date.toDate).format('MM/DD/YYYY');
        setPDFUrl(pdfUrl);
        setReportUrl(url);
        setDisplay(true);

    }

    function stockReportGroupValue(groupId) {
        // Change the group state so it can disabled or enabled sortOn by calling it.
        groupId == "1" ? setIsGroup(false) : setIsGroup(true); // If id is 1 then set group to true else false.
        setStockReportGroup(groupId);
    }

    function OrderBy(groupId) {
        // Change the group state so it can disabled or enabled sortOn by calling it.
        groupId == "1" ? setIsOrder(false) : setIsOrder(true); // If id is 1 then set group to true else false.
        setOrderReportGroup(groupId);
    }

    return (
        <div>
            <Header />
            <div className="main-content">
                <div className="report-container group">
                    <div className="report-container-header">
                        <h1>Product Inventory Report</h1>
                        <button id="close-report-btn" onClick={() => setCloseReport(true)} >Close</button>
                        {closeReport == true ? <Redirect to="/dashboard" /> : null}
                    </div>
                    <div className="report-container-inner">
                        <div className="r-left">
                            {/* Date Filter */}
                            <Date dateData={(date) => setDate(date)} defaultDate={{ fromDate: moment().format('YYYY-MM-DD') }} />
                            <Active sendStatus={(a) => { a == 'active-1' ? setIsInActive('F') : setIsInActive('T') }} />
                            <ProductStatus statusData={(id) => { setSelectedProductStatus(id); }} />
                            <ProductInventoryReportFilter
                                filterData={(state) => { setFormState(state) }}
                                productStatus={selectedProductStatus}
                                isInActive={isInactive}
                                searchState={searchState}
                                date={date}
                            />
                        </div>
                        <div className="r-right">
                            <Group groupData={stockReportGroupValue} disabled={false} />
                            <GroupOn groupData={stockReportGroupValue} disabled={isGroup} groupOn={groupsOn} groupOnData={(arr => setGroupOnArray(arr))} />
                            <Group label1='Asc' label2="Desc" name="orderby" title="Sort" id={id} setOrder={(arr=> setOrder(arr))}  groupData={OrderBy} disabled={false} />
                            <SortOrder groupOn={sortOrder}  groupOnData={(arr => setOrderOnArray(arr))} />                             
                            <button className="report-submit-btn" id="genreport" onClick={showTable}>SEARCH</button>
                        </div>
                    </div>
                </div>
                <ReportDialog
                    display={display}
                    callBack={() => { setDisplay(false) }}
                    url={reportUrl}
                    pdfUrl=''
                    skuUrl={skuUrl}
                    hyperLinks={stockReportHyperLinks}
                    body={formState}
                    pdfReportId={pdfReportId}
                />
            </div>
        </div>
    );
}

export default ProductInventoryReport;