import React, { useState,useEffect } from 'react';
import Header from './../../../header/header';
import ProductCompositionReportFilter from './productCompositionReportFilter';
import ProductStatus from './../../../common/productStatus'
import Active from '../../../common/active';
import config from './../../../../config/config';
import GroupOn from '../../../common/groupOn';
import Group from '../../../common/group';
import ReportDialog from '../../../common/reportDialog';
import { Redirect } from 'react-router';

const groupsOn = require('./../../../../data/productUpc/sortsOn.json');


const URL = config.PRODUCT_UPC;
const PDF_URL = config.PRODUCT_PDF_URL;
const EMAIL_URL = config.PRODUCT_EMAIL_URL;
const PRODUCT_STATUS = "&productStatus=";
const IS_INACTIVE ="&isInActive=";
const SORT_ON = "&sortOn=";

const ProductCompositionReport = () => {

    const [ stockReportSortsOn, setStockReportSortsOn ] = useState('');
    const [ groupOnArray, setGroupOnArray ] = useState([]);    
    const [closeReport, setCloseReport] = useState(false);
    // Report filters
    const [ isInactive, setIsInActive ] = useState("F");
    const [ selectedProductStatus, setSelectedProductStatus ] = useState('1');
    const [ searchState, setSearchState ] = useState(false);
    const [ formState, setFormState ] = useState({});

    const [ display, setDisplay ] = useState(false);
    const [ reportUrl, setReportUrl ] = useState();
    const [pdfUrl, setPDFUrl] = useState();
    const [ emailUrl, setEmailUrl ] = useState();
    const [ pdfReportId, setPdfReportId ] = useState();
    const stockReportHyperLinks = [];

    const [ isGroup, setIsGroup ] = useState(true);
    const [ stockReportGroup, setStockReportGroup ] = useState('');

    function showTable() {
        let selectedArray = groupOnArray;
        let url = URL + PRODUCT_STATUS + selectedProductStatus;
        let pdfUrl = PDF_URL + PRODUCT_STATUS + selectedProductStatus;
        let emailUrl = EMAIL_URL + PRODUCT_STATUS + selectedProductStatus;
        if( stockReportSortsOn != '' ) {
            url += SORT_ON + stockReportSortsOn;
            pdfUrl += SORT_ON + stockReportSortsOn;
            emailUrl += SORT_ON + stockReportSortsOn;
        }
        setFormState({...formState, "groupBy": selectedArray });
        url += IS_INACTIVE + isInactive;
        pdfUrl += IS_INACTIVE + isInactive;
        emailUrl += IS_INACTIVE + isInactive;
        setPDFUrl(pdfUrl);
        setEmailUrl( emailUrl );
        setReportUrl(url);
        setDisplay(true);
    }

    useEffect(() => {
        document.getElementById('genreport').disabled = true
        document.getElementById('genreport').classList.add('disabled');
    },[])

    function stockReportGroupValue( groupId ) {
        // Change the group state so it can disabled or enabled sortOn by calling it.
        groupId == "1" ? setIsGroup( false ): setIsGroup( true ); // If id is 1 then set group to true else false.
        setStockReportGroup( groupId );
    }

    return(
        <div>
            <Header/>
            <div className="main-content product-upc-report">
                <div className="report-container group">
                    <div className="report-container-header">
                        <h1>Products UPC Report</h1>
                        <button id="close-report-btn" onClick={ () => setCloseReport(true) } >Close</button>
                        { closeReport == true ? <Redirect to="/dashboard" />: null }
                    </div>
                    <div className="report-container-inner">
                        <div className="w-100 r-left">
                            <Active sendStatus={ (a) => { a == 1 ? setIsInActive('F'): setIsInActive('T') }  } />
                            <ProductStatus url='productStatusUpc' statusData={ (id) => { setSelectedProductStatus(id); } }/>
                            <ProductCompositionReportFilter 
                                filterData={ (state) => { setFormState(state) } } 
                                productStatus={ selectedProductStatus } 
                                isInActive={ isInactive } 
                                searchState={ searchState }
                            />

                        </div>
                        <div className="r-right">
                              <button className="report-submit-btn" id="genreport" onClick={ showTable }>SEARCH</button>                         
                        </div>
                    </div>
                </div>
                <ReportDialog                   
                    display={ display }     
                    callBack={ () => { setDisplay(false) } } 
                    url= { reportUrl }
                  //  pdfUrl={pdfUrl}
                //    emailUrl ={emailUrl}
                    hyperLinks={ stockReportHyperLinks }
                    body={ formState }
                    pdfReportId={ pdfReportId }
                />
            </div>
        </div>
    );
}

export default ProductCompositionReport;