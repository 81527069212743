import React, {useEffect, useState} from 'react';
import AutoCompleteSelect from '../../common/autoCompleteSelect';
import useDidMountEffect from '../../common/useDidMountEffect';
import config from '../../../config/config';
import moment from 'moment';

const URL = config.GET_ALL_URL;
let JWT;

const PurchaseOrderReportFilter = (props) => {
	// Selected Ids from the options list in AutoCompleteSelect.
	const [otherThanFirst, setOtherThanFirst] = useState(true);
	const [shipperId, setShipperId] = useState('-1');
	const [styleId, setStyleId] = useState('-1');
	const [skuId, setSkuId] = useState('-1');
	// Array that have data to sent to AutoCompleteSelect component to render options.
	const [shipper, setShipper] = useState('-1');
	const [styles, setStyles] = useState('-1');
	const [skus, setSkus] = useState('-1');
	// Loading booleans to be set on AutoComplete.
	const [shipperLoading, setShipperLoading] = useState(true);
	const [stylesLoading, setStylesLoading] = useState(true);
	const [skusLoading, setSkusLoading] = useState(true);

	useState(
		() => {
			JWT = localStorage.getItem('access_token');
		},
		[props.date]
	);

	useDidMountEffect(() => {
        const newState = getNewState();
        props.filterData(newState);
    },[shipperId,styleId, skuId ]
    );
    
    function getNewState() {
		return {
			shipperId: shipperId,
			skuId: skuId,
			styleId: styleId
		};
    }
    
	/**
     * Send back data to the parent component i.e CustomerReport whenever selected option changes.
     */

	useDidMountEffect(() => {
			setOtherThanFirst(true);
			const newState = {
				shipperId: '-1',
                styleId: '-1',
				skuId: '-1'
			};
			getShipper(newState);
		},[props.date]
	);

	useDidMountEffect(() => {
            const newState = {
                shipperId: shipperId,
				styleId: '-1',
				skuId: '-1'
			};
			getStyle(newState);
		},[shipperId]
    );
    

    
	useDidMountEffect(() => {
        const newState = {
            shipperId: shipperId,
            styleId: styleId,
            skuId: '-1'
        };
        getSKU(newState);
    },[styleId]
);

	function getDate(){
		return (
			'&fromDate=' +
			moment(props.date.fromDate).format('MM/DD/YYYY') +
			'&toDate=' +
			moment(props.date.toDate).format('MM/DD/YYYY')
		);
	}

	function getShipper(state){
		setShipperLoading(true);
		fetch(URL + 'purchaseOrderShipper' + getDate(), {
			method: 'POST',
			headers: {Authorization: JWT, Accept: 'application/json', 'Content-Type': 'application/json'},
			body: JSON.stringify(state)
		})
			.then((res) => res.json())
			.then((result) => {
				setShipperLoading(false);
				let options = [];
				// Set Items according to the requirement of AutoCompleteSelect component to render options.
				result.forEach((item) => {
					options.push({value: item.shipperid, label: item.shippername});
				});
				setShipper(options); // Set array in the state so it can call next component to render the options.
			});
		getStyle(state);
	}

	function getStyle(state){
		setStylesLoading(true);
		fetch(URL + 'purchaseOrderStyle' + getDate(), {
			method: 'POST',
			headers: {Authorization: JWT, Accept: 'application/json', 'Content-Type': 'application/json'},
			body: JSON.stringify(state)
		})
			.then((res) => res.json())
			.then((result) => {
				setStylesLoading(false);
				let options = [];
				// Set Items according to the requirement of AutoCompleteSelect component to render options.
				result.forEach((item) => {
					options.push({value: item.styleid, label: item.stylename});
				});
				setStyles(options); // Set array in the state so it can call next component to render the options.
			});
		getSKU(state);
	}

	function getSKU(state){
		setSkusLoading(true);
		fetch(URL + 'purchaseOrderSku' + getDate(), {
			method: 'POST',
			headers: {Authorization: JWT, Accept: 'application/json', 'Content-Type': 'application/json'},
			body: JSON.stringify(state)
		})
			.then((res) => res.json())
			.then((result) => {
				setSkusLoading(false);
				let options = [];
				// Set Items according to the requirement of AutoCompleteSelect component to render options.
				result.forEach((item) => {
					options.push({value: item.skuid, label: item.skuname});
				});
				setSkus(options); // Set array in the state so it can call next component to render the options.
			});
	}

	return (
		<div className="slt-filters" id="mainfilters">
			<h3 className="filter-title">Filter</h3>
			<div>
				<span className="b-ftr-inr">
					<label>Shipper</label>
					<AutoCompleteSelect
						label="Shipper"
						list={shipper}
						loading={shipperLoading}
						callBack={(a) => {
							setShipperId(a);
						}}
					/>
				</span>
			</div>

			<div>
				<span className="b-ftr-inr">
					<label>Style</label>
					<AutoCompleteSelect
						list={styles}
						loading={stylesLoading}
						callBack={(a) => {
						setStyleId(a);
						}}
					/>
				</span>
			</div>

			<div>
				<span className="b-ftr-inr">
					<label>SKU</label>
					<AutoCompleteSelect
						list={skus}
						loading={skusLoading}
						callBack={(a) => {
							setSkuId(a);
						}}
					/>
				</span>
			</div>
		</div>
	);
};
export default PurchaseOrderReportFilter;
