import React, {useEffect, useState} from "react";
import Header from "./../../../header/header";
import config from "./../../../../config/config";
import {NavLink} from "react-router-dom";
import {Redirect} from "react-router";
import moment from "moment";
import Table from "./../../../common/groupHTMLTable";
import {getExcelStandard} from "../../../../helper/excel";
import {
  montFromDate,
  monthToDate,
  yearFromDate,
  yearToDate,
} from "../../../../helper/date";

let JWT;
let fromDate;
let toDate;

const Ecommerce = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [closeReport, setCloseReport] = useState(false);
  const [reportName, setReportName] = useState(props.reportName);
  const [reportType, setReportType] = useState(props.reportType);
  const [reportNumber, setReportNumber] = useState(props.reportNumber);
  const [genderId, setGenderId] = useState(props.genderId);
  const [querytype, setQueryType] = useState(props.querytype);
  const classes = "ecommerce-by-region-table net-shipped-by-sale-person-table";
  const TableClassName = classes + " " + props.class;
  const URL = config.E_COMMERCE;

  const [formState, setFormState] = useState({
    genderId: genderId,
    querytype: querytype,
  });

  let tableName;

  if (querytype == "top size") {
    tableName = "groupTable";
  } else {
    tableName = "normalTable";
  }
 
  const columnAttributes = {
    bookedquantity: {
      link: true,
      tabs: false,
      type: "booked",
      endPoint: "dashboardEcommerceDetail",
      title: props.reportName + " - Detail",
      reportNumber: props.reportNumber,
      popup: {
        orderno: {
          link: true,
          tabs: false,
          endPoint: "dashboardBookingBySPOrderDetail",
          title: "Booked Quantity Detail",
          reportNumber: props.reportNumber,
        },
      },
    },
    shippedquantity: {
      link: true,
      tabs: false,
      type: "shipped",
      endPoint: "dashboardEcommerceDetail",
      title: props.reportName + " - Detail",
      reportNumber: props.reportNumber,
      popup: {
        orderno: {
          link: true,
          tabs: false,
          endPoint: "dashboardBookingBySPOrderDetail",
          title: "Shipped Quantity Detail",
          reportNumber: props.reportNumber,
        },
      },
    },

    returnedquantity: {
      link: true,
      tabs: false,
      type: "returned",
      endPoint: "dashboardEcommerceDetail",
      title: props.reportName + " - Detail",
      reportNumber: props.reportNumber,
      popup: {
        orderno: {
          link: true,
          tabs: false,
          endPoint: "dashboardBookingBySPOrderDetail",
          title: "Returned Quantity Detail",
          reportNumber: props.reportNumber,
        },
      },
    },

    size: {
      link: true,
      tabs: false,
      type: "returned",
      endPoint: "dashboardEcommerceSizeDetail",
      title: props.reportName + " - Detail",
      reportNumber: props.reportNumber,
      reorder: {
        srno: 0,
        region: 1,
        style: 2,
        sku: 3,
        inseam: 4,
        size: 5,
        wash: 6,
      },
      popup: {
        orderno: {
          link: false,
          tabs: false,
          endPoint: "dashboardBookingBySPOrderDetail",
          title: "Returned Quantity Detail",
          reportNumber: props.reportNumber,
        },
      },
    },
  };

  useEffect(() => {
    JWT = localStorage.getItem("access_token");
    if(props.reportType)
    fetchData()
  }, [props]);

  async function fetchData() {
    setLoading(true);
    fromDate = reportType == "ytd" ? yearFromDate() : montFromDate();
    toDate = reportType == "ytd" ? yearToDate() : monthToDate();
   if(reportType == 'today')
    fromDate = toDate;
    let url = URL;

    setFormState({
      fromDate: fromDate,
      toDate: toDate,
    });

    url +=
      "&fromDate=" +
      moment(fromDate).format("MM/DD/YYYY") +
      "&toDate=" +
      moment(toDate).format("MM/DD/YYYY");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: JWT,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formState),
    });
    const data1 = await response.json();
    data1.reportName = "Net Shipped By Sale Person";
    setData(data1);
    setLoading(false);
  }

  return (
    <div>
      <Header />
      <div className="dash-body ecommerce-dashbaord">
        <div style={{paddingLeft: "0%", paddingRight: "0%", marginTop: "-2%"}}>
          <div className="report-result-head">
            <h2>{reportName}</h2> <br />
            <h2>{reportNumber}</h2>
            <div className="navigation-area">
              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-region-ytd"
              >
                ALL-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react "
                to="e-commerce-by-women-top-style-ytd"
              >
                W-TopFit-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-men-top-style-ytd"
              >
                M-TopFit-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-kids-top-style-ytd"
              >
                K-TopFit-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-pets-top-style-ytd"
              >
                P-TopFit-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react "
                to="e-commerce-by-women-top-sku-ytd"
              >
                W-TOPSKU-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-men-top-sku-ytd"
              >
                M-TOPSKU-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-kids-top-sku-ytd"
              >
                K-TOPSKU-YTD
              </NavLink>
              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-pets-top-sku-ytd"
              >
                P-TOPSKU-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react "
                to="e-commerce-by-women-top-size-ytd"
              >
                W-TOPSIZE-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-men-top-size-ytd"
              >
                M-TOPSIZE-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-kids-top-size-ytd"
              >
                K-TOPSIZE-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-pets-top-size-ytd"
              >
                P-TOPSIZE-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-region-mtd"
              >
                ALL-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react "
                to="e-commerce-by-women-top-style-mtd"
              >
                W-TopFit-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-men-top-style-mtd"
              >
                M-TopFit-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-kids-top-style-mtd"
              >
                K-TopFit-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-pets-top-style-mtd"
              >
                P-TopFit-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react "
                to="e-commerce-by-women-top-sku-mtd"
              >
                W-TOPSKU-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-men-top-sku-mtd"
              >
                M-TOPSKU-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-kids-top-sku-mtd"
              >
                K-TOPSKU-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-pets-top-sku-mtd"
              >
                P-TOPSKU-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react "
                to="e-commerce-by-women-top-size-mtd"
              >
                W-TOPSIZE-mtd
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-men-top-size-mtd"
              >
                M-TOPSIZE-mtd
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-kids-top-size-mtd"
              >
                K-TOPSIZE-mtd
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-pets-top-size-mtd"
              >
                P-TOPSIZE-mtd
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-all-today"
              >
                ALL-TODAY
              </NavLink>


              <NavLink
                activeClassName="selected"
                className="link-react "
                to="e-commerce-by-women-top-style-today"
              >
                W-TopFit-TODAY
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-men-top-style-today"
              >
                M-TopFit-TODAY
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-kids-top-style-today"
              >
                K-TopFit-TODAY
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-pets-top-style-today"
              >
                P-TopFit-TODAY
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react "
                to="e-commerce-by-women-top-sku-today"
              >
                W-TOPSKU-TODAY
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-men-top-sku-today"
              >
                M-TOPSKU-TODAY
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-kids-top-sku-today"
              >
                K-TOPSKU-TODAY
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-pets-top-sku-today"
              >
                P-TOPSKU-TODAY
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react "
                to="e-commerce-by-women-top-size-today"
              >
                W-TOPSIZE-TODAY
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-men-top-size-today"
              >
                M-TOPSIZE-TODAY
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-kids-top-size-today"
              >
                K-TOPSIZE-TODAY
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="e-commerce-by-pets-top-size-today"
              >
                P-TOPSIZE-TODAY
              </NavLink>
              

            </div>
            {props.reportType ? (
              <div className="report-result-right-top">
                <button
                  id="close-dialog-btn"
                  onClick={() => setCloseReport(true)}
                >
                  Close
                </button>

                <button
                  id="download-excel-button"
                  onClick={() => {
                    getExcelStandard(data);
                  }}
                >
                  Download EXCEL
                </button>
                {closeReport == true ? <Redirect to="/dashboard" /> : null}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="report-data">
            {props.reportType ? (
              <Table
                table={tableName}
                defaultPageSize={1500}
                popupClassName=""
                TableClassName={TableClassName}
                body={formState}
                columnAttributes={columnAttributes}
                display={true}
                data={data}
                loading={loading}
              />
            ) : ("")}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Ecommerce;