import React, {useEffect, useState} from "react";
import Header from "./../../../header/header";
import config from "./../../../../config/config";
import {NavLink} from "react-router-dom";
import {Redirect} from "react-router";
import moment from "moment";
import Table from "./../../../common/groupHTMLTable";
import {getExcelStandard} from "../../../../helper/excel";
import {
  montFromDate,
  monthToDate,
  yearFromDate,
  yearToDate,
} from "../../../../helper/date";
let JWT;
let fromDate;
let toDate;

const ReturnByFitStyle = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [closeReport, setCloseReport] = useState(false);
  const [reportName, setReportName] = useState(props.reportName);
  const [reportType, setReportType] = useState(props.reportType);
  const [reportNumber, setReportNumber] = useState(props.reportNumber);
  const [genderId, setGenderId] = useState(props.genderId);
  const [querytype, setQueryType] = useState(props.querytype);
  const [formState, setFormState] = useState({
    genderId: genderId,
    querytype: querytype,
  });

  const classes = " ";
  const TableClassName = classes + " " + props.class;

  let URL;
  if (querytype == "all fitstyle") {
    URL = config.NET_SHIPPED_BY_FIT_STYLE;
  }

  if (querytype == "top sku") {
    URL = config.NET_SHIPPED_BY_FIT_STYLE_TOP_SKU;
  }

  if (querytype == "top size") {
    URL = config.NET_SHIPPED_BY_FIT_STYLE_TOP_SIZE;
  }
  useEffect(() => {
    JWT = localStorage.getItem("access_token");
    fetchData();
  }, [props]);

  async function fetchData() {
    setLoading(true);
    fromDate = reportType == "ytd" ? yearFromDate() : montFromDate();
    toDate = reportType == "ytd" ? yearToDate() : monthToDate();
    let url = URL;
    url +=
      "&fromDate=" +
      moment(fromDate).format("MM/DD/YYYY") +
      "&toDate=" +
      moment(toDate).format("MM/DD/YYYY");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: JWT,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formState),
    });
    const data1 = await response.json();
    data1.reportName = "Booking Leaderboard - MTD";
    setData(data1);
    setLoading(false);
  }

  return (
    <div>
      <Header />
      <div className="dash-body net-shipped-by-sale-person-table ecommerce-dashbaord net-shipped-fit-style-dashboard">
        <div style={{paddingLeft: "0%", paddingRight: "0%", marginTop: "-2%"}}>
          <div className="report-result-head">
            <h2>{reportName}</h2> <br />
            <h2>{reportNumber}</h2>
            <div className="navigation-area">
              <NavLink
                activeClassName="selected"
                className="link-react"
                to="net-shipped-by-fit-style-ytd"
              >
                ALL-FITSTYLE-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="net-shipped-by-fit-style-women-ytd"
              >
                W-FITSTYLE-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="net-shipped-by-fit-style-men-ytd"
              >
                M-FITSTYLE-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="net-shipped-by-fit-style-kids-ytd"
              >
                K-FITSTYLE-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="net-shipped-by-fit-style-pets-ytd"
              >
                P-FITSTYLE-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="net-shipped-by-fit-style-women-sku-ytd"
              >
                W-TOPSKU-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="net-shipped-by-fit-style-men-sku-ytd"
              >
                M-TOPSku-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="net-shipped-by-fit-style-kids-sku-ytd"
              >
                K-TOPSKU-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="net-shipped-by-fit-style-pets-sku-ytd"
              >
                P-TOPSKU-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react "
                to="net-shipped-by-fit-style-women-top-size-ytd"
              >
                W-TOPSIZE-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="net-shipped-by-fit-style-men-top-size-ytd"
              >
                M-TOPSIZE-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="net-shipped-by-fit-style-kids-top-size-ytd"
              >
                K-TOPSIZE YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="net-shipped-by-fit-style-pets-top-size-ytd"
              >
                P-TOPSize-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="net-shipped-by-fit-style-mtd"
              >
                ALL-FITSTYLE-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="net-shipped-by-fit-style-women-mtd"
              >
                W-FITSTYLE-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="net-shipped-by-fit-style-men-mtd"
              >
                M-FITSTYLE-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="net-shipped-by-fit-style-kids-mtd"
              >
                K-FITSTYLE-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="net-shipped-by-fit-style-pets-mtd"
              >
                P-FITSTYLE-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react "
                to="net-shipped-by-fit-style-women-sku-mtd"
              >
                W-TOPSKU-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="net-shipped-by-fit-style-men-sku-mtd"
              >
                M-TOPSKU-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="net-shipped-by-fit-style-kids-sku-mtd"
              >
                K-TOPSKU-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="net-shipped-by-fit-style-pets-sku-mtd"
              >
                P-TOPSKU-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react "
                to="net-shipped-by-fit-style-women-top-size-mtd"
              >
                W-TOPSIZE-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="net-shipped-by-fit-style-men-top-size-mtd"
              >
                M-TOPSIZE-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="net-shipped-by-fit-style-kids-top-size-mtd"
              >
                K-TOPSIZE-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="net-shipped-by-fit-style-pets-top-size-mtd"
              >
                P-TOPSIZE-MTD
              </NavLink>
            </div>
            {props.querytype ? (
              <div className="report-result-right-top">
                <button
                  id="close-dialog-btn"
                  onClick={() => setCloseReport(true)}
                >
                  Close
                </button>
                <button
                  id="download-excel-button"
                  onClick={() => {
                    getExcelStandard(data);
                  }}
                >
                  Download EXCEL
                </button>
                {closeReport == true ? <Redirect to="/dashboard" /> : null}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="report-data">
            {props.querytype ? (
              <Table
                defaultPageSize="1000"
                TableClassName={TableClassName}
                display={true}
                data={data}
                loading={loading}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReturnByFitStyle;
