import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { getExcelStandard } from '../../helper/excel'
import PopUp from '../common/popup';
import moment from 'moment';

const MainTable = (props) => {

    const [column, setColumns] = useState([]);
    const [displayPopup, setDisplayPopup] = useState(false);
    const [popupUrl, setpopupUrl] = useState('');
    const [popupTitle, setPopupTitle] = useState('');
    const [reportNumber, setReportNumber] = useState();

    const [orderNo, setOrderNo] = useState('');
    const [type, setType] = useState('');
    const [destroy, setDestroy] = useState(false);
    const [tabs, setTabs] = useState(false);


    useEffect(() => {
        // setSeconds(0);
        if (props.data != null) {
            destroy == true ? setDestroy(false) : setDestroy(true);
            getColumns();
        }
    }, [props.data]);
 
  
    function showPopup(record, column) {
        let columnAttr = props.columnAttribute.popup[column.field];
        let title = columnAttr && columnAttr.title ? columnAttr.title : '';
        let  reportNumber = columnAttr && columnAttr.reportNumber ? columnAttr.reportNumber : '';
        let endPoint = column.endpoint ? column.endpoint : columnAttr && columnAttr.endPoint ? columnAttr.endPoint : '';
        let tabs = columnAttr && columnAttr.tabs ? columnAttr.tabs : false;

        setOrderNo(record.orderno);
        setType(props.type);
        setTabs(tabs);
        setDisplayPopup(true);
        setPopupTitle(title ? title :'Order Detail');
        setReportNumber(reportNumber ? reportNumber:'');
        setpopupUrl('dashboardBookingBySPOrderDetail');
    }

    function getColumns() {
        if (props.data.columns != null) {
            let columns = [];
            props.data.columns.forEach((column, index) => {
                columns.push({
                    title: () => column.upper ? column.title.toUpperCase() : column.title,
                    dataIndex: column.field,
                    width: column.width && column.width != undefined ? column.width : 100,
                    align: column.convert != null && column.convert == true ? 'right' : null,
                    sorter: (a, b) => {
                        if (!Object.values(a).includes('Total')) {
                            let fielda = a[column.field];
                            let fieldb = b[column.field];
                            let isValidNumber = /^[0-9,.]*$/.test(fielda);
                            //Date Sorter
                            if (moment(fielda, "MM/DD/YYYY", true).isValid()) {
                                return new Date(fielda) - new Date(fieldb)
                            }
                            //String Sorter
                            else if (typeof fielda === 'string') {
                                if (fielda.includes('$') || isValidNumber || fielda.includes('%')) {
                                    fielda = fielda ? fielda.replaceAll('$', '') : '';
                                    fieldb = fieldb ? fieldb.replaceAll('$', '') : '';
                                    fielda = fielda ? fielda.replaceAll(',', '') : '';
                                    fieldb = fieldb ? fieldb.replaceAll(',', '') : '';
                                    fielda = fielda ? fielda.replaceAll('%', '') : '';
                                    fieldb = fieldb ? fieldb.replaceAll('%', '') : '';
                                    return fielda - fieldb;
                                }
                                return fielda.localeCompare([fieldb])
                            }
                            return fielda - fieldb;
                        }
                    },
                    sortDirections: ['descend', 'ascend'],                   
                    render: (text, record) => {
                        return column.field =='orderno' ? <button className="link-button" sku_id={text}
                            onClick={() => showPopup(record, column)}>
                            {text}</button> : column.bold != null && column.bold == true ? <b>{text}</b> : text
                    }
                });
            });
            setColumns(columns);
        }
    }

    const Loading = {
        spinning: props.loading,
    }
    return (
        <>
            { props.popupNavigation ?
                <div className="navigation-area">
                    <a id='inseam-button' key='1' className="link-react selected"
                        onClick={() => {
                            let button = document.getElementById('inseam-button');
                            button.classList.add("selected");
                            let button1 = document.getElementById('size-button');
                            button1.classList.remove('selected')
                            props.callBackFetchData('N');
                        }}> Inseam
                    </a>
                    <a id='size-button' key='2' className="link-react"
                        onClick={() => {
                            let button = document.getElementById('size-button');
                            button.classList.add("selected");
                            let button1 = document.getElementById('inseam-button');
                            button1.classList.remove('selected')
                          props.callBackFetchData('Y');
                        }}> Size
                    </a>
            </div>:''
         }

          <div className="report-result-right-top">
              <button id="download-excel-button"
                    onClick={() => {
                        setDisplayPopup(false);
                        getExcelStandard(props.data)
                    }}>Download EXCEL</button>
            </div>
            
            <Table className={'popUpTable ' + props.className}
                key={destroy}
                rowClassName={(record, index) => record.bold !== undefined? 'bold-row' : ''}
                expandable={{ defaultExpandAllRows: true }}   
                dataSource={props.data.rows}
                columns={column}
                bordered 
                loading={Loading}
                pagination={{
                    defaultPageSize: 1000,
                    showSizeChanger: true,
                    pageSizeOptions: ['15', '30', '50', '100','500','1000']
                }}
                scroll={{ x: 1500, y: 600 }} // AJ
            />
            {
            <PopUp
                className='sub-popup-table'    
                popupNavigation={props.tabs}                   
                orderNo={orderNo}
                popupUrl={popupUrl}
                popupTitle={popupTitle}
                reportNumber ={reportNumber}    
                formState={props.body}
                reportType={props.reportType}
                yearDesc={props.yearDesc}
                skuUrl={props.skuUrl}
                location={props.location}
                inseam={props.inseam}
                skuId={props.skuId}
                body={props.body}
                exfactdate={props.exfactdate}
                type={type}    
                genderID={props.genderID}
                salesPersonId={props.salesPersonId}
                display={displayPopup}
                callBack={() => setDisplayPopup(false)} />
            }
         </>
    );
}
export default MainTable;