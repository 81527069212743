import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import "./css/util.css";
import "./css/main.css";
import $ from "jquery";
import {usePopper} from "react-popper";
import "bootstrap/dist/js/bootstrap.bundle.min";

ReactDOM.render(<App />, document.getElementById("root"));

$("body").delegate(".dropdown-menu a.dropdown-toggle", "click", function () {
  if (!$(this).next().hasClass("show")) {
    $(this).parents(".dropdown-menu").first().find(".show").removeClass("show");
  }
  var $subMenu = $(this).next(".dropdown-menu");
  $subMenu.toggleClass("show");
  $(this)
    .parents("li.nav-item.dropdown.show")
    .on("hidden.bs.dropdown", function (e) {
      $(".dropdown-submenu .show").removeClass("show");
    });
  return false;
});

$("body").delegate(".dashboard-card", "click", function () {
  let currentElement = $(this);
  currentElement.parent().hide();
  let subElement = '.' + currentElement.attr('class').split(' ').pop() + '-sub';
  $(subElement).removeClass('hidden');
  $(subElement).show();
});

$("body").delegate(".back-button", "click", function () {
  let currentElement = $(this);
  currentElement.parent().hide();
  let subElement = '.' + currentElement.parent().attr('class').split(' ').pop().split('-')[0];
  $(subElement).parent().show();
});


// https://stackoverflow.com/questions/24933430/img-src-svg-changing-the-fill-color

// http://jsfiddle.net/wuSF7/462/


  // $("body").delegate("#root", "change", function () {
    

  // $('img.svg').each(function () {
  //     var $img = $(this);
  //     var imgID = $img.attr('id');
  //     var imgClass = $img.attr('class');
  //     var imgURL = $img.attr('src');
  
  //     $.get(imgURL, function(data) {
  //         // Get the SVG tag, ignore the rest
  //         var $svg = $(data).find('svg');
  
  //         // Add replaced image's ID to the new SVG
  //         if(typeof imgID !== 'undefined') {
  //             $svg = $svg.attr('id', imgID);
  //         }
  //         // Add replaced image's classes to the new SVG
  //         if(typeof imgClass !== 'undefined') {
  //             $svg = $svg.attr('class', imgClass+' replaced-svg');
  //         }
  
  //         // Remove any invalid XML tags as per http://validator.w3.org
  //         $svg = $svg.removeAttr('xmlns:a');
          
  //         // Check if the viewport is set, else we gonna set it if we can.
  //         if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
  //             $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
  //         }
  
  //         // Replace image with new SVG
  //         $img.replaceWith($svg);
  
  //     }, 'xml');
  
  // });
//});
    
  
