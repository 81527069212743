import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Header from "../header/header";
// import DashboardCardLeft from "./dashboardCardLeft";
// import DashboardCardRight from "./dashboardCardRight";
// import config from "./../../config/config";

const Dashboard = () => {
  // const [display, setDisplay] = useState(false);
  return (
    <div>
      <Header />
      <div className="dash-body">
        <div className="desktop-dashboard" style={{paddingLeft: "2%"}}>
          <h2>Welcome To OMS</h2>
        </div>
        <div className="dashboard-cards">
          <div className="dashboard-card dashboardmenu">
            <span>
              <i className="fa fa-bar-chart" aria-hidden="true"></i>
            </span>
            Dashboard
          </div>
          <div className="dashboard-card salesmenu">
            <span>
              <i className="fa fa-bar-chart" aria-hidden="true"></i>
            </span>
            Sales
          </div>
          <div className="dashboard-card purchasemenu">
            <span>
              <i className="fa fa-bar-chart" aria-hidden="true"></i>
            </span>
            Purchase
          </div>
          <div className="dashboard-card inventorymenu">
            <span>
              <i className="fa fa-bar-chart" aria-hidden="true"></i>
            </span>
            Inventory
          </div>
          <div className="dashboard-card mastermenu">
            <span>
              <i className="fa fa-bar-chart" aria-hidden="true"></i>
            </span>
            Master
          </div>
          <div className="dashboard-card stockmenu">
            <span>
              <i className="fa fa-bar-chart" aria-hidden="true"></i>
            </span>
            Stock
          </div>
        </div>
        <div className="dashboard-cards hidden dashboardmenu-sub">
          <span href="#" className="previous back-button">
            <i className="fa fa-angle-left"></i> Dashboard
          </span>
          <div className="dashboard-card bookedmenu">
            <span>
              <i className="fa fa-bar-chart" aria-hidden="true"></i>
            </span>
            Booked
          </div>
          <div className="dashboard-card pendingmenu">
            <span>
              <i className="fa fa-bar-chart" aria-hidden="true"></i>
            </span>
            Pending Order
          </div>

          <div className="dashboard-card pendingpodmenu">
            <span>
              <i className="fa fa-bar-chart" aria-hidden="true"></i>
            </span>
            Pending pod
          </div>

          <div className="dashboard-card shippedmenu">
            <span>
              <i className="fa fa-bar-chart" aria-hidden="true"></i>
            </span>
            Shipped
          </div>

          <div className="dashboard-card returnedmenu">
            <span>
              <i className="fa fa-bar-chart" aria-hidden="true"></i>
            </span>
            Returned
          </div>
          <div className="dashboard-card netshippedmenu">
            <span>
              <i className="fa fa-bar-chart" aria-hidden="true"></i>
            </span>
            Net Shipped
          </div>
          <Link className="link-react" to="/e-commerce-by-region-ytd">
            <div className="dashboard-card ecommerceedmenu">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              E-commerce
            </div>
          </Link>
          <Link className="link-react" to="/business-leaderboard">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Business Dashboard
            </div>
          </Link>

          <Link className="link-react" to="/business-leaderboard-region-wise">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Business Dashboard - Region Wise
            </div>
          </Link>
        </div>
        <div className="dashboard-cards hidden bookedmenu-sub">
          <span href="#" className="previous back-button">
            <i className="fa fa-angle-left"></i> Dashboard{" "}
            <i className="fa fa-angle-left"></i> Booked
          </span>
          <Link className="link-react" to="/bookings-by-sale-person">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Booked By Sale Person
            </div>
          </Link>

          <Link className="link-react" to="/booking-by-gender">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Booked By Gender
            </div>
          </Link>

          <Link className="link-react" to="/bookings-by-customer">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Booked By Customer Top 30
            </div>
          </Link>

          <Link className="link-react" to="/bookings-by-region">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Booked By Region
            </div>
          </Link>

          <Link className="link-react" to="/bookings-by-fit-style">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Booked By FIT/Style
            </div>
          </Link>
        </div>
        <div className="dashboard-cards hidden pendingmenu-sub">
          <span href="#" className="previous back-button">
            <i className="fa fa-angle-left"></i> Dashboard{" "}
            <i className="fa fa-angle-left"></i> Pending Order
          </span>
          <Link className="link-react" to="/pending-by-sale-person-">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Pending By Sale Person
            </div>
          </Link>

          <Link className="link-react" to="/pending-by-gender">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Pending By Gender
            </div>
          </Link>

          <Link className="link-react" to="/pending-by-customer">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Pending By Customer Top 30
            </div>
          </Link>

          <Link className="link-react" to="/pending-by-region">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Pending By Region
            </div>
          </Link>

          <Link className="link-react" to="/pending-by-fit-style">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Pending By FIT/Style
            </div>
          </Link>
        </div>
        <div className="dashboard-cards hidden pendingpodmenu-sub">
          <span href="#" className="previous back-button">
            <i className="fa fa-angle-left"></i> Dashboard{" "}
            <i className="fa fa-angle-left"></i> Pending Pod
          </span>
          <Link className="link-react" to="/pending-pod-by-sale-person">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Pending Pod By Sale Person
            </div>
          </Link>

          <Link className="link-react" to="/pending-pod-by-region">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Pending Pod By Region
            </div>
          </Link>
        </div>
        <div className="dashboard-cards hidden shippedmenu-sub">
          <span href="#" className="previous back-button">
            <i className="fa fa-angle-left"></i> Dashboard{" "}
            <i className="fa fa-angle-left"></i> Shipped
          </span>
          <Link className="link-react" to="/shipped-by-sale-person">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Shipped By Sale Person
            </div>
          </Link>

          <Link className="link-react" to="/shipped-by-gender">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Shipped By Gender
            </div>
          </Link>

          <Link className="link-react" to="/shipped-by-customer">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Shipped By Customer Top 30
            </div>
          </Link>

          <Link className="link-react" to="/shipped-by-region">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Shipped By Region
            </div>
          </Link>

          <Link className="link-react" to="/shipped-by-fit-style">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Shipped By FIT/Style
            </div>
          </Link>
        </div>
        <div className="dashboard-cards hidden returnedmenu-sub">
          <span href="#" className="previous back-button">
            <i className="fa fa-angle-left"></i> Dashboard{" "}
            <i className="fa fa-angle-left"></i> Return
          </span>
          <Link className="link-react" to="/return-by-sale-person">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Returned By Sale Person
            </div>
          </Link>

          <Link className="link-react" to="/return-by-gender">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Returned By Gender
            </div>
          </Link>

          <Link className="link-react" to="/return-by-customer">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Returned By Customer Top 30
            </div>
          </Link>

          <Link className="link-react" to="/return-by-region">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Returned By Region
            </div>
          </Link>

          <Link className="link-react" to="/return-by-fit-style">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Returned By FIT/Style
            </div>
          </Link>
        </div>
        <div className="dashboard-cards hidden netshippedmenu-sub">
          <span href="#" className="previous back-button">
            <i className="fa fa-angle-left"></i> Dashboard{" "}
            <i className="fa fa-angle-left"></i> Net Shipped
          </span>
          <Link className="link-react" to="/net-shipped-by-sale-person">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Net Shipped By Sale Person
            </div>
          </Link>

          <Link className="link-react" to="/net-shipped-by-region">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Net Shipped By Region
            </div>
          </Link>

          <Link className="link-react" to="/net-shipped-by-gender">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Net Shipped By Gender
            </div>
          </Link>

          <Link className="link-react" to="/net-shipped-by-fit-style">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Net Shipped By Fit/Style
            </div>
          </Link>

          <Link className="link-react" to="/net-shipped-by-customer">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Net Shipped By Customer Top 30
            </div>
          </Link>
        </div>
        <div className="dashboard-cards hidden salesmenu-sub">
          <span href="#" className="previous back-button">
            <i className="fa fa-angle-left"></i> Sales
          </span>
          <Link className="link-react" to="/order-by-po">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Order By PO #
            </div>
          </Link>

          <Link className="link-react" to="/order-by-product">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Order By Product
            </div>
          </Link>

          <Link className="link-react" to="/sale-comparison-report">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Sales Targets
            </div>
          </Link>
        </div>
        <div className="dashboard-cards hidden purchasemenu-sub">
          <span href="#" className="previous back-button">
            <i className="fa fa-angle-left"></i> Purchase
          </span>
          <Link className="link-react" to="/purchase-order">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Purchase Order
            </div>
          </Link>
        </div>
        <div className="dashboard-cards hidden inventorymenu-sub">
          <span href="#" className="previous back-button">
            <i className="fa fa-angle-left"></i> Inventory
          </span>
          <Link className="link-react" to="/sku-lookupv2">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              SKU Lookup
            </div>
          </Link>

          <div className="dashboard-card otsmenu">
            <span>
              <i className="fa fa-bar-chart" aria-hidden="true"></i>
            </span>
            OTS
          </div>

          <div className="dashboard-card productionmenu">
            <span>
              <i className="fa fa-bar-chart" aria-hidden="true"></i>
            </span>
            Production
          </div>

          <Link className="link-react" to="/b-grade-stock-report">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              B Grade Stock
            </div>
          </Link>
        </div>
        <div className="dashboard-cards hidden otsmenu-sub">
          <span href="#" className="previous back-button">
            <i className="fa fa-angle-left"></i> Inventory{" "}
            <i className="fa fa-angle-left"></i> OTS
          </span>
          <Link className="link-react" to="/inventory-ots-summary">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Summary
            </div>
          </Link>

          <Link className="link-react" to="/inventory-ots-detail">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Detail
            </div>
          </Link>
        </div>
        <div className="dashboard-cards hidden productionmenu-sub">
          <span href="#" className="previous back-button">
            <i className="fa fa-angle-left"></i> Dashboard{" "}
            <i className="fa fa-angle-left"></i> Production
          </span>
          <Link className="link-react" to="/inventory-production-detail">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Detail
            </div>
          </Link>
        </div>
        <div className="dashboard-cards hidden mastermenu-sub">
          <span href="#" className="previous back-button">
            <i className="fa fa-angle-left"></i> Master
          </span>

          <div className="dashboard-card productmenu">
            <span>
              <i className="fa fa-bar-chart" aria-hidden="true"></i>
            </span>
            Products
          </div>

          <Link className="link-react" to="/customer-report">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Customer
            </div>
          </Link>
        </div>
        <div className="dashboard-cards hidden productmenu-sub">
          <span href="#" className="previous back-button">
            <i className="fa fa-angle-left"></i> Master{" "}
            <i className="fa fa-angle-left"></i> Products
          </span>
          <Link className="link-react" to="/product-report">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Products
            </div>
          </Link>
          <Link className="link-react" to="/product-composition-report">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Products UPC
            </div>
          </Link>
        </div>
        <div className="dashboard-cards hidden stockmenu-sub">
          <span href="#" className="previous back-button">
            <i className="fa fa-angle-left"></i> Stock
          </span>
          <Link className="link-react" to="/goods-required">
            <div className="dashboard-card">
              <span>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </span>
              Stock
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

// const Dashboard = () => {

//     return (
//         <div>
//             <Header />
//             <div className="dash-body">
//                 <div style={{paddingLeft:'2%'}}>
//                     <h2>Welcome To OMS</h2>
//                 </div>

//                 {/* <div className="dash-card-left-div"> */}
//                 {/* <DashboardCardLeft/> */}
//                 {/* <DashboardCardLeft/> */}
//                 {/* <DashboardCardLeft/> */}
//                 {/* <DashboardCardLeft/> */}
//                 {/* </div> */}
//                 {/* <div className="dash-card-right-div" > */}
//                 {/* <DashboardCardRight/> */}
//                 {/* <DashboardCardRight/> */}
//                 {/* <DashboardCardRight/> */}
//                 {/* <DashboardCardRight/> */}
//                 {/* </div> */}
//             </div>
//         </div>
//     );
// }

export default Dashboard;
