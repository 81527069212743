import React from 'react';
const reportsType =  require('./../../../../data/stockReport/reports.json');

const StockReportType = ( props ) => {


    function onChange( type ) {
        props.reportTypeData( type );
    }

    function renderReportType() {
        return reportsType.map( type => {
            return(
                <span key={ type.id } className="st-btns-right">
                        <label><input  type="radio" id={ type.id } name="reportype" onChange={ onChange.bind( this, type ) }/><span>{ type.value }</span></label>
                </span>
            );
        });
    }

    return(
        <div className="report-filter-right">   
        <h3 className="filter-title">Reports</h3>
            <div className="btns-main-right">
                {
                    renderReportType()
                }
            </div>
        </div>
    );
}

export default StockReportType;