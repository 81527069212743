import React, { useState, useEffect } from 'react';
import Header from '../../../header/header';
import DetailReportFilter from './StockLocationWiseReportFilter';
import ProductStatus from '../../../common/productStatus'
import Active from '../../../common/active';
import Date from '../../../common/date'
import config from '../../../../config/config';
import ReportDialog from '../../../common/reportDialog';
import { Redirect } from 'react-router';
import moment from 'moment';
import StockLocationWiseReportLocationFilter from './StockLocationWiseReportLocationFilter';


const URL = config.STOCK_LOCATION_WISE;
const PDF_URL = config.STOCK_LOCSTION_WISE_PDF_URL;
const SKU_URL = config.GET_ALL_URL;
const PRODUCT_STATUS = "&productStatus=";
const IS_INACTIVE = "&isInActive=";
const SORT_ON = "&sortOn=";

const StockLocationWise = () => {


    const [stockReportSortsOn, setStockReportSortsOn] = useState('');

    const [groupOnArray, setGroupOnArray] = useState([]);
    const [OrderOnArray, setOrderOnArray] = useState([]);

    const [Order, setOrder] = useState('DESC');

    const [closeReport, setCloseReport] = useState(false);

    const [date, setDate] = useState({});

    // Report filters
    const [isInactive, setIsInActive] = useState("F");
    const [selectedProductStatus, setSelectedProductStatus] = useState('1');
    const [searchState, setSearchState] = useState(false);
    const [formState, setFormState] = useState({});

    const [display, setDisplay] = useState(false);
    const [reportUrl, setReportUrl] = useState();
    const [pdfUrl, setPDFUrl] = useState();
    const [skuUrl, setSKUUrl] = useState(SKU_URL);
    const [pdfReportId, setPdfReportId] = useState();
    const hyperLinks = [];

    const [isGroup, setIsGroup] = useState(true);
    const [IsOrder, setIsOrder] = useState(false);

    //SET RADIO BUTTON IDS
    const [id, setId] = useState(['DESC', 'ASC']);

    const [stockReportGroup, setStockReportGroup] = useState('');
    const [OrderReportGroup, setOrderReportGroup] = useState('');

    useEffect(() => {
        document.getElementById('genreport').disabled = true
        document.getElementById('genreport').classList.add('disabled');
    }, [])


    function showTable() {
        let url = URL + PRODUCT_STATUS + selectedProductStatus;
        let pdfUrl = PDF_URL + PRODUCT_STATUS + selectedProductStatus;

        if (stockReportSortsOn != '') {
            url += SORT_ON + stockReportSortsOn;
            pdfUrl += SORT_ON + stockReportSortsOn;
        }

        setFormState({ ...formState, "groupBy": groupOnArray, "order": Order, "orderBy": OrderOnArray, "fromdate": moment(date.fromDate).format('MM/DD/YYYY') });
        url += IS_INACTIVE + isInactive;
        pdfUrl += IS_INACTIVE + isInactive;
        //  url += "&fromDate=" + moment(date.fromDate).format('MM/DD/YYYY') + "&toDate=" + moment(date.toDate).format('MM/DD/YYYY');
        pdfUrl += "&fromDate=" + moment(date.fromDate).format('MM/DD/YYYY') + "&toDate=" + moment(date.toDate).format('MM/DD/YYYY');
        setPDFUrl(pdfUrl);
        setReportUrl(url);
        setDisplay(true);

    }

    function stockReportGroupValue(groupId) {
        // Change the group state so it can disabled or enabled sortOn by calling it.
        groupId == "1" ? setIsGroup(false) : setIsGroup(true); // If id is 1 then set group to true else false.
        setStockReportGroup(groupId);
    }

    function OrderBy(groupId) {
        // Change the group state so it can disabled or enabled sortOn by calling it.
        groupId == "1" ? setIsOrder(false) : setIsOrder(true); // If id is 1 then set group to true else false.
        setOrderReportGroup(groupId);
    }

    return (
        <div>
            <Header />
            <div className="main-content ots-detail-filter">
                <div className="report-container group">
                    <div className="report-container-header">
                        <h1>Stock Location Wise</h1>
                        <button id="close-report-btn" onClick={() => setCloseReport(true)} >Close</button>
                        {closeReport == true ? <Redirect to="/dashboard" /> : null}
                    </div>
                    <div className="report-container-inner">
                        <div className="r-left">
                            <Date dateData={(date) => setDate(date)} defaultDate={{ fromDate: "2023-12-31" }} />

                            <ProductStatus statusData={(id) => { setSelectedProductStatus(id); }} />
                            {/* <StockLocationWiseReportLocationFilter
                                filterData={(state) => { setFormLocation(state) }}
                                productStatus={selectedProductStatus}
                                isInActive={isInactive}
                                searchState={searchState}
                                date={date}
                            /> */}
                            <DetailReportFilter
                                filterData={(state) => { setFormState(state) }}
                                productStatus={selectedProductStatus}
                                isInActive={isInactive}
                                searchState={searchState}
                                date={date}
                            />
                        </div>

                        <div className="r-right">
                            <Active sendStatus={(a) => { a == 'active-1' ? setIsInActive('F') : setIsInActive('T') }} />
                            <button className="report-submit-btn" id="genreport" onClick={showTable}>SEARCH</button>
                        </div>
                    </div>
                </div>
                <ReportDialog
                    TableClassName="goodRequiredTable ots-detail-report"
                    defaultPageSize={100}
                    display={display}
                    callBack={() => { setDisplay(false) }}
                    url={reportUrl}
                    pdfUrl=''
                    skuUrl={skuUrl}
                    hyperLinks={hyperLinks}
                    body={formState}
                    pdfReportId={pdfReportId}
                    fromDate={date.fromDate}
                    toDate={date.toDate}
                />
            </div>
        </div>
    );
}
export default StockLocationWise;