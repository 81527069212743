import React, {useEffect, useState} from "react";
import Select from "react-select";
import "./../../css/main.css";

/**
 *
 * @param props // props.list will be must required prop to call this component. In other case application can crash.
 */
const AutoCompleteSelect = (props) => {
  // 'ALL' option at the start of every filter.
  const allFilterObject = {value: "-1", label: "All"}; // Keep the value -1 to know 'ALL' is selected.

  // Add to the array.

  const optionList = [allFilterObject].concat(
    props.list != null ? props.list : []
  ); // If list is not present in prop then concat 'ALL' option with empty array.

  if (props.defaultValue) {
    optionList[0] = "";
  }

  // Selected Option.
  const [selectedOption, setselectedOption] = useState(optionList[0]);

  useEffect(() => {
    clear();
    //if data exist
    if (!props.loading && props.defaultValue && optionList.length > 1) {
      onChangeValue(optionList[props.defaultValue]);
    }
  }, [props.list]);

  // Custom style for the react-select-library.
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#999",
      // minHeight: '35px',
      // height: '35px',
      boxShadow: state.isFocused ? null : null,
      fontSize: "14px",
      cursor: "pointer",
    }),
    valueContainer: (provided) => ({
      ...provided,
      // height: '35px',
      padding: "0 6px",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0px",
      height: "35px",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "35px",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    option: (provided, {isFocused, isSelected}) => ({
      ...provided,
      fontSize: "14px",
      background: props.SelectBackgroundColor,
      padding: "2px 10px",
      cursor: "pointer",
      marginBottom: "2px",
      color: isSelected || isFocused ? "#fff" : null,
    }),
  };

  // Theme to add in react-select-library.
  const theme = (theme) => ({
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      primary25: "#007bff",
      primary: "#007bff",
    },
  });

  // Callback to return the value of selected item in select.
  function onChangeValue(selectedOption) {
    if (props.multiple) {
      setselectedOption(selectedOption);
      props.callBack(selectedOption);
    } else {
      setselectedOption(selectedOption);
      props.callBack(selectedOption.value);
      if (props.callBackText) props.callBackText(selectedOption.label);
    }
  }

  // Function the clear the list i.e with 'ALL' option.
  function clear() {
    // If 'ALL' option not selected then call onChange otherwise it will load next data every time clear presses.
    if (selectedOption.value != optionList[0].value) {
      onChangeValue(optionList[0]); // Call onChangeValue function with first option.
    }
  }

  return (
    <>
      <div className="auto-select">
        <Select
          className={props.className ? props.className : "select-box"}
          isMulti={props.multiple ? true : false}
          id={props.name + "-react-select"}
          styles={customStyles}
          options={optionList}
          defaultValue={optionList[0]}
          value={selectedOption}
          theme={theme}
          onChange={onChangeValue}
          isLoading={props.loading}
        />
      </div>
      <button
        
        disabled={props.disabled?'disabled':''}
        
        className="auto-complete-text-btn" onClick={clear}>
        CLEAR
      </button>
    </>
  );
};
export default AutoCompleteSelect;
