import React, { useEffect } from 'react';
const status = require('./../../../../data/stockReport/status.json');


const StockReport = ( props ) => {

    function returnStatus( filter ) {
        props.statusData( filter.id );
    }

    useEffect( () => {
        returnStatus( status[0] );
    },[])

    function renderFilter() {
        return status.map( ( filter, index ) => {
            return (
                <div key={ filter.id }>
                    <span className="st-btns">
                        <label><input  type="radio" name="status" id={ filter.id } onChange={ returnStatus.bind( this, filter ) } defaultChecked={ index === 0 } /><span>{ filter.value }</span></label>
                    </span>                  
                </div>
            );
        })
    } 

    return(
        <div className="status-filter">
            <h3 className="filter-title">Status</h3>
            <div className="status-buttons">
                <div className="btns-main">
                    { renderFilter() }
                </div>
            </div>
        </div>
    );
}

export default StockReport;