import React from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import StockReport from './components/reports/inventory/stock/stockReport';
import BGradeStockReport from './components/reports/inventory/stock/bGradeStockReport';
import Login from './components/login/login';
import AdminLogin from './components/admin/login';
import Dashboard from './components/dashboard/dashboard';
import ProductReport from './components/reports/master/product/productReport';
import ProductCompositionReport from './components/reports/master/product/productCompositionReport';
import CustomerReport from './components/reports/master/customer/customerReport';
import SalesReport from './components/reports/sales/salesReport';
import SalesComparisonReport from './components/reports/sales/salesComparisonReport';
import OrderByProduct from './components/reports/sales/orderByProduct';
import OrderBooking from './components/reports/sales/orderBooking';
import SKU_LOOKUP from './components/reports/inventory/skuLookup';
import SKU_LOOKUPV2 from './components/reports/inventory/skuLookupv2';
import CUMULATIVE_OTS from './components/reports/inventory/cumulativeOts';
import InventoryProductReport from './components/reports/inventory/product/productInventoryReport';
import InventoryOtsDetailReport from './components/reports/inventory/ots/detailReport';
import InventoryOtsSummaryReport from './components/reports/inventory/ots/summaryReport';
import InventoryProductionDetailReport from './components/reports/inventory/production/detailReport';
import InventoryProductionSummaryReport from './components/reports/inventory/production/summaryReport';

import BookingBySalePerson from './components/reports/dashboard/leaderboards/bookingBySalePerson';
import BookingByGender from './components/reports/dashboard/leaderboards/bookingByGender';
import BookingByCustomer from './components/reports/dashboard/leaderboards/bookingByCustomer';
import BookingByRegion from './components/reports/dashboard/leaderboards/bookingByRegion';
import BookingByFitStyle from './components/reports/dashboard/leaderboards/bookingByFitStyle';

import ShippedBySalePerson from './components/reports/dashboard/leaderboards/shippedBySalePerson';
import ShippedByGender from './components/reports/dashboard/leaderboards/shippedByGender';
import ShippedByCustomer from './components/reports/dashboard/leaderboards/shippedByCustomer';
import ShippedByRegion from './components/reports/dashboard/leaderboards/shippedByRegion';
import ShippedByRegionNew from './components/reports/dashboard/leaderboards/shippedByRegionNew';
import ShippedByFitStyle from './components/reports/dashboard/leaderboards/shippedByFitStyle';

import PendingBySalePerson from './components/reports/dashboard/leaderboards/pendingBySalePerson';
import PendingByGender from './components/reports/dashboard/leaderboards/pendingByGender';
import PendingByCustomer from './components/reports/dashboard/leaderboards/pendingByCustomer';
import PendingByRegion from './components/reports/dashboard/leaderboards/pendingByRegion';
import PendingByFitStyle from './components/reports/dashboard/leaderboards/pendingByFitStyle';

import PendingPodBySalePerson from './components/reports/dashboard/leaderboards/pendingPodBySalePerson';
import PendingPodByRegion from './components/reports/dashboard/leaderboards/pendingPodByRegion';

import ReturnBySalePerson from './components/reports/dashboard/leaderboards/returnBySalePerson';
import ReturnByGender from './components/reports/dashboard/leaderboards/returnByGender';
import ReturnByCustomer from './components/reports/dashboard/leaderboards/returnByCustomer';
import ReturnByRegion from './components/reports/dashboard/leaderboards/returnByRegion';
import ReturnByFitStyle from './components/reports/dashboard/leaderboards/returnByFitStyle';

import NetShippedBySalePerson from './components/reports/dashboard/leaderboards/netShippedBySalePerson';
import NetShippedByGender from './components/reports/dashboard/leaderboards/netShippedByGender';
import NetShippedByCustomer from './components/reports/dashboard/leaderboards/netShippedByCustomer';
import NetShippedByRegion from './components/reports/dashboard/leaderboards/netShippedByRegion';
import NetShippedByFitStyle from './components/reports/dashboard/leaderboards/netShippedByFitStyle';



import Ecommerce from './components/reports/dashboard/leaderboards/ecommerce';
import NetShipped from './components/reports/dashboard/leaderboards/netshipped';

import BusinessBoard from './components/reports/dashboard/leaderboards/businessBoard';
import BusinessBoardRegionWise from './components/reports/dashboard/leaderboards/businessDashboardRegionWise';
import BusinessBoardTest from './components/reports/dashboard/leaderboards/businessBoardTest';
import PurchaseOrderReport from './components/reports/purchase/purchaseOrderReport'
import Invoice from './components/reports/invoice/invoice'
import SkuWiseSearch from './components/reports/sku/skuWiseSearch';
import GoodsRequired from './components/reports/sku/goodsRequired';
import AdminConfig from './components/admin/config';
import StockLocationWise from './components/reports/inventory/stock/StockLocationWisedetailReport';


const App = () => {
  return (
    <>
      <Router>
        <Switch >
          <Route path="/" exact component={Login} ></Route>
          <Route path="/admin/login" component={AdminLogin} ></Route>
          <Route path="/dashboard" component={Dashboard} ></Route>
          <Route path="/bookings-by-sale-person" render={routeProps => <BookingBySalePerson {...routeProps} reportType='' reportName="Booked By SalePerson" reportNumber='' genderId='-1' key={Date.now()} />} />
          <Route path="/bookings-by-sale-person-ytd" render={routeProps => <BookingBySalePerson {...routeProps} reportType='ytd' reportName="Booked By SalePerson - YTD" reportNumber='RPT#DB-0001' genderId='-1' key={Date.now()} />} />
          <Route path="/bookings-by-sale-person-mtd" render={routeProps => <BookingBySalePerson {...routeProps} reportType='mtd' reportName="Booked By SalePerson - MTD" reportNumber='RPT#DB-0002' genderId='-1' key={Date.now()} />} />
          <Route path="/bookings-by-sale-person-women-ytd" render={routeProps => <BookingBySalePerson {...routeProps} reportType='ytd' reportName="Booked By SalePerson - Women - YTD" reportNumber='RPT#DB-0003' genderId='7' key={Date.now()} />} />
          <Route path="/bookings-by-sale-person-women-mtd" render={routeProps => <BookingBySalePerson {...routeProps} reportType='mtd' reportName="Booked By SalePerson - Women - MTD" reportNumber='RPT#DB-0004' genderId='7' key={Date.now()} />} />
          <Route path="/bookings-by-sale-person-men-ytd" render={routeProps => <BookingBySalePerson {...routeProps} reportType='ytd' reportName="Booked By SalePerson - Men - YTD" reportNumber='RPT#DB-0005' genderId='9' key={Date.now()} />} />
          <Route path="/bookings-by-sale-person-men-mtd" render={routeProps => <BookingBySalePerson {...routeProps} reportType='mtd' reportName="Booked By SalePerson - Men - MTD" reportNumber='RPT#DB-0006' genderId='9' key={Date.now()} />} />
          <Route path="/bookings-by-sale-person-kids-ytd" render={routeProps => <BookingBySalePerson {...routeProps} reportType='ytd' reportName="Booked By SalePerson - Kids - YTD" reportNumber='RPT#DB-0007' genderId='13' key={Date.now()} />} />
          <Route path="/bookings-by-sale-person-kids-mtd" render={routeProps => <BookingBySalePerson {...routeProps} reportType='mtd' reportName="Booked By SalePerson - Kids - MTD" reportNumber='RPT#DB-0008' genderId='13' key={Date.now()} />} />
          <Route path="/bookings-by-sale-person-pets-ytd" render={routeProps => <BookingBySalePerson {...routeProps} reportType='ytd' reportName="Booked By SalePerson - Pet - YTD" reportNumber='RPT#DB-0009' genderId='12' key={Date.now()} />} />
          <Route path="/bookings-by-sale-person-pets-mtd" render={routeProps => <BookingBySalePerson {...routeProps} reportType='mtd' reportName="Booked By SalePerson - Pet - MTD" reportNumber='RPT#DB-0010' genderId='12' key={Date.now()} />} />

          <Route path='/booking-by-gender' render={routeProps => <BookingByGender {...routeProps} reportType='' reportName="Booked By Gender" reportNumber='' genderId='-1' key={Date.now()} />} />
          <Route path='/booking-by-gender-ytd' render={routeProps => <BookingByGender {...routeProps} reportType='ytd' reportName="Booked By Gender - YTD" reportNumber='RPT#DB-0011' genderId='-1' key={Date.now()} />} />
          <Route path='/booking-by-gender-mtd' render={routeProps => <BookingByGender {...routeProps} reportType='mtd' reportName="Booked By Gender - MTD" reportNumber='RPT#DB-0012' genderId='-1' key={Date.now()} />} />

          <Route path="/bookings-by-customer" render={routeProps => <BookingByCustomer {...routeProps} reportType='' reportName="Booked By Customer Top 30" reportNumber='' genderId='-1' key={Date.now()} />} />
          <Route path="/bookings-by-customer-ytd" render={routeProps => <BookingByCustomer {...routeProps} reportType='ytd' reportName="Booked By Customer Top 30 - YTD" reportNumber='RPT#DB-0013' genderId='-1' key={Date.now()} />} />
          <Route path="/bookings-by-customer-mtd" render={routeProps => <BookingByCustomer {...routeProps} reportType='mtd' reportName="Booked By Customer Top 30 - MTD" reportNumber='RPT#DB-0014' genderId='-1' key={Date.now()} />} />
          <Route path="/bookings-by-customer-women-ytd" render={routeProps => <BookingByCustomer {...routeProps} reportType='ytd' reportName="Booked By Customer Top 30 - Women - YTD" reportNumber='RPT#DB-0015' genderId='7' key={Date.now()} />} />
          <Route path="/bookings-by-customer-women-mtd" render={routeProps => <BookingByCustomer {...routeProps} reportType='mtd' reportName="Booked By Customer Top 30 - Women - MTD" reportNumber='RPT#DB-0016' genderId='7' key={Date.now()} />} />
          <Route path="/bookings-by-customer-men-ytd" render={routeProps => <BookingByCustomer {...routeProps} reportType='ytd' reportName="Booked By Customer Top 30 - Men - YTD" reportNumber='RPT#DB-0017' genderId='9' key={Date.now()} />} />
          <Route path="/bookings-by-customer-men-mtd" render={routeProps => <BookingByCustomer {...routeProps} reportType='mtd' reportName="Booked By Customer Top 30 - Men - MTD" reportNumber='RPT#DB-0018' genderId='9' key={Date.now()} />} />
          <Route path="/bookings-by-customer-kids-ytd" render={routeProps => <BookingByCustomer {...routeProps} reportType='ytd' reportName="Booked By Customer Top 30 - Kids - YTD" reportNumber='RPT#DB-0019' genderId='13' key={Date.now()} />} />
          <Route path="/bookings-by-customer-kids-mtd" render={routeProps => <BookingByCustomer {...routeProps} reportType='mtd' reportName="Booked By Customer Top 30 - Kids - MTD" reportNumber='RPT#DB-0020' genderId='13' key={Date.now()} />} />
          <Route path="/bookings-by-customer-pets-ytd" render={routeProps => <BookingByCustomer {...routeProps} reportType='ytd' reportName="Booked By Customer Top 30 - Pet - YTD" reportNumber='RPT#DB-0021' genderId='12' key={Date.now()} />} />
          <Route path="/bookings-by-customer-pets-mtd" render={routeProps => <BookingByCustomer {...routeProps} reportType='mtd' reportName="Booked By Customer Top 30 - Pet - MTD" reportNumber='RPT#DB-0022' genderId='12' key={Date.now()} />} />
          <Route path="/bookings-by-customer-ytd" render={routeProps => <BookingByCustomer {...routeProps} reportType='ytd' reportName="Booked By Customer Top 30 - YTD" reportNumber='RPT#DB-0023' genderId='-1' key={Date.now()} />} />
          <Route path="/bookings-by-customer-mtd" render={routeProps => <BookingByCustomer {...routeProps} reportType='mtd' reportName="Booked By Customer Top 30 - MTD" reportNumber='RPT#DB-0024' genderId='-1' key={Date.now()} />} />
          <Route path="/bookings-by-customer-women-ytd" render={routeProps => <BookingByCustomer {...routeProps} reportType='ytd' reportName="Booked By Customer Top 30 - Women - YTD" reportNumber='RPT#DB-0025' genderId='7' key={Date.now()} />} />
          <Route path="/bookings-by-customer-women-mtd" render={routeProps => <BookingByCustomer {...routeProps} reportType='mtd' reportName="Booked By Customer Top 30 - Women - MTD" reportNumber='RPT#DB-0026' genderId='7' key={Date.now()} />} />
          <Route path="/bookings-by-customer-men-ytd" render={routeProps => <BookingByCustomer {...routeProps} reportType='ytd' reportName="Booked By Customer Top 30 - Men - YTD" reportNumber='RPT#DB-0027' genderId='9' key={Date.now()} />} />
          <Route path="/bookings-by-customer-men-mtd" render={routeProps => <BookingByCustomer {...routeProps} reportType='mtd' reportName="Booked By Customer Top 30 - Men - MTD" reportNumber='RPT#DB-0028' genderId='9' key={Date.now()} />} />
          <Route path="/bookings-by-customer-kids-ytd" render={routeProps => <BookingByCustomer {...routeProps} reportType='ytd' reportName="Booked By Customer Top 30 - Kids - YTD" reportNumber='RPT#DB-0029' genderId='13' key={Date.now()} />} />
          <Route path="/bookings-by-customer-kids-mtd" render={routeProps => <BookingByCustomer {...routeProps} reportType='mtd' reportName="Booked By Customer Top 30 - Kids - MTD" reportNumber='RPT#DB-0030' genderId='13' key={Date.now()} />} />
          <Route path="/bookings-by-customer-pets-ytd" render={routeProps => <BookingByCustomer {...routeProps} reportType='ytd' reportName="Booked By Customer Top 30 - Pet - YTD" reportNumber='RPT#DB-0031' genderId='12' key={Date.now()} />} />
          <Route path="/bookings-by-customer-pets-mtd" render={routeProps => <BookingByCustomer {...routeProps} reportType='mtd' reportName="Booked By Customer Top 30 - Pet - MTD" reportNumber='RPT#DB-0032' genderId='12' key={Date.now()} />} />

          <Route path="/bookings-by-region" render={routeProps => <BookingByRegion {...routeProps} reportType='' reportName="Booked By Region" reportNumber='' genderId='-1' key={Date.now()} />} />
          <Route path="/bookings-by-region-ytd" render={routeProps => <BookingByRegion {...routeProps} reportType='ytd' reportName="Booked By Region - YTD" reportNumber='RPT#DB-0033' genderId='-1' key={Date.now()} />} />
          <Route path="/bookings-by-region-mtd" render={routeProps => <BookingByRegion {...routeProps} reportType='mtd' reportName="Booked By Region - MTD" reportNumber='RPT#DB-0034' genderId='-1' key={Date.now()} />} />
          <Route path="/bookings-by-region-women-ytd" render={routeProps => <BookingByRegion {...routeProps} reportType='ytd' reportName="Booked By Region - Women - YTD" reportNumber='RPT#DB-0035' genderId='7' key={Date.now()} />} />
          <Route path="/bookings-by-region-women-mtd" render={routeProps => <BookingByRegion {...routeProps} reportType='mtd' reportName="Booked By Region - Women - MTD" reportNumber='RPT#DB-0036' genderId='7' key={Date.now()} />} />
          <Route path="/bookings-by-region-men-ytd" render={routeProps => <BookingByRegion {...routeProps} reportType='ytd' reportName="Booked By Region - Men - YTD" reportNumber='RPT#DB-0037' genderId='9' key={Date.now()} />} />
          <Route path="/bookings-by-region-men-mtd" render={routeProps => <BookingByRegion {...routeProps} reportType='mtd' reportName="Booked By Region - Men - MTD" reportNumber='RPT#DB-0038' genderId='9' key={Date.now()} />} />
          <Route path="/bookings-by-region-kids-ytd" render={routeProps => <BookingByRegion {...routeProps} reportType='ytd' reportName="Booked By Region - Kids - YTD" reportNumber='RPT#DB-0039' genderId='13' key={Date.now()} />} />
          <Route path="/bookings-by-region-kids-mtd" render={routeProps => <BookingByRegion {...routeProps} reportType='mtd' reportName="Booked By Region - Kids - MTD" reportNumber='RPT#DB-0040' genderId='13' key={Date.now()} />} />
          <Route path="/bookings-by-region-pets-ytd" render={routeProps => <BookingByRegion {...routeProps} reportType='ytd' reportName="Booked By Region - Pet - YTD" reportNumber='RPT#DB-0041' genderId='12' key={Date.now()} />} />
          <Route path="/bookings-by-region-pets-mtd" render={routeProps => <BookingByRegion {...routeProps} reportType='mtd' reportName="Booked By Region - Pet - MTD" reportNumber='RPT#DB-0042' genderId='12' key={Date.now()} />} />

          <Route path="/bookings-by-fit-style" render={routeProps => <BookingByFitStyle {...routeProps} reportType='' reportName="Booked By FIT/Style" reportNumber='' genderId='-1' key={Date.now()} />} />
          <Route path="/bookings-by-fit-style-ytd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='ytd' reportName="Booked By FIT/Style - YTD" reportNumber='RPT#DB-0043' genderId='-1' key={Date.now()} />} />
          <Route path="/bookings-by-fit-style-mtd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='mtd' reportName="Booked By FIT/Style - MTD" reportNumber='RPT#DB-0044' genderId='-1' key={Date.now()} />} />
          <Route path="/bookings-by-fit-style-women-ytd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='ytd' reportName="Booked By FIT/Style - Women - YTD" reportNumber='RPT#DB-0045' genderId='7' key={Date.now()} />} />
          <Route path="/bookings-by-fit-style-women-mtd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='mtd' reportName="Booked By FIT/Style - Women - MTD" reportNumber='RPT#DB-0046' genderId='7' key={Date.now()} />} />
          <Route path="/bookings-by-fit-style-men-ytd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='ytd' reportName="Booked By FIT/Style - Men - YTD" reportNumber='RPT#DB-0047' genderId='9' key={Date.now()} />} />
          <Route path="/bookings-by-fit-style-men-mtd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='mtd' reportName="Booked By FIT/Style - Men - MTD" reportNumber='RPT#DB-0048' genderId='9' key={Date.now()} />} />
          <Route path="/bookings-by-fit-style-kids-ytd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='ytd' reportName="Booked By FIT/Style - Kids - YTD" reportNumber='RPT#DB-0049' genderId='13' key={Date.now()} />} />
          <Route path="/bookings-by-fit-style-kids-mtd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='mtd' reportName="Booked By FIT/Style - Kids - MTD" reportNumber='RPT#DB-0050' genderId='13' key={Date.now()} />} />
          <Route path="/bookings-by-fit-style-pets-ytd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='ytd' reportName="Booked By FIT/Style - Pet - YTD" reportNumber='RPT#DB-0051' genderId='12' key={Date.now()} />} />
          <Route path="/bookings-by-fit-style-pets-mtd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='mtd' reportName="Booked By FIT/Style - Pet - MTD" reportNumber='RPT#DB-0052' genderId='12' key={Date.now()} />} />
          {/*Fit Style Top Sku */}
          <Route path="/bookings-by-fit-style-top-sku-ytd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='ytd' reportName="Booked By FIT/Style Top Sku - YTD" reportNumber='RPT#DB-0053' genderId='-1' querytype='all fit style top sku' key={Date.now()} />} />
          <Route path="/bookings-by-fit-style-top-sku-mtd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='mtd' reportName="Booked By FIT/Style Top Sku - MTD" reportNumber='RPT#DB-0054' genderId='-1' querytype='all fit style top sku' key={Date.now()} />} />
          <Route path="/bookings-by-fit-style-top-sku-women-ytd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='ytd' reportName="Booked By FIT/Style Top Sku - Women - YTD" reportNumber='RPT#DB-0055' genderId='7' querytype='all fit style top sku' key={Date.now()} />} />
          <Route path="/bookings-by-fit-style-top-sku-women-mtd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='mtd' reportName="Booked By FIT/Style Top Sku - Women - MTD" reportNumber='RPT#DB-0056' genderId='7' querytype='all fit style top sku' key={Date.now()} />} />
          <Route path="/bookings-by-fit-style-top-sku-men-ytd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='ytd' reportName="Booked By FIT/Style Top Sku - Men - YTD" reportNumber='RPT#DB-0057' genderId='9' querytype='all fit style top sku' key={Date.now()} />} />
          <Route path="/bookings-by-fit-style-top-sku-men-mtd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='mtd' reportName="Booked By FIT/Style Top Sku - Men - MTD" reportNumber='RPT#DB-0058' genderId='9' querytype='all fit style top sku' key={Date.now()} />} />
          <Route path="/bookings-by-fit-style-top-sku-kids-ytd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='ytd' reportName="Booked By FIT/Style Top Sku - Kids - YTD" reportNumber='RPT#DB-0059' genderId='13' querytype='all fit style top sku' key={Date.now()} />} />
          <Route path="/bookings-by-fit-style-top-sku-kids-mtd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='mtd' reportName="Booked By FIT/Style Top Sku - Kids - MTD" reportNumber='RPT#DB-0060' genderId='13' querytype='all fit style top sku' key={Date.now()} />} />
          <Route path="/bookings-by-fit-style-top-sku-pets-ytd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='ytd' reportName="Booked By FIT/Style Top Sku - Pet - YTD" reportNumber='RPT#DB-0061' genderId='12' querytype='all fit style top sku' key={Date.now()} />} />
          <Route path="/bookings-by-fit-style-top-sku-pets-mtd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='mtd' reportName="Booked By FIT/Style Top Sku - Pet - MTD" reportNumber='RPT#DB-0062' genderId='12' querytype='all fit style top sku' key={Date.now()} />} />
          {/*Fit Style Top Size */}
          <Route path="/bookings-by-fit-style-top-size-ytd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='ytd' reportName="Booked By FIT/Style Top Size - YTD" reportNumber='RPT#DB-0063' genderId='-1' querytype='all fit style top size' key={Date.now()} />} />
          <Route path="/bookings-by-fit-style-top-size-mtd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='mtd' reportName="Booked By FIT/Style Top Size - MTD" reportNumber='RPT#DB-0064' genderId='-1' querytype='all fit style top size' key={Date.now()} />} />
          <Route path="/bookings-by-fit-style-top-size-women-ytd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='ytd' reportName="Booked By FIT/Style Top Size - Women - YTD" reportNumber='RPT#DB-0065' genderId='7' querytype='all fit style top size' key={Date.now()} />} />
          <Route path="/bookings-by-fit-style-top-size-women-mtd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='mtd' reportName="Booked By FIT/Style Top Size - Women - MTD" reportNumber='RPT#DB-0066' genderId='7' querytype='all fit style top size' key={Date.now()} />} />
          <Route path="/bookings-by-fit-style-top-size-men-ytd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='ytd' reportName="Booked By FIT/Style Top Size - Men - YTD" reportNumber='RPT#DB-0067' genderId='9' querytype='all fit style top size' key={Date.now()} />} />
          <Route path="/bookings-by-fit-style-top-size-men-mtd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='mtd' reportName="Booked By FIT/Style Top Size - Men - MTD" reportNumber='RPT#DB-0068' genderId='9' querytype='all fit style top size' key={Date.now()} />} />
          <Route path="/bookings-by-fit-style-top-size-kids-ytd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='ytd' reportName="Booked By FIT/Style Top Size - Kids - YTD" reportNumber='RPT#DB-0069' genderId='13' querytype='all fit style top size' key={Date.now()} />} />
          <Route path="/bookings-by-fit-style-top-size-kids-mtd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='mtd' reportName="Booked By FIT/Style Top Size - Kids - MTD" reportNumber='RPT#DB-0070' genderId='13' querytype='all fit style top size' key={Date.now()} />} />
          <Route path="/bookings-by-fit-style-top-size-pets-ytd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='ytd' reportName="Booked By FIT/Style Top Size - Pet - YTD" reportNumber='RPT#DB-0071' genderId='12' querytype='all fit style top size' key={Date.now()} />} />
          <Route path="/bookings-by-fit-style-top-size-pets-mtd" render={routeProps => <BookingByFitStyle {...routeProps} reportType='mtd' reportName="Booked By FIT/Style Top Size - Pet - MTD" reportNumber='RPT#DB-0072' genderId='12' querytype='all fit style top size' key={Date.now()} />} />
          {/* Pending Order */}
          <Route path="/pending-by-sale-person" render={routeProps => <PendingBySalePerson {...routeProps} reportType='' reportName="Pending Orders By SalePerson" reportNumber='' genderId='-1' key={Date.now()} />} />
          <Route path="/pending-by-sale-person-ytd" render={routeProps => <PendingBySalePerson {...routeProps} reportType='ytd' reportName="Pending Orders By SalePerson - YTD" reportNumber='RPT#DB-0073' genderId='-1' key={Date.now()} />} />
          <Route path="/pending-by-sale-person-mtd" render={routeProps => <PendingBySalePerson {...routeProps} reportType='mtd' reportName="Pending Orders By SalePerson - MTD" reportNumber='RPT#DB-0074' genderId='-1' key={Date.now()} />} />
          <Route path="/pending-by-sale-person-women-ytd" render={routeProps => <PendingBySalePerson {...routeProps} reportType='ytd' reportName="Pending Orders By SalePerson - Women - YTD" reportNumber='RPT#DB-0075' genderId='7' key={Date.now()} />} />
          <Route path="/pending-by-sale-person-women-mtd" render={routeProps => <PendingBySalePerson {...routeProps} reportType='mtd' reportName="Pending Orders By SalePerson - Women - MTD" reportNumber='RPT#DB-0076' genderId='7' key={Date.now()} />} />
          <Route path="/pending-by-sale-person-men-ytd" render={routeProps => <PendingBySalePerson {...routeProps} reportType='ytd' reportName="Pending Orders By SalePerson - Men - YTD" reportNumber='RPT#DB-0077' genderId='9' key={Date.now()} />} />
          <Route path="/pending-by-sale-person-men-mtd" render={routeProps => <PendingBySalePerson {...routeProps} reportType='mtd' reportName="Pending Orders By SalePerson - Men - MTD" reportNumber='RPT#DB-0078' genderId='9' key={Date.now()} />} />
          <Route path="/pending-by-sale-person-kids-ytd" render={routeProps => <PendingBySalePerson {...routeProps} reportType='ytd' reportName="Pending Orders By SalePerson - Kids - YTD" reportNumber='RPT#DB-0079' genderId='13' key={Date.now()} />} />
          <Route path="/pending-by-sale-person-kids-mtd" render={routeProps => <PendingBySalePerson {...routeProps} reportType='mtd' reportName="Pending Orders By SalePerson - Kids - MTD" reportNumber='RPT#DB-0080' genderId='13' key={Date.now()} />} />
          <Route path="/pending-by-sale-person-pets-ytd" render={routeProps => <PendingBySalePerson {...routeProps} reportType='ytd' reportName="Pending Orders By SalePerson - Pet - YTD" reportNumber='RPT#DB-0081' genderId='12' key={Date.now()} />} />
          <Route path="/pending-by-sale-person-pets-mtd" render={routeProps => <PendingBySalePerson {...routeProps} reportType='mtd' reportName="Pending Orders By SalePerson - Pet - MTD" reportNumber='RPT#DB-0082' genderId='12' key={Date.now()} />} />

          <Route path='/pending-by-gender' render={routeProps => <PendingByGender {...routeProps} reportType='' reportName="Pending Orders By Gender" reportNumber='RPT#DB-0083' genderId='-1' key={Date.now()} />} />
          <Route path='/pending-by-gender-ytd' render={routeProps => <PendingByGender {...routeProps} reportType='ytd' reportName="Pending Orders By Gender - YTD" reportNumber='RPT#DB-0084' genderId='-1' key={Date.now()} />} />
          <Route path='/pending-by-gender-mtd' render={routeProps => <PendingByGender {...routeProps} reportType='mtd' reportName="Pending Orders By Gender - MTD" reportNumber='RPT#DB-0085' genderId='-1' key={Date.now()} />} />

          <Route path="/pending-by-customer" render={routeProps => <PendingByCustomer {...routeProps} reportType='' reportName="Pending Orders By Customer Top 30" reportNumber='' genderId='-1' key={Date.now()} />} />
          <Route path="/pending-by-customer-ytd" render={routeProps => <PendingByCustomer {...routeProps} reportType='ytd' reportName="Pending Orders By Customer Top 30 - YTD" reportNumber='RPT#DB-0086' genderId='-1' key={Date.now()} />} />
          <Route path="/pending-by-customer-mtd" render={routeProps => <PendingByCustomer {...routeProps} reportType='mtd' reportName="Pending Orders By Customer Top 30 - MTD" reportNumber='RPT#DB-0087' genderId='-1' key={Date.now()} />} />
          <Route path="/pending-by-customer-women-ytd" render={routeProps => <PendingByCustomer {...routeProps} reportType='ytd' reportName="Pending Orders By Customer Top 30 - Women - YTD" reportNumber='RPT#DB-0088' genderId='7' key={Date.now()} />} />
          <Route path="/pending-by-customer-women-mtd" render={routeProps => <PendingByCustomer {...routeProps} reportType='mtd' reportName="Pending Orders By Customer Top 30 - Women - MTD" reportNumber='RPT#DB-0089' genderId='7' key={Date.now()} />} />
          <Route path="/pending-by-customer-men-ytd" render={routeProps => <PendingByCustomer {...routeProps} reportType='ytd' reportName="Pending Orders By Customer Top 30 - Men - YTD" reportNumber='RPT#DB-0090' genderId='9' key={Date.now()} />} />
          <Route path="/pending-by-customer-men-mtd" render={routeProps => <PendingByCustomer {...routeProps} reportType='mtd' reportName="Pending Orders By Customer Top 30 - Men - MTD" reportNumber='RPT#DB-0091' genderId='9' key={Date.now()} />} />
          <Route path="/pending-by-customer-kids-ytd" render={routeProps => <PendingByCustomer {...routeProps} reportType='ytd' reportName="Pending Orders By Customer Top 30 - Kids - YTD" reportNumber='RPT#DB-0092' genderId='13' key={Date.now()} />} />
          <Route path="/pending-by-customer-kids-mtd" render={routeProps => <PendingByCustomer {...routeProps} reportType='mtd' reportName="Pending Orders By Customer Top 30 - Kids - MTD" reportNumber='RPT#DB-0093' genderId='13' key={Date.now()} />} />
          <Route path="/pending-by-customer-pets-ytd" render={routeProps => <PendingByCustomer {...routeProps} reportType='ytd' reportName="Pending Orders By Customer Top 30 - Pet - YTD" reportNumber='RPT#DB-0094' genderId='12' key={Date.now()} />} />
          <Route path="/pending-by-customer-pets-mtd" render={routeProps => <PendingByCustomer {...routeProps} reportType='mtd' reportName="Pending Orders By Customer Top 30 - Pet - MTD" reportNumber='RPT#DB-0095' genderId='12' key={Date.now()} />} />

          <Route path="/pending-by-region" render={routeProps => <PendingByRegion {...routeProps} reportType='' reportName="Pending Orders By Region" reportNumber='' genderId='-1' key={Date.now()} />} />
          <Route path="/pending-by-region-ytd" render={routeProps => <PendingByRegion {...routeProps} reportType='ytd' reportName="Pending Orders By Region - YTD" reportNumber='RPT#DB-0248' genderId='-1' key={Date.now()} />} />
          <Route path="/pending-by-region-mtd" render={routeProps => <PendingByRegion {...routeProps} reportType='mtd' reportName="Pending Orders By Region - MTD" reportNumber='RPT#DB-0096' genderId='-1' key={Date.now()} />} />
          <Route path="/pending-by-region-women-ytd" render={routeProps => <PendingByRegion {...routeProps} reportType='ytd' reportName="Pending Orders By Region - Women - YTD" reportNumber='RPT#DB-0097' genderId='7' key={Date.now()} />} />
          <Route path="/pending-by-region-women-mtd" render={routeProps => <PendingByRegion {...routeProps} reportType='mtd' reportName="Pending Orders By Region - Women - MTD" reportNumber='RPT#DB-0098' genderId='7' key={Date.now()} />} />
          <Route path="/pending-by-region-men-ytd" render={routeProps => <PendingByRegion {...routeProps} reportType='ytd' reportName="Pending Orders By Region - Men - YTD" reportNumber='RPT#DB-0099' genderId='9' key={Date.now()} />} />
          <Route path="/pending-by-region-men-mtd" render={routeProps => <PendingByRegion {...routeProps} reportType='mtd' reportName="Pending Orders By Region - Men - MTD" reportNumber='RPT#DB-0100' genderId='9' key={Date.now()} />} />
          <Route path="/pending-by-region-kids-ytd" render={routeProps => <PendingByRegion {...routeProps} reportType='ytd' reportName="Pending Orders By Region - Kids - YTD" reportNumber='RPT#DB-0101' genderId='13' key={Date.now()} />} />
          <Route path="/pending-by-region-kids-mtd" render={routeProps => <PendingByRegion {...routeProps} reportType='mtd' reportName="Pending Orders By Region - Kids - MTD" reportNumber='RPT#DB-0102' genderId='13' key={Date.now()} />} />
          <Route path="/pending-by-region-pets-ytd" render={routeProps => <PendingByRegion {...routeProps} reportType='ytd' reportName="Pending Orders By Region - Pet - YTD" reportNumber='RPT#DB-0103' genderId='12' key={Date.now()} />} />
          <Route path="/pending-by-region-pets-mtd" render={routeProps => <PendingByRegion {...routeProps} reportType='mtd' reportName="Pending Orders By Region - Pet - MTD" reportNumber='RPT#DB-0104' genderId='12' key={Date.now()} />} />

          <Route path="/pending-by-fit-style" render={routeProps => <PendingByFitStyle {...routeProps} reportType='' reportName="Pending Orders By Fit/Style" reportNumber='' genderId='-1' key={Date.now()} />} />
          <Route path="/pending-by-fit-style-ytd" render={routeProps => <PendingByFitStyle {...routeProps} reportType='ytd' reportName="Pending Orders By Fit/Style - YTD" reportNumber='RPT#DB-0105' genderId='-1' key={Date.now()} />} />
          <Route path="/pending-by-fit-style-mtd" render={routeProps => <PendingByFitStyle {...routeProps} reportType='mtd' reportName="Pending Orders By Fit/Style - MTD" reportNumber='RPT#DB-0106' genderId='-1' key={Date.now()} />} />
          <Route path="/pending-by-fit-style-women-ytd" render={routeProps => <PendingByFitStyle {...routeProps} reportType='ytd' reportName="Pending Orders By Fit/Style - Women - YTD" reportNumber='RPT#DB-0107' genderId='7' key={Date.now()} />} />
          <Route path="/pending-by-fit-style-women-mtd" render={routeProps => <PendingByFitStyle {...routeProps} reportType='mtd' reportName="Pending Orders By Fit/Style - Women - MTD" reportNumber='RPT#DB-0108' genderId='7' key={Date.now()} />} />
          <Route path="/pending-by-fit-style-men-ytd" render={routeProps => <PendingByFitStyle {...routeProps} reportType='ytd' reportName="Pending Orders By Fit/Style - Men - YTD" reportNumber='RPT#DB-0109' genderId='9' key={Date.now()} />} />
          <Route path="/pending-by-fit-style-men-mtd" render={routeProps => <PendingByFitStyle {...routeProps} reportType='mtd' reportName="Pending Orders By Fit/Style - Men - MTD" reportNumber='RPT#DB-0110' genderId='9' key={Date.now()} />} />
          <Route path="/pending-by-fit-style-kids-ytd" render={routeProps => <PendingByFitStyle {...routeProps} reportType='ytd' reportName="Pending Orders By Fit/Style - Kids - YTD" reportNumber='RPT#DB-0111' genderId='13' key={Date.now()} />} />
          <Route path="/pending-by-fit-style-kids-mtd" render={routeProps => <PendingByFitStyle {...routeProps} reportType='mtd' reportName="Pending Orders By Fit/Style - Kids - MTD" reportNumber='RPT#DB-0112' genderId='13' key={Date.now()} />} />
          <Route path="/pending-by-fit-style-pets-ytd" render={routeProps => <PendingByFitStyle {...routeProps} reportType='ytd' reportName="Pending Orders By Fit/Style - Pet - YTD" reportNumber='RPT#DB-0113' genderId='12' key={Date.now()} />} />
          <Route path="/pending-by-fit-style-pets-mtd" render={routeProps => <PendingByFitStyle {...routeProps} reportType='mtd' reportName="Pending Orders By Fit/Style - Pet - MTD" reportNumber='RPT#DB-0114' genderId='12' key={Date.now()} />} />

          {/*Pending Pod */}
          <Route path="/pending-pod-by-sale-person" render={routeProps => <PendingPodBySalePerson {...routeProps} reportType='' reportName="Pending POD By SalePerson" reportNumber='' genderId='-1' querytype='all salesperson' key={Date.now()} />} />
          <Route path="/pending-pod-by-sale-person-ytd" render={routeProps => <PendingPodBySalePerson {...routeProps} reportType='ytd' reportName="Pending POD By SalePerson - YTD" reportNumber='RPT#DB-0115' genderId='-1' querytype='all salesperson' key={Date.now()} />} />
          <Route path="/pending-pod-by-sale-person-mtd" render={routeProps => <PendingPodBySalePerson {...routeProps} reportType='mtd' reportName="Pending POD By SalePerson - MTD" reportNumber='RPT#DB-0116' genderId='-1' querytype='all salesperson' key={Date.now()} />} />
          <Route path="/pending-pod-by-sale-person-women-ytd" render={routeProps => <PendingPodBySalePerson {...routeProps} reportType='ytd' reportName="Pending POD By SalePerson - Women - YTD" reportNumber='RPT#DB-0117' genderId='7' querytype='all salesperson' key={Date.now()} />} />
          <Route path="/pending-pod-by-sale-person-women-mtd" render={routeProps => <PendingPodBySalePerson {...routeProps} reportType='mtd' reportName="Pending POD By SalePerson - Women - MTD" reportNumber='RPT#DB-0118' genderId='7' querytype='all salesperson' key={Date.now()} />} />
          <Route path="/pending-pod-by-sale-person-men-ytd" render={routeProps => <PendingPodBySalePerson {...routeProps} reportType='ytd' reportName="Pending POD By SalePerson - Men - YTD" reportNumber='RPT#DB-0119' genderId='9' querytype='all salesperson' key={Date.now()} />} />
          <Route path="/pending-pod-by-sale-person-men-mtd" render={routeProps => <PendingPodBySalePerson {...routeProps} reportType='mtd' reportName="Pending POD By SalePerson - Men - MTD" reportNumber='RPT#DB-0120' genderId='9' querytype='all salesperson' key={Date.now()} />} />
          <Route path="/pending-pod-by-sale-person-kids-ytd" render={routeProps => <PendingPodBySalePerson {...routeProps} reportType='ytd' reportName="Pending POD By SalePerson - Kids - YTD" reportNumber='RPT#DB-0121' genderId='13' querytype='all salesperson' key={Date.now()} />} />
          <Route path="/pending-pod-by-sale-person-kids-mtd" render={routeProps => <PendingPodBySalePerson {...routeProps} reportType='mtd' reportName="Pending POD By SalePerson - Kids - MTD" reportNumber='RPT#DB-0122' genderId='13' querytype='all salesperson' key={Date.now()} />} />
          <Route path="/pending-pod-by-sale-person-pets-ytd" render={routeProps => <PendingPodBySalePerson {...routeProps} reportType='ytd' reportName="Pending POD By SalePerson - Pet - YTD" reportNumber='RPT#DB-0123' genderId='12' querytype='all salesperson' key={Date.now()} />} />
          <Route path="/pending-pod-by-sale-person-pets-mtd" render={routeProps => <PendingPodBySalePerson {...routeProps} reportType='mtd' reportName="Pending POD By SalePerson - Pet - MTD" reportNumber='RPT#DB-0124' genderId='12' querytype='all salesperson' key={Date.now()} />} />

          <Route path="/pending-pod-by-region" render={routeProps => <PendingPodByRegion {...routeProps} reportType='' reportName="Pending POD By Region" reportNumber='' genderId='-1' querytype='all region' key={Date.now()} />} />
          <Route path="/pending-pod-by-region-ytd" render={routeProps => <PendingPodByRegion {...routeProps} reportType='ytd' reportName="Pending POD By Region - YTD" reportNumber='RPT#DB-0125' genderId='-1' querytype='all region' key={Date.now()} />} />
          <Route path="/pending-pod-by-region-mtd" render={routeProps => <PendingPodByRegion {...routeProps} reportType='mtd' reportName="Pending POD By Region - MTD" reportNumber='RPT#DB-0126' genderId='-1' querytype='all region' key={Date.now()} />} />
          <Route path="/pending-pod-by-region-women-ytd" render={routeProps => <PendingPodByRegion {...routeProps} reportType='ytd' reportName="Pending POD By Region - Women - YTD" reportNumber='RPT#DB-0127' genderId='7' querytype='all region' key={Date.now()} />} />
          <Route path="/pending-pod-by-region-women-mtd" render={routeProps => <PendingPodByRegion {...routeProps} reportType='mtd' reportName="Pending POD By Region - Women - MTD" reportNumber='RPT#DB-0128' genderId='7' querytype='all region' key={Date.now()} />} />
          <Route path="/pending-pod-by-region-men-ytd" render={routeProps => <PendingPodByRegion {...routeProps} reportType='ytd' reportName="Pending POD By Region - Men - YTD" reportNumber='RPT#DB-0129' genderId='9' querytype='all region' key={Date.now()} />} />
          <Route path="/pending-pod-by-region-men-mtd" render={routeProps => <PendingPodByRegion {...routeProps} reportType='mtd' reportName="Pending POD By Region - Men - MTD" reportNumber='RPT#DB-0130' genderId='9' querytype='all region' key={Date.now()} />} />
          <Route path="/pending-pod-by-region-kids-ytd" render={routeProps => <PendingPodByRegion {...routeProps} reportType='ytd' reportName="Pending POD By Region - Kids - YTD" reportNumber='RPT#DB-0131' genderId='13' querytype='all region' key={Date.now()} />} />
          <Route path="/pending-pod-by-region-kids-mtd" render={routeProps => <PendingPodByRegion {...routeProps} reportType='mtd' reportName="Pending POD By Region - Kids - MTD" reportNumber='RPT#DB-0132' genderId='13' querytype='all region' key={Date.now()} />} />
          <Route path="/pending-pod-by-region-pets-ytd" render={routeProps => <PendingPodByRegion {...routeProps} reportType='ytd' reportName="Pending POD By Region - Pet - YTD" reportNumber='RPT#DB-0133' genderId='12' querytype='all region' key={Date.now()} />} />
          <Route path="/pending-pod-by-region-pets-mtd" render={routeProps => <PendingPodByRegion {...routeProps} reportType='mtd' reportName="Pending POD By Region - Pet - MTD" reportNumber='RPT#DB-0134' genderId='12' querytype='all region' key={Date.now()} />} />

          {/*Shipped */}
          <Route path="/shipped-by-sale-person" render={routeProps => <ShippedBySalePerson {...routeProps} reportType='' reportName="Shipped By SalePerson" reportNumber='' genderId='-1' key={Date.now()} />} />
          <Route path="/shipped-by-sale-person-ytd" render={routeProps => <ShippedBySalePerson {...routeProps} reportType='ytd' reportName="Shipped By SalePerson - YTD" reportNumber='RPT#DB-0135' genderId='-1' key={Date.now()} />} />
          <Route path="/shipped-by-sale-person-mtd" render={routeProps => <ShippedBySalePerson {...routeProps} reportType='mtd' reportName="Shipped By SalePerson - MTD" reportNumber='RPT#DB-0136' genderId='-1' key={Date.now()} />} />
          <Route path="/shipped-by-sale-person-women-ytd" render={routeProps => <ShippedBySalePerson {...routeProps} reportType='ytd' reportName="Shipped By SalePerson - Women - YTD" reportNumber='RPT#DB-0137' genderId='7' key={Date.now()} />} />
          <Route path="/shipped-by-sale-person-women-mtd" render={routeProps => <ShippedBySalePerson {...routeProps} reportType='mtd' reportName="Shipped By SalePerson - Women - MTD" reportNumber='RPT#DB-0138' genderId='7' key={Date.now()} />} />
          <Route path="/shipped-by-sale-person-men-ytd" render={routeProps => <ShippedBySalePerson {...routeProps} reportType='ytd' reportName="Shipped By SalePerson - Men - YTD" reportNumber='RPT#DB-0139' genderId='9' key={Date.now()} />} />
          <Route path="/shipped-by-sale-person-men-mtd" render={routeProps => <ShippedBySalePerson {...routeProps} reportType='mtd' reportName="Shipped By SalePerson - Men - MTD" reportNumber='RPT#DB-0140' genderId='9' key={Date.now()} />} />
          <Route path="/shipped-by-sale-person-kids-ytd" render={routeProps => <ShippedBySalePerson {...routeProps} reportType='ytd' reportName="Shipped By SalePerson - Kids - YTD" reportNumber='RPT#DB-0141' genderId='13' key={Date.now()} />} />
          <Route path="/shipped-by-sale-person-kids-mtd" render={routeProps => <ShippedBySalePerson {...routeProps} reportType='mtd' reportName="Shipped By SalePerson - Kids - MTD" reportNumber='RPT#DB-0142' genderId='13' key={Date.now()} />} />
          <Route path="/shipped-by-sale-person-pets-ytd" render={routeProps => <ShippedBySalePerson {...routeProps} reportType='ytd' reportName="Shipped By SalePerson - Pet - YTD" reportNumber='RPT#DB-0143' genderId='12' key={Date.now()} />} />
          <Route path="/shipped-by-sale-person-pets-mtd" render={routeProps => <ShippedBySalePerson {...routeProps} reportType='mtd' reportName="Shipped By SalePerson - Pet - MTD" reportNumber='RPT#DB-0144' genderId='12' key={Date.now()} />} />

          <Route path='/shipped-by-gender' render={routeProps => <ShippedByGender {...routeProps} reportType='' reportName="Shipped By Gender" reportNumber='' genderId='-1' key={Date.now()} />} />
          <Route path='/shipped-by-gender-ytd' render={routeProps => <ShippedByGender {...routeProps} reportType='ytd' reportName="Shipped By Gender - YTD" reportNumber='RPT#DB-0145' genderId='-1' key={Date.now()} />} />
          <Route path='/shipped-by-gender-mtd' render={routeProps => <ShippedByGender {...routeProps} reportType='mtd' reportName="Shipped By Gender - MTD" reportNumber='RPT#DB-0146' genderId='-1' key={Date.now()} />} />

          <Route path="/shipped-by-customer" render={routeProps => <ShippedByCustomer {...routeProps} reportType='' reportName="Shipped By Customer Top 30" reportNumber='' genderId='-1' key={Date.now()} />} />
          <Route path="/shipped-by-customer-ytd" render={routeProps => <ShippedByCustomer {...routeProps} reportType='ytd' reportName="Shipped By Customer Top 30 - YTD" reportNumber='RPT#DB-0147' genderId='-1' key={Date.now()} />} />
          <Route path="/shipped-by-customer-mtd" render={routeProps => <ShippedByCustomer {...routeProps} reportType='mtd' reportName="Shipped By Customer Top 30 - MTD" reportNumber='RPT#DB-0147' genderId='-1' key={Date.now()} />} />
          <Route path="/shipped-by-customer-women-ytd" render={routeProps => <ShippedByCustomer {...routeProps} reportType='ytd' reportName="Shipped By Customer Top 30 - Women - YTD" reportNumber='RPT#DB-0148' genderId='7' key={Date.now()} />} />
          <Route path="/shipped-by-customer-women-mtd" render={routeProps => <ShippedByCustomer {...routeProps} reportType='mtd' reportName="Shipped By Customer Top 30 - Women - MTD" reportNumber='RPT#DB-0149' genderId='7' key={Date.now()} />} />
          <Route path="/shipped-by-customer-men-ytd" render={routeProps => <ShippedByCustomer {...routeProps} reportType='ytd' reportName="Shipped By Customer Top 30 - Men - YTD" reportNumber='RPT#DB-0150' genderId='9' key={Date.now()} />} />
          <Route path="/shipped-by-customer-men-mtd" render={routeProps => <ShippedByCustomer {...routeProps} reportType='mtd' reportName="Shipped By Customer Top 30 - Men - MTD" reportNumber='RPT#DB-0151' genderId='9' key={Date.now()} />} />
          <Route path="/shipped-by-customer-kids-ytd" render={routeProps => <ShippedByCustomer {...routeProps} reportType='ytd' reportName="Shipped By Customer Top 30 - Kids - YTD" reportNumber='RPT#DB-0152' genderId='13' key={Date.now()} />} />
          <Route path="/shipped-by-customer-kids-mtd" render={routeProps => <ShippedByCustomer {...routeProps} reportType='mtd' reportName="Shipped By Customer Top 30 - Kids - MTD" reportNumber='RPT#DB-0153' genderId='13' key={Date.now()} />} />
          <Route path="/shipped-by-customer-pets-ytd" render={routeProps => <ShippedByCustomer {...routeProps} reportType='ytd' reportName="Shipped By Customer Top 30 - Pet - YTD" reportNumber='RPT#DB-0154' genderId='12' key={Date.now()} />} />
          <Route path="/shipped-by-customer-pets-mtd" render={routeProps => <ShippedByCustomer {...routeProps} reportType='mtd' reportName="Shipped By Customer Top 30 - Pet - MTD" reportNumber='RPT#DB-0155' genderId='12' key={Date.now()} />} />

          <Route path="/shipped-by-region" render={routeProps => <ShippedByRegion {...routeProps} reportType='' reportName="Shipped By Region" reportNumber='' genderId='-1' key={Date.now()} />} />
          <Route path="/shipped-by-region-ytd" render={routeProps => <ShippedByRegion {...routeProps} reportType='ytd' reportName="Shipped By Region - YTD" reportNumber='RPT#DB-0156' genderId='-1' key={Date.now()} />} />
          <Route path="/shipped-by-region-mtd" render={routeProps => <ShippedByRegion {...routeProps} reportType='mtd' reportName="Shipped By Region - MTD" reportNumber='RPT#DB-0157' genderId='-1' key={Date.now()} />} />
          <Route path="/shipped-by-region-women-ytd" render={routeProps => <ShippedByRegion {...routeProps} reportType='ytd' reportName="Shipped By Region - Women - YTD" reportNumber='RPT#DB-0158' genderId='7' key={Date.now()} />} />
          <Route path="/shipped-by-region-women-mtd" render={routeProps => <ShippedByRegion {...routeProps} reportType='mtd' reportName="Shipped By Region - Women - MTD" reportNumber='RPT#DB-0159' genderId='7' key={Date.now()} />} />
          <Route path="/shipped-by-region-men-ytd" render={routeProps => <ShippedByRegion {...routeProps} reportType='ytd' reportName="Shipped By Region - Men - YTD" reportNumber='RPT#DB-0160' genderId='9' key={Date.now()} />} />
          <Route path="/shipped-by-region-men-mtd" render={routeProps => <ShippedByRegion {...routeProps} reportType='mtd' reportName="Shipped By Region - Men - MTD" reportNumber='RPT#DB-0161' genderId='9' key={Date.now()} />} />
          <Route path="/shipped-by-region-kids-ytd" render={routeProps => <ShippedByRegion {...routeProps} reportType='ytd' reportName="Shipped By Region - Kids - YTD" reportNumber='RPT#DB-0162' genderId='13' key={Date.now()} />} />
          <Route path="/shipped-by-region-kids-mtd" render={routeProps => <ShippedByRegion {...routeProps} reportType='mtd' reportName="Shipped By Region - Kids - MTD" reportNumber='RPT#DB-0163' genderId='13' key={Date.now()} />} />
          <Route path="/shipped-by-region-pets-ytd" render={routeProps => <ShippedByRegion {...routeProps} reportType='ytd' reportName="Shipped By Region - Pet - YTD" reportNumber='RPT#DB-0164' genderId='12' key={Date.now()} />} />
          <Route path="/shipped-by-region-pets-mtd" render={routeProps => <ShippedByRegion {...routeProps} reportType='mtd' reportName="Shipped By Region - Pet - MTD" reportNumber='RPT#DB-0165' genderId='12' key={Date.now()} />} />

          <Route path="/shipped-by-region-new" render={routeProps => <ShippedByRegionNew {...routeProps} reportType='' reportName="Shipped By Region New" reportNumber='' genderId='-1' key={Date.now()} />} />
          <Route path="/shipped-by-region-new-ytd" render={routeProps => <ShippedByRegionNew {...routeProps} reportType='ytd' reportName="Shipped By Region New - YTD" reportNumber='RPT#DB-0156' genderId='-1' key={Date.now()} />} />
          <Route path="/shipped-by-region-new-mtd" render={routeProps => <ShippedByRegionNew {...routeProps} reportType='mtd' reportName="Shipped By Region New - MTD" reportNumber='RPT#DB-0157' genderId='-1' key={Date.now()} />} />
          <Route path="/shipped-by-region-new-women-ytd" render={routeProps => <ShippedByRegionNew {...routeProps} reportType='ytd' reportName="Shipped By Region New - Women - YTD" reportNumber='RPT#DB-0158' genderId='7' key={Date.now()} />} />
          <Route path="/shipped-by-region-new-women-mtd" render={routeProps => <ShippedByRegionNew {...routeProps} reportType='mtd' reportName="Shipped By Region New - Women - MTD" reportNumber='RPT#DB-0159' genderId='7' key={Date.now()} />} />
          <Route path="/shipped-by-region-new-men-ytd" render={routeProps => <ShippedByRegionNew {...routeProps} reportType='ytd' reportName="Shipped By Region New - Men - YTD" reportNumber='RPT#DB-0160' genderId='9' key={Date.now()} />} />
          <Route path="/shipped-by-region-new-men-mtd" render={routeProps => <ShippedByRegionNew {...routeProps} reportType='mtd' reportName="Shipped By Region New - Men - MTD" reportNumber='RPT#DB-0161' genderId='9' key={Date.now()} />} />
          <Route path="/shipped-by-region-new-kids-ytd" render={routeProps => <ShippedByRegionNew {...routeProps} reportType='ytd' reportName="Shipped By Region New - Kids - YTD" reportNumber='RPT#DB-0162' genderId='13' key={Date.now()} />} />
          <Route path="/shipped-by-region-new-kids-mtd" render={routeProps => <ShippedByRegionNew {...routeProps} reportType='mtd' reportName="Shipped By Region New - Kids - MTD" reportNumber='RPT#DB-0163' genderId='13' key={Date.now()} />} />
          <Route path="/shipped-by-region-new-pets-ytd" render={routeProps => <ShippedByRegionNew {...routeProps} reportType='ytd' reportName="Shipped By Region New - Pet - YTD" reportNumber='RPT#DB-0164' genderId='12' key={Date.now()} />} />
          <Route path="/shipped-by-region-new-pets-mtd" render={routeProps => <ShippedByRegionNew {...routeProps} reportType='mtd' reportName="Shipped By Region New - Pet - MTD" reportNumber='RPT#DB-0165' genderId='12' key={Date.now()} />} />



          <Route path="/shipped-by-fit-style" render={routeProps => <ShippedByFitStyle {...routeProps} reportType='' reportName="Shipped By Fit/Style" reportNumber='' genderId='-1' key={Date.now()} />} />
          <Route path="/shipped-by-fit-style-ytd" render={routeProps => <ShippedByFitStyle {...routeProps} reportType='ytd' reportName="Shipped By Fit/Style - YTD" reportNumber='RPT#DB-00166' genderId='-1' key={Date.now()} />} />
          <Route path="/shipped-by-fit-style-mtd" render={routeProps => <ShippedByFitStyle {...routeProps} reportType='mtd' reportName="Shipped By Fit/Style - MTD" reportNumber='RPT#DB-0167' genderId='-1' key={Date.now()} />} />
          <Route path="/shipped-by-fit-style-women-ytd" render={routeProps => <ShippedByFitStyle {...routeProps} reportType='ytd' reportName="Shipped By Fit/Style - Women - YTD" reportNumber='RPT#DB-0168' genderId='7' key={Date.now()} />} />
          <Route path="/shipped-by-fit-style-women-mtd" render={routeProps => <ShippedByFitStyle {...routeProps} reportType='mtd' reportName="Shipped By Fit/Style - Women - MTD" reportNumber='RPT#DB-0169' genderId='7' key={Date.now()} />} />
          <Route path="/shipped-by-fit-style-men-ytd" render={routeProps => <ShippedByFitStyle {...routeProps} reportType='ytd' reportName="Shipped By Fit/Style - Men - YTD" reportNumber='RPT#DB-00170' genderId='9' key={Date.now()} />} />
          <Route path="/shipped-by-fit-style-men-mtd" render={routeProps => <ShippedByFitStyle {...routeProps} reportType='mtd' reportName="Shipped By Fit/Style - Men - MTD" reportNumber='RPT#DB-0171' genderId='9' key={Date.now()} />} />
          <Route path="/shipped-by-fit-style-kids-ytd" render={routeProps => <ShippedByFitStyle {...routeProps} reportType='ytd' reportName="Shipped By Fit/Style - Kids - YTD" reportNumber='RPT#DB-0172' genderId='13' key={Date.now()} />} />
          <Route path="/shipped-by-fit-style-kids-mtd" render={routeProps => <ShippedByFitStyle {...routeProps} reportType='mtd' reportName="Shipped By Fit/Style - Kids - MTD" reportNumber='RPT#DB-0173' genderId='13' key={Date.now()} />} />
          <Route path="/shipped-by-fit-style-pets-ytd" render={routeProps => <ShippedByFitStyle {...routeProps} reportType='ytd' reportName="Shipped By Fit/Style - Pet - YTD" reportNumber='RPT#DB-0174' genderId='12' key={Date.now()} />} />
          <Route path="/shipped-by-fit-style-pets-mtd" render={routeProps => <ShippedByFitStyle {...routeProps} reportType='mtd' reportName="Shipped By Fit/Style - Pet - MTD" reportNumber='RPT#DB-0175' genderId='12' key={Date.now()} />} />
          {/*Return*/}
          <Route path="/return-by-sale-person" render={routeProps => <ReturnBySalePerson {...routeProps} reportType='' reportName="Returned By SalePerson" reportNumber='' genderId='-1' key={Date.now()} />} />
          <Route path="/return-by-sale-person-ytd" render={routeProps => <ReturnBySalePerson {...routeProps} reportType='ytd' reportName="Returned By SalePerson - YTD" reportNumber='RPT#DB-0176' genderId='-1' key={Date.now()} />} />
          <Route path="/return-by-sale-person-mtd" render={routeProps => <ReturnBySalePerson {...routeProps} reportType='mtd' reportName="Returned By SalePerson - MTD" reportNumber='RPT#DB-0177' genderId='-1' key={Date.now()} />} />
          <Route path="/return-by-sale-person-women-ytd" render={routeProps => <ReturnBySalePerson {...routeProps} reportType='ytd' reportName="Returned By SalePerson - Women - YTD" reportNumber='RPT#DB-0178' genderId='7' key={Date.now()} />} />
          <Route path="/return-by-sale-person-women-mtd" render={routeProps => <ReturnBySalePerson {...routeProps} reportType='mtd' reportName="Returned By SalePerson - Women - MTD" reportNumber='RPT#DB-0179' genderId='7' key={Date.now()} />} />
          <Route path="/return-by-sale-person-men-ytd" render={routeProps => <ReturnBySalePerson {...routeProps} reportType='ytd' reportName="Returned By SalePerson - Men - YTD" reportNumber='RPT#DB-0180' genderId='9' key={Date.now()} />} />
          <Route path="/return-by-sale-person-men-mtd" render={routeProps => <ReturnBySalePerson {...routeProps} reportType='mtd' reportName="Returned By SalePerson - Men - MTD" reportNumber='RPT#DB-0181' genderId='9' key={Date.now()} />} />
          <Route path="/return-by-sale-person-kids-ytd" render={routeProps => <ReturnBySalePerson {...routeProps} reportType='ytd' reportName="Returned By SalePerson - Kids - YTD" reportNumber='RPT#DB-0182' genderId='13' key={Date.now()} />} />
          <Route path="/return-by-sale-person-kids-mtd" render={routeProps => <ReturnBySalePerson {...routeProps} reportType='mtd' reportName="Returned By SalePerson - Kids - MTD" reportNumber='RPT#DB-0183' genderId='13' key={Date.now()} />} />
          <Route path="/return-by-sale-person-pets-ytd" render={routeProps => <ReturnBySalePerson {...routeProps} reportType='ytd' reportName="Returned By SalePerson - Pet - YTD" reportNumber='RPT#DB-0184' genderId='12' key={Date.now()} />} />
          <Route path="/return-by-sale-person-pets-mtd" render={routeProps => <ReturnBySalePerson {...routeProps} reportType='mtd' reportName="Returned By SalePerson - Pet - MTD" reportNumber='RPT#DB-0185' genderId='12' key={Date.now()} />} />

          <Route path='/return-by-gender' render={routeProps => <ReturnByGender {...routeProps} reportType='' reportName="Returned By Gender" reportNumber='' genderId='-1' key={Date.now()} />} />
          <Route path='/return-by-gender-ytd' render={routeProps => <ReturnByGender {...routeProps} reportType='ytd' reportName="Returned By Gender - YTD" reportNumber='RPT#DB-0186' genderId='-1' key={Date.now()} />} />
          <Route path='/return-by-gender-mtd' render={routeProps => <ReturnByGender {...routeProps} reportType='mtd' reportName="Returned By Gender - MTD" reportNumber='RPT#DB-0187' genderId='-1' key={Date.now()} />} />

          <Route path="/return-by-customer" render={routeProps => <ReturnByCustomer {...routeProps} reportType='' reportName="Returned By Customer" reportNumber='' genderId='-1' key={Date.now()} />} />
          <Route path="/return-by-customer-ytd" render={routeProps => <ReturnByCustomer {...routeProps} reportType='ytd' reportName="Returned By Customer Top 30 - YTD" reportNumber='RPT#DB-0189' genderId='-1' key={Date.now()} />} />
          <Route path="/return-by-customer-mtd" render={routeProps => <ReturnByCustomer {...routeProps} reportType='mtd' reportName="Returned By Customer Top 30 - MTD" reportNumber='RPT#DB-0190' genderId='-1' key={Date.now()} />} />
          <Route path="/return-by-customer-women-ytd" render={routeProps => <ReturnByCustomer {...routeProps} reportType='ytd' reportName="Returned By Customer Top 30 - Women - YTD" reportNumber='RPT#DB-0191' genderId='7' key={Date.now()} />} />
          <Route path="/return-by-customer-women-mtd" render={routeProps => <ReturnByCustomer {...routeProps} reportType='mtd' reportName="Returned By Customer Top 30 - Women - MTD" reportNumber='RPT#DB-0192' genderId='7' key={Date.now()} />} />
          <Route path="/return-by-customer-men-ytd" render={routeProps => <ReturnByCustomer {...routeProps} reportType='ytd' reportName="Returned By Customer Top 30 - Men - YTD" reportNumber='RPT#DB-0193' genderId='9' key={Date.now()} />} />
          <Route path="/return-by-customer-men-mtd" render={routeProps => <ReturnByCustomer {...routeProps} reportType='mtd' reportName="Returned By Customer Top 30 - Men - MTD" reportNumber='RPT#DB-0194' genderId='9' key={Date.now()} />} />
          <Route path="/return-by-customer-kids-ytd" render={routeProps => <ReturnByCustomer {...routeProps} reportType='ytd' reportName="Returned By Customer Top 30 - Kids - YTD" reportNumber='RPT#DB-0195' genderId='13' key={Date.now()} />} />
          <Route path="/return-by-customer-kids-mtd" render={routeProps => <ReturnByCustomer {...routeProps} reportType='mtd' reportName="Returned By Customer Top 30 - Kids - MTD" reportNumber='RPT#DB-0196' genderId='13' key={Date.now()} />} />
          <Route path="/return-by-customer-pets-ytd" render={routeProps => <ReturnByCustomer {...routeProps} reportType='ytd' reportName="Returned By Customer Top 30 - Pet - YTD" reportNumber='RPT#DB-0197' genderId='12' key={Date.now()} />} />
          <Route path="/return-by-customer-pets-mtd" render={routeProps => <ReturnByCustomer {...routeProps} reportType='mtd' reportName="Returned By Customer Top 30 - Pet - MTD" reportNumber='RPT#DB-0198' genderId='12' key={Date.now()} />} />

          <Route path="/return-by-region" render={routeProps => <ReturnByRegion {...routeProps} reportType='' reportName="Returned By Region" reportNumber='' genderId='-1' key={Date.now()} />} />
          <Route path="/return-by-region-ytd" render={routeProps => <ReturnByRegion {...routeProps} reportType='ytd' reportName="Returned By Region - YTD" reportNumber='RPT#DB-0148' genderId='-1' key={Date.now()} />} />
          <Route path="/return-by-region-mtd" render={routeProps => <ReturnByRegion {...routeProps} reportType='mtd' reportName="Returned By Region - MTD" reportNumber='RPT#DB-0199' genderId='-1' key={Date.now()} />} />
          <Route path="/return-by-region-women-ytd" render={routeProps => <ReturnByRegion {...routeProps} reportType='ytd' reportName="Returned By Region - Women - YTD" reportNumber='RPT#DB-0200' genderId='7' key={Date.now()} />} />
          <Route path="/return-by-region-women-mtd" render={routeProps => <ReturnByRegion {...routeProps} reportType='mtd' reportName="Returned By Region - Women - MTD" reportNumber='RPT#DB-0201' genderId='7' key={Date.now()} />} />
          <Route path="/return-by-region-men-ytd" render={routeProps => <ReturnByRegion {...routeProps} reportType='ytd' reportName="Returned By Region - Men - YTD" reportNumber='RPT#DB-0202' genderId='9' key={Date.now()} />} />
          <Route path="/return-by-region-men-mtd" render={routeProps => <ReturnByRegion {...routeProps} reportType='mtd' reportName="Returned By Region - Men - MTD" reportNumber='RPT#DB-0203' genderId='9' key={Date.now()} />} />
          <Route path="/return-by-region-kids-ytd" render={routeProps => <ReturnByRegion {...routeProps} reportType='ytd' reportName="Returned By Region - Kids - YTD" reportNumber='RPT#DB-0204' genderId='13' key={Date.now()} />} />
          <Route path="/return-by-region-kids-mtd" render={routeProps => <ReturnByRegion {...routeProps} reportType='mtd' reportName="Returned By Region - Kids - MTD" reportNumber='RPT#DB-0205' genderId='13' key={Date.now()} />} />
          <Route path="/return-by-region-pets-ytd" render={routeProps => <ReturnByRegion {...routeProps} reportType='ytd' reportName="Returned By Region - Pet - YTD" reportNumber='RPT#DB-0206' genderId='12' key={Date.now()} />} />
          <Route path="/return-by-region-pets-mtd" render={routeProps => <ReturnByRegion {...routeProps} reportType='mtd' reportName="Returned By Region - Pet - MTD" reportNumber='RPT#DB-0207' genderId='12' key={Date.now()} />} />

          <Route path="/return-by-fit-style" render={routeProps => <ReturnByFitStyle {...routeProps} reportType='' reportName="Returned By Fit/Style" reportNumber='' genderId='-1' key={Date.now()} />} />
          <Route path="/return-by-fit-style-ytd" render={routeProps => <ReturnByFitStyle {...routeProps} reportType='ytd' reportName="Returned By Fit/Style - YTD" reportNumber='RPT#DB-0158' genderId='-1' key={Date.now()} />} />
          <Route path="/return-by-fit-style-mtd" render={routeProps => <ReturnByFitStyle {...routeProps} reportType='mtd' reportName="Returned By Fit/Style - MTD" reportNumber='RPT#DB-0208' genderId='-1' key={Date.now()} />} />
          <Route path="/return-by-fit-style-women-ytd" render={routeProps => <ReturnByFitStyle {...routeProps} reportType='ytd' reportName="Returned By Fit/Style - Women - YTD" reportNumber='RPT#DB-0209' genderId='7' key={Date.now()} />} />
          <Route path="/return-by-fit-style-women-mtd" render={routeProps => <ReturnByFitStyle {...routeProps} reportType='mtd' reportName="Returned By Fit/Style - Women - MTD" reportNumber='RPT#DB-0210' genderId='7' key={Date.now()} />} />
          <Route path="/return-by-fit-style-men-ytd" render={routeProps => <ReturnByFitStyle {...routeProps} reportType='ytd' reportName="Returned By Fit/Style - Men - YTD" reportNumber='RPT#DB-0211' genderId='9' key={Date.now()} />} />
          <Route path="/return-by-fit-style-men-mtd" render={routeProps => <ReturnByFitStyle {...routeProps} reportType='mtd' reportName="Returned By Fit/Style - Men - MTD" reportNumber='RPT#DB-0212' genderId='9' key={Date.now()} />} />
          <Route path="/return-by-fit-style-kids-ytd" render={routeProps => <ReturnByFitStyle {...routeProps} reportType='ytd' reportName="Returned By Fit/Style - Kids - YTD" reportNumber='RPT#DB-0213' genderId='13' key={Date.now()} />} />
          <Route path="/return-by-fit-style-kids-mtd" render={routeProps => <ReturnByFitStyle {...routeProps} reportType='mtd' reportName="Returned By Fit/Style - Kids - MTD" reportNumber='RPT#DB-0214' genderId='13' key={Date.now()} />} />
          <Route path="/return-by-fit-style-pets-ytd" render={routeProps => <ReturnByFitStyle {...routeProps} reportType='ytd' reportName="Returned By Fit/Style - Pet - YTD" reportNumber='RPT#DB-0215' genderId='12' key={Date.now()} />} />
          <Route path="/return-by-fit-style-pets-mtd" render={routeProps => <ReturnByFitStyle {...routeProps} reportType='mtd' reportName="Returned By Fit/Style - Pet - MTD" reportNumber='RPT#DB-0216' genderId='12' key={Date.now()} />} />
          {/*Net Shipped*/}
          <Route path="/net-shipped-by-sale-person" render={routeProps => <NetShippedBySalePerson {...routeProps} reportType='' reportName="Net Shipped By SalePerson" reportNumber='' genderId='-1' querytype='' key={Date.now()} />} />
          <Route path="/net-shipped-by-sale-person-ytd" render={routeProps => <NetShippedBySalePerson {...routeProps} reportType='ytd' reportName="Net Shipped By SalePerson - YTD" reportNumber='RPT#DB-0217' genderId='-1' querytype='all salesperson' key={Date.now()} />} />
          <Route path="/net-shipped-by-sale-person-mtd" render={routeProps => <NetShippedBySalePerson {...routeProps} reportType='mtd' reportName="Net Shipped By SalePerson - MTD" reportNumber='RPT#DB-0218' genderId='-1' querytype='all salesperson' key={Date.now()} />} />
          <Route path="/net-shipped-by-sale-person-women-ytd" render={routeProps => <NetShippedBySalePerson {...routeProps} reportType='ytd' reportName="Net Shipped By SalePerson - Women - YTD" reportNumber='RPT#DB-0219' genderId='7' querytype='all salesperson' key={Date.now()} />} />
          <Route path="/net-shipped-by-sale-person-women-mtd" render={routeProps => <NetShippedBySalePerson {...routeProps} reportType='mtd' reportName="Net Shipped By SalePerson - Women - MTD" reportNumber='RPT#DB-0220' genderId='7' querytype='all salesperson' key={Date.now()} />} />
          <Route path="/net-shipped-by-sale-person-men-ytd" render={routeProps => <NetShippedBySalePerson {...routeProps} reportType='ytd' reportName="Net Shipped By SalePerson - Men - YTD" reportNumber='RPT#DB-0221' genderId='9' querytype='all salesperson' key={Date.now()} />} />
          <Route path="/net-shipped-by-sale-person-men-mtd" render={routeProps => <NetShippedBySalePerson {...routeProps} reportType='mtd' reportName="Net Shipped By SalePerson - Men - MTD" reportNumber='RPT#DB-0222' genderId='9' querytype='all salesperson' key={Date.now()} />} />
          <Route path="/net-shipped-by-sale-person-kids-ytd" render={routeProps => <NetShippedBySalePerson {...routeProps} reportType='ytd' reportName="Net Shipped By SalePerson - Kids - YTD" reportNumber='RPT#DB-0223' genderId='13' querytype='all salesperson' key={Date.now()} />} />
          <Route path="/net-shipped-by-sale-person-kids-mtd" render={routeProps => <NetShippedBySalePerson {...routeProps} reportType='mtd' reportName="Net Shipped By SalePerson - Kids - MTD" reportNumber='RPT#DB-0224' genderId='13' querytype='all salesperson' key={Date.now()} />} />
          <Route path="/net-shipped-by-sale-person-pets-ytd" render={routeProps => <NetShippedBySalePerson {...routeProps} reportType='ytd' reportName="Net Shipped By SalePerson - Pet - YTD" reportNumber='RPT#DB-0225' genderId='12' querytype='all salesperson' key={Date.now()} />} />
          <Route path="/net-shipped-by-sale-person-pets-mtd" render={routeProps => <NetShippedBySalePerson {...routeProps} reportType='mtd' reportName="Net Shipped By SalePerson - Pet - MTD" reportNumber='RPT#DB-0226' genderId='12' querytype='all salesperson' key={Date.now()} />} />


          <Route path='/net-shipped-by-gender' render={routeProps => <NetShippedByGender {...routeProps} reportType='' reportName="Net Shipped By Gender" reportNumber='' genderId='-1' querytype='all gender' key={Date.now()} />} />
          <Route path='/net-shipped-by-gender-ytd' render={routeProps => <NetShippedByGender {...routeProps} reportType='ytd' reportName="Net Shipped By Gender - YTD" reportNumber='RPT#DB-0227' genderId='-1' querytype='all gender' key={Date.now()} />} />
          <Route path='/net-shipped-by-gender-mtd' render={routeProps => <NetShippedByGender {...routeProps} reportType='mtd' reportName="Net Shipped By Gender - MTD" reportNumber='RPT#DB-0228' genderId='-1' querytype='all gender' key={Date.now()} />} />

          <Route path="/net-shipped-by-customer" render={routeProps => <NetShippedByCustomer {...routeProps} reportType='' reportName="Net Shipped By Customer Top" reportNumber='' genderId='-1' key={Date.now()} />} />
          <Route path="/net-shipped-by-customer-ytd" render={routeProps => <NetShippedByCustomer {...routeProps} reportType='ytd' reportName="Net Shipped By Customer Top 30 - YTD" reportNumber='RPT#DB-0229' genderId='-1' key={Date.now()} />} />
          <Route path="/net-shipped-by-customer-mtd" render={routeProps => <NetShippedByCustomer {...routeProps} reportType='mtd' reportName="Net Shipped By Customer Top 30 - MTD" reportNumber='RPT#DB-0230' genderId='-1' key={Date.now()} />} />
          <Route path="/net-shipped-by-customer-women-ytd" render={routeProps => <NetShippedByCustomer {...routeProps} reportType='ytd' reportName="Net Shipped By Customer Top 30 - Women - YTD" reportNumber='RPT#DB-0231' genderId='7' key={Date.now()} />} />
          <Route path="/net-shipped-by-customer-women-mtd" render={routeProps => <NetShippedByCustomer {...routeProps} reportType='mtd' reportName="Net Shipped By Customer Top 30 - Women - MTD" reportNumber='RPT#DB-0232' genderId='7' key={Date.now()} />} />
          <Route path="/net-shipped-by-customer-men-ytd" render={routeProps => <NetShippedByCustomer {...routeProps} reportType='ytd' reportName="Net Shipped By Customer Top 30 - Men - YTD" reportNumber='RPT#DB-0233' genderId='9' key={Date.now()} />} />
          <Route path="/net-shipped-by-customer-men-mtd" render={routeProps => <NetShippedByCustomer {...routeProps} reportType='mtd' reportName="Net Shipped By Customer Top 30 - Men - MTD" reportNumber='RPT#DB-0234' genderId='9' key={Date.now()} />} />
          <Route path="/net-shipped-by-customer-kids-ytd" render={routeProps => <NetShippedByCustomer {...routeProps} reportType='ytd' reportName="Net Shipped By Customer Top 30 - Kids - YTD" reportNumber='RPT#DB-0235' genderId='13' key={Date.now()} />} />
          <Route path="/net-shipped-by-customer-kids-mtd" render={routeProps => <NetShippedByCustomer {...routeProps} reportType='mtd' reportName="Net Shipped By Customer Top 30 - Kids - MTD" reportNumber='RPT#DB-0236' genderId='13' key={Date.now()} />} />
          <Route path="/net-shipped-by-customer-pets-ytd" render={routeProps => <NetShippedByCustomer {...routeProps} reportType='ytd' reportName="Net Shipped By Customer Top 30 - Pet - YTD" reportNumber='RPT#DB-0237' genderId='12' key={Date.now()} />} />
          <Route path="/net-shipped-by-customer-pets-mtd" render={routeProps => <NetShippedByCustomer {...routeProps} reportType='mtd' reportName="Net Shipped By Customer Top 30 - Pet - MTD" reportNumber='RPT#DB-0238' genderId='12' key={Date.now()} />} />

          <Route path="/net-shipped-by-region" render={routeProps => <NetShippedByRegion {...routeProps} reportType='' reportName="" reportNumber='' class='net-shipped-by-region-table' genderId='-1' querytype='' key={Date.now()} />} />
          <Route path="/net-shipped-by-region-ytd" render={routeProps => <NetShippedByRegion {...routeProps} reportType='ytd' reportName="Net Shipped By Region - YTD" reportNumber='RPT#DB-0239' class='net-shipped-by-region-table' genderId='-1' querytype='all region' key={Date.now()} />} />
          <Route path="/net-shipped-by-region-mtd" render={routeProps => <NetShippedByRegion {...routeProps} reportType='mtd' reportName="Net Shipped By Region - MTD" reportNumber='RPT#DB-0240' class='net-shipped-by-region-table' genderId='-1' querytype='all region' key={Date.now()} />} />
          <Route path="/net-shipped-by-region-women-ytd" render={routeProps => <NetShippedByRegion {...routeProps} reportType='ytd' reportName="Net Shipped By Region - Women - YTD" reportNumber='RPT#DB-0241' class='net-shipped-by-region-table' genderId='7' querytype='all region' key={Date.now()} />} />
          <Route path="/net-shipped-by-region-women-mtd" render={routeProps => <NetShippedByRegion {...routeProps} reportType='mtd' reportName="Net Shipped By Region - Women - MTD" reportNumber='RPT#DB-0242' class='net-shipped-by-region-table' genderId='7' querytype='all region' key={Date.now()} />} />
          <Route path="/net-shipped-by-region-men-ytd" render={routeProps => <NetShippedByRegion {...routeProps} reportType='ytd' reportName="Net Shipped By Region - Men - YTD" reportNumber='RPT#DB-0243' class='net-shipped-by-region-table' genderId='9' querytype='all region' key={Date.now()} />} />
          <Route path="/net-shipped-by-region-men-mtd" render={routeProps => <NetShippedByRegion {...routeProps} reportType='mtd' reportName="Net Shipped By Region - Men - MTD" reportNumber='RPT#DB-0244' class='net-shipped-by-region-table' genderId='9' querytype='all region' key={Date.now()} />} />
          <Route path="/net-shipped-by-region-kids-ytd" render={routeProps => <NetShippedByRegion {...routeProps} reportType='ytd' reportName="Net Shipped By Region - Kids - YTD" reportNumber='RPT#DB-0245' class='net-shipped-by-region-table' genderId='13' querytype='all region' key={Date.now()} />} />
          <Route path="/net-shipped-by-region-kids-mtd" render={routeProps => <NetShippedByRegion {...routeProps} reportType='mtd' reportName="Net Shipped By Region - Kids - MTD" reportNumber='RPT#DB-0246' class='net-shipped-by-region-table' genderId='13' querytype='all region' key={Date.now()} />} />
          <Route path="/net-shipped-by-region-pets-ytd" render={routeProps => <NetShippedByRegion {...routeProps} reportType='ytd' reportName="Net Shipped By Region - Pet - YTD" reportNumber='RPT#DB-0247' class='net-shipped-by-region-table' genderId='12' querytype='all region' key={Date.now()} />} />
          <Route path="/net-shipped-by-region-pets-mtd" render={routeProps => <NetShippedByRegion {...routeProps} reportType='mtd' reportName="Net Shipped By Region - Pet - MTD" reportNumber='RPT#DB-0248' class='net-shipped-by-region-table' genderId='12' querytype='all region' key={Date.now()} />} />



          <Route path="/net-shipped-by-fit-style" render={routeProps => <NetShippedByFitStyle {...routeProps} reportType='ytd' reportName="Net Shipped By Fit/Style" reportNumber='' class='net-shipped-by-fit-style-table' genderId='-1' querytype='' key={Date.now()} />} />
          <Route path="/net-shipped-by-fit-style-ytd" render={routeProps => <NetShippedByFitStyle {...routeProps} reportType='ytd' reportName="Net Shipped By Fit/Style - YTD" reportNumber='RPT#DB-0249' class='net-shipped-by-fit-style-table' genderId='-1' querytype='all fitstyle' key={Date.now()} />} />
          <Route path="/net-shipped-by-fit-style-mtd" render={routeProps => <NetShippedByFitStyle {...routeProps} reportType='mtd' reportName="Net Shipped By Fit/Style - MTD" reportNumber='RPT#DB-0250' class='net-shipped-by-fit-style-table' genderId='-1' querytype='all fitstyle' key={Date.now()} />} />
          <Route path="/net-shipped-by-fit-style-women-ytd" render={routeProps => <NetShippedByFitStyle {...routeProps} reportType='ytd' reportName="Net Shipped By Fit/Style - Women - YTD" reportNumber='RPT#DB-0251' class='net-shipped-by-fit-style-table' genderId='7' querytype='all fitstyle' key={Date.now()} />} />
          <Route path="/net-shipped-by-fit-style-women-mtd" render={routeProps => <NetShippedByFitStyle {...routeProps} reportType='mtd' reportName="Net Shipped By Fit/Style - Women - MTD" reportNumber='RPT#DB-0252' class='net-shipped-by-fit-style-table' genderId='7' querytype='all fitstyle' key={Date.now()} />} />
          <Route path="/net-shipped-by-fit-style-men-ytd" render={routeProps => <NetShippedByFitStyle {...routeProps} reportType='ytd' reportName="Net Shipped By Fit/Style - Men - YTD" reportNumber='RPT#DB-0253' class='net-shipped-by-fit-style-table' genderId='9' querytype='all fitstyle' key={Date.now()} />} />
          <Route path="/net-shipped-by-fit-style-men-mtd" render={routeProps => <NetShippedByFitStyle {...routeProps} reportType='mtd' reportName="Net Shipped By Fit/Style - Men - MTD" reportNumber='RPT#DB-0254' class='net-shipped-by-fit-style-table' genderId='9' querytype='all fitstyle' key={Date.now()} />} />
          <Route path="/net-shipped-by-fit-style-kids-ytd" render={routeProps => <NetShippedByFitStyle {...routeProps} reportType='ytd' reportName="Net Shipped By Fit/Style - Kids - YTD" reportNumber='RPT#DB-0255' class='net-shipped-by-fit-style-table' genderId='13' querytype='all fitstyle' key={Date.now()} />} />
          <Route path="/net-shipped-by-fit-style-kids-mtd" render={routeProps => <NetShippedByFitStyle {...routeProps} reportType='mtd' reportName="Net Shipped By Fit/Style - Kids - MTD" reportNumber='RPT#DB-0256' class='net-shipped-by-fit-style-table' genderId='13' querytype='all fitstyle' key={Date.now()} />} />
          <Route path="/net-shipped-by-fit-style-pets-ytd" render={routeProps => <NetShippedByFitStyle {...routeProps} reportType='ytd' reportName="Net Shipped By Fit/Style - Pet - YTD" reportNumber='RPT#DB-0257' class='net-shipped-by-fit-style-table' genderId='12' querytype='all fitstyle' key={Date.now()} />} />
          <Route path="/net-shipped-by-fit-style-pets-mtd" render={routeProps => <NetShippedByFitStyle {...routeProps} reportType='mtd' reportName="Net Shipped By Fit/Style - Pet - MTD" reportNumber='RPT#DB-0258' class='net-shipped-by-fit-style-table' genderId='12' querytype='all fitstyle' key={Date.now()} />} />

          <Route path="/net-shipped-by-fit-style-women-sku-ytd" render={routeProps => <NetShippedByFitStyle {...routeProps} reportType='ytd' reportName="Net Shipped By TOPSKU- W-TOPSKU -YTD" reportNumber='RPT#DB-0259' class='net-shipped-by-top-sku-table' genderId='7' querytype='top sku' key={Date.now()} />} />
          <Route path="/net-shipped-by-fit-style-women-sku-mtd" render={routeProps => <NetShippedByFitStyle {...routeProps} reportType='mtd' reportName="Net Shipped By TOPSKU- W-TOPSKU -MTD" reportNumber='RPT#DB-0260' class='net-shipped-by-top-sku-table' genderId='7' querytype='top sku' key={Date.now()} />} />
          <Route path="/net-shipped-by-fit-style-men-sku-ytd" render={routeProps => <NetShippedByFitStyle {...routeProps} reportType='ytd' reportName="Net Shipped By TOPSKU- M-TOPSKU -YTD" reportNumber='RPT#DB-0261' class='net-shipped-by-top-sku-table' genderId='9' querytype='top sku' key={Date.now()} />} />
          <Route path="/net-shipped-by-fit-style-men-sku-mtd" render={routeProps => <NetShippedByFitStyle {...routeProps} reportType='mtd' reportName="Net Shipped By TOPSKU- M-TOPSKU -MTD" reportNumber='RPT#DB-0262' class='net-shipped-by-top-sku-table' genderId='9' querytype='top sku' key={Date.now()} />} />
          <Route path="/net-shipped-by-fit-style-kids-sku-ytd" render={routeProps => <NetShippedByFitStyle {...routeProps} reportType='ytd' reportName="Net Shipped By TOPSKU- K-TOPSKU -YTD" reportNumber='RPT#DB-0263' class='net-shipped-by-top-sku-table' genderId='13' querytype='top sku' key={Date.now()} />} />
          <Route path="/net-shipped-by-fit-style-kids-sku-mtd" render={routeProps => <NetShippedByFitStyle {...routeProps} reportType='mtd' reportName="Net Shipped By TOPSKU- K-TOPSKU -MTD" reportNumber='RPT#DB-0264' class='net-shipped-by-top-sku-table' genderId='13' querytype='top sku' key={Date.now()} />} />
          <Route path="/net-shipped-by-fit-style-pets-sku-ytd" render={routeProps => <NetShippedByFitStyle {...routeProps} reportType='ytd' reportName="Net Shipped By TOPSKU- P-TOPSKU -YTD" reportNumber='RPT#DB-0265' class='net-shipped-by-top-sku-table' genderId='12' querytype='top sku' key={Date.now()} />} />
          <Route path="/net-shipped-by-fit-style-pets-sku-mtd" render={routeProps => <NetShippedByFitStyle {...routeProps} reportType='mtd' reportName="Net Shipped By TOPSKU- P-TOPSKU -MTD" reportNumber='RPT#DB-0266' class='net-shipped-by-top-sku-table' genderId='12' querytype='top sku' key={Date.now()} />} />


          <Route path="/net-shipped-by-fit-style-women-top-size-ytd" render={routeProps => <NetShippedByFitStyle {...routeProps} reportType='ytd' reportName="Net Shipped By TOPSIZE-  W-TOPSIZE -YTD" reportNumber='RPT#DB-0267' class='net-shipped-by-fit-style-top-size-table' genderId='7' querytype='top size' key={Date.now()} />} />
          <Route path="/net-shipped-by-fit-style-women-top-size-mtd" render={routeProps => <NetShippedByFitStyle {...routeProps} reportType='mtd' reportName="Net Shipped By TOPSIZE-  W-TOPSIZE -MTD" reportNumber='RPT#DB-0268' class='net-shipped-by-fit-style-top-size-table' genderId='7' querytype='top size' key={Date.now()} />} />
          <Route path="/net-shipped-by-fit-style-men-top-size-ytd" render={routeProps => <NetShippedByFitStyle {...routeProps} reportType='ytd' reportName="Net Shipped By TOPSIZE- M-TOPSIZE -YTD" reportNumber='RPT#DB-0269' class='net-shipped-by-fit-style-top-size-table' genderId='9' querytype='top size' key={Date.now()} />} />
          <Route path="/net-shipped-by-fit-style-men-top-size-mtd" render={routeProps => <NetShippedByFitStyle {...routeProps} reportType='mtd' reportName="Net Shipped By TOPSIZE- M-TOPSIZE -MTD" reportNumber='RPT#DB-0270' class='net-shipped-by-fit-style-top-size-table' genderId='9' querytype='top size' key={Date.now()} />} />
          <Route path="/net-shipped-by-fit-style-kids-top-size-ytd" render={routeProps => <NetShippedByFitStyle {...routeProps} reportType='ytd' reportName="Net Shipped By TOPSIZE- K-TOPSIZE -YTD" reportNumber='RPT#DB-0271' class='net-shipped-by-fit-style-top-size-table' genderId='13' querytype='top size' key={Date.now()} />} />
          <Route path="/net-shipped-by-fit-style-kids-top-size-mtd" render={routeProps => <NetShippedByFitStyle {...routeProps} reportType='mtd' reportName="Net Shipped By TOPSIZE- K-TOPSIZE -MTD" reportNumber='RPT#DB-0272' class='net-shipped-by-fit-style-top-size-table' genderId='13' querytype='top size' key={Date.now()} />} />
          <Route path="/net-shipped-by-fit-style-pets-top-size-ytd" render={routeProps => <NetShippedByFitStyle {...routeProps} reportType='ytd' reportName="Net Shipped By TOPSIZE- P-TOPSIZE -YTD" reportNumber='RPT#DB-0273' class='net-shipped-by-fit-style-top-size-table' genderId='12' querytype='top size' key={Date.now()} />} />
          <Route path="/net-shipped-by-fit-style-pets-top-size-mtd" render={routeProps => <NetShippedByFitStyle {...routeProps} reportType='mtd' reportName="Net Shipped By TOPSIZE- P-TOPSIZE -MTD" reportNumber='RPT#DB-0274' class='net-shipped-by-fit-style-top-size-table' genderId='12' querytype='top size' key={Date.now()} />} />


          {/* E-Commerce*/}

          <Route path="/e-commerce-by-region" render={routeProps => <Ecommerce {...routeProps} reportType='' reportName="E-Commerce" reportNumber='' genderId='-1' querytype='all ecommerce' key={Date.now()} />} />

          <Route path="/e-commerce-by-region-ytd" render={routeProps => <Ecommerce {...routeProps} reportType='ytd' reportName="E-Commerce - YTD" reportNumber='RPT#DB-0275' genderId='-1' querytype='all ecommerce' key={Date.now()} />} />
          <Route path="/e-commerce-by-region-mtd" render={routeProps => <Ecommerce {...routeProps} reportType='mtd' reportName="E-Commerce - MTD" reportNumber='RPT#DB-0276' genderId='-1' querytype='all ecommerce' key={Date.now()} />} />
          <Route path="/e-commerce-all-today" render={routeProps => <Ecommerce {...routeProps} reportType='today' reportName="E-commerce - All-Today" reportNumber='RPT#DB-0277' genderId='-1' querytype='all ecommerce' key={Date.now()} />} />

          <Route path="/e-commerce-by-women-top-style-ytd" render={routeProps => <Ecommerce {...routeProps} reportType='ytd' reportName="E-Commerce -  W-TOPFIT -YTD" reportNumber='RPT#DB-0278' class='top-style-table' genderId='7' querytype='top fit' key={Date.now()} />} />
          <Route path="/e-commerce-by-women-top-style-mtd" render={routeProps => <Ecommerce {...routeProps} reportType='mtd' reportName="E-Commerce -  W-TOPFIT -MTD" reportNumber='RPT#DB-0279' class='top-style-table' genderId='7' querytype='top fit' key={Date.now()} />} />
          <Route path="/e-commerce-by-women-top-style-today" render={routeProps => <Ecommerce {...routeProps} reportType='today' reportName="E-Commerce -  W-TOPFIT -TODAY" reportNumber='RPT#DB-0280' class='top-style-table' genderId='7' querytype='top fit' key={Date.now()} />} />

          <Route path="/e-commerce-by-men-top-style-ytd" render={routeProps => <Ecommerce {...routeProps} reportType='ytd' reportName="E-Commerce - M-TOPFIT -YTD" reportNumber='RPT#DB-0281' class='top-style-table' genderId='9' querytype='top fit' key={Date.now()} />} />
          <Route path="/e-commerce-by-men-top-style-mtd" render={routeProps => <Ecommerce {...routeProps} reportType='mtd' reportName="E-Commerce - M-TOPFIT -MTD" reportNumber='RPT#DB-0282' class='top-style-table' genderId='9' querytype='top fit' key={Date.now()} />} />
          <Route path="/e-commerce-by-men-top-style-today" render={routeProps => <Ecommerce {...routeProps} reportType='today' reportName="E-Commerce - M-TOPFIT -TODAY" reportNumber='RPT#DB-0283' class='top-style-table' genderId='9' querytype='top fit' key={Date.now()} />} />

          <Route path="/e-commerce-by-kids-top-style-ytd" render={routeProps => <Ecommerce {...routeProps} reportType='ytd' reportName="E-Commerce - K-TOPFIT -YTD" reportNumber='RPT#DB-0284' class='top-style-table' genderId='13' querytype='top fit' key={Date.now()} />} />
          <Route path="/e-commerce-by-kids-top-style-mtd" render={routeProps => <Ecommerce {...routeProps} reportType='mtd' reportName="E-Commerce - K-TOPFIT -MTD" reportNumber='RPT#DB-0285' class='top-style-table' genderId='13' querytype='top fit' key={Date.now()} />} />
          <Route path="/e-commerce-by-kids-top-style-today" render={routeProps => <Ecommerce {...routeProps} reportType='today' reportName="E-Commerce - K-TOPFIT -TODAY" reportNumber='RPT#DB-0286' class='top-style-table' genderId='13' querytype='top fit' key={Date.now()} />} />

          <Route path="/e-commerce-by-pets-top-style-ytd" render={routeProps => <Ecommerce {...routeProps} reportType='ytd' reportName="E-Commerce - P-TOPFIT -YTD" reportNumber='RPT#DB-0287' class='top-style-table' genderId='12' querytype='top fit' key={Date.now()} />} />
          <Route path="/e-commerce-by-pets-top-style-mtd" render={routeProps => <Ecommerce {...routeProps} reportType='mtd' reportName="E-Commerce - P-TOPFIT -MTD" reportNumber='RPT#DB-0288' class='top-style-table' genderId='12' querytype='top fit' key={Date.now()} />} />
          <Route path="/e-commerce-by-pets-top-style-today" render={routeProps => <Ecommerce {...routeProps} reportType='today' reportName="E-Commerce - P-TOPFIT -TODAY" reportNumber='RPT#DB-0289' class='top-style-table' genderId='12' querytype='top fit' key={Date.now()} />} />

          <Route path="/e-commerce-by-women-top-sku-ytd" render={routeProps => <Ecommerce {...routeProps} reportType='ytd' reportName="E-Commerce -  W-TOPSKU -YTD" reportNumber='RPT#DB-0290' class='top-sku-table' genderId='7' querytype='top sku' key={Date.now()} />} />
          <Route path="/e-commerce-by-women-top-sku-mtd" render={routeProps => <Ecommerce {...routeProps} reportType='mtd' reportName="E-Commerce -  W-TOPSKU -MTD" reportNumber='RPT#DB-0291' class='top-sku-table' genderId='7' querytype='top sku' key={Date.now()} />} />
          <Route path="/e-commerce-by-women-top-sku-today" render={routeProps => <Ecommerce {...routeProps} reportType='today' reportName="E-Commerce -  W-TOPSKU -TODAY" reportNumber='RPT#DB-0292' class='top-sku-table' genderId='7' querytype='top sku' key={Date.now()} />} />

          <Route path="/e-commerce-by-men-top-sku-ytd" render={routeProps => <Ecommerce {...routeProps} reportType='ytd' reportName="E-Commerce - M-TOPSKU -YTD" reportNumber='RPT#DB-0293' class='top-sku-table' genderId='9' querytype='top sku' key={Date.now()} />} />
          <Route path="/e-commerce-by-men-top-sku-mtd" render={routeProps => <Ecommerce {...routeProps} reportType='mtd' reportName="E-Commerce - M-TOPSKU -MTD" reportNumber='RPT#DB-0294' class='top-sku-table' genderId='9' querytype='top sku' key={Date.now()} />} />
          <Route path="/e-commerce-by-men-top-sku-today" render={routeProps => <Ecommerce {...routeProps} reportType='today' reportName="E-Commerce - M-TOPSKU -TODAY" reportNumber='RPT#DB-0295' class='top-sku-table' genderId='9' querytype='top sku' key={Date.now()} />} />

          <Route path="/e-commerce-by-kids-top-sku-ytd" render={routeProps => <Ecommerce {...routeProps} reportType='ytd' reportName="E-Commerce - K-TOPSKU -YTD" reportNumber='RPT#DB-0296' class='top-sku-table' genderId='13' querytype='top sku' key={Date.now()} />} />
          <Route path="/e-commerce-by-kids-top-sku-mtd" render={routeProps => <Ecommerce {...routeProps} reportType='mtd' reportName="E-Commerce - K-TOPSKU -MTD" reportNumber='RPT#DB-0297' class='top-sku-table' genderId='13' querytype='top sku' key={Date.now()} />} />
          <Route path="/e-commerce-by-kids-top-sku-today" render={routeProps => <Ecommerce {...routeProps} reportType='today' reportName="E-Commerce - K-TOPSKU -TODAY" reportNumber='RPT#DB-0298' class='top-sku-table' genderId='13' querytype='top sku' key={Date.now()} />} />

          <Route path="/e-commerce-by-pets-top-sku-ytd" render={routeProps => <Ecommerce {...routeProps} reportType='ytd' reportName="E-Commerce - P-TOPSKU -YTD" reportNumber='RPT#DB-0299' class='top-sku-table' genderId='12' querytype='top sku' key={Date.now()} />} />
          <Route path="/e-commerce-by-pets-top-sku-mtd" render={routeProps => <Ecommerce {...routeProps} reportType='mtd' reportName="E-Commerce - P-TOPSKU -MTD" reportNumber='RPT#DB-0300' class='top-sku-table' genderId='12' querytype='top sku' key={Date.now()} />} />
          <Route path="/e-commerce-by-pets-top-sku-today" render={routeProps => <Ecommerce {...routeProps} reportType='today' reportName="E-Commerce - P-TOPSKU -TODAY" reportNumber='RPT#DB-0301' class='top-sku-table' genderId='12' querytype='top sku' key={Date.now()} />} />

          <Route path="/e-commerce-by-women-top-size-ytd" render={routeProps => <Ecommerce {...routeProps} reportType='ytd' reportName="E-Commerce -  W-TOPSIZE -YTD" reportNumber='RPT#DB-0302' class='top-size-table' genderId='7' querytype='top size' key={Date.now()} />} />
          <Route path="/e-commerce-by-women-top-size-mtd" render={routeProps => <Ecommerce {...routeProps} reportType='mtd' reportName="E-Commerce -  W-TOPSIZE -MTD" reportNumber='RPT#DB-0303' class='top-size-table' genderId='7' querytype='top size' key={Date.now()} />} />
          <Route path="/e-commerce-by-women-top-size-today" render={routeProps => <Ecommerce {...routeProps} reportType='today' reportName="E-Commerce -  W-TOPSIZE -TODAY" reportNumber='RPT#DB-0304' class='top-size-table' genderId='7' querytype='top size' key={Date.now()} />} />

          <Route path="/e-commerce-by-men-top-size-ytd" render={routeProps => <Ecommerce {...routeProps} reportType='ytd' reportName="E-Commerce - M-TOPSIZE -YTD" reportNumber='RPT#DB-0305' class='top-size-table' genderId='9' querytype='top size' key={Date.now()} />} />
          <Route path="/e-commerce-by-men-top-size-mtd" render={routeProps => <Ecommerce {...routeProps} reportType='mtd' reportName="E-Commerce - M-TOPSIZE -MTD" reportNumber='RPT#DB-0306' class='top-size-table' genderId='9' querytype='top size' key={Date.now()} />} />
          <Route path="/e-commerce-by-men-top-size-today" render={routeProps => <Ecommerce {...routeProps} reportType='today' reportName="E-Commerce - M-TOPSIZE -TODAY" reportNumber='RPT#DB-0307' class='top-size-table' genderId='9' querytype='top size' key={Date.now()} />} />

          <Route path="/e-commerce-by-kids-top-size-ytd" render={routeProps => <Ecommerce {...routeProps} reportType='ytd' reportName="E-Commerce - K-TOPSIZE -YTD" reportNumber='RPT#DB-0308' class='top-size-table' genderId='13' querytype='top size' key={Date.now()} />} />
          <Route path="/e-commerce-by-kids-top-size-mtd" render={routeProps => <Ecommerce {...routeProps} reportType='mtd' reportName="E-Commerce - K-TOPSIZE -MTD" reportNumber='RPT#DB-0309' class='top-size-table' genderId='13' querytype='top size' key={Date.now()} />} />
          <Route path="/e-commerce-by-kids-top-size-today" render={routeProps => <Ecommerce {...routeProps} reportType='mtd' reportName="E-Commerce - K-TOPSIZE -TODAY" reportNumber='RPT#DB-0310' class='top-size-table' genderId='13' querytype='top size' key={Date.now()} />} />

          <Route path="/e-commerce-by-pets-top-size-ytd" render={routeProps => <Ecommerce {...routeProps} reportType='ytd' reportName="E-Commerce - P-TOPSIZE -YTD" reportNumber='RPT#DB-0311' class='top-size-table' genderId='12' querytype='top size' key={Date.now()} />} />
          <Route path="/e-commerce-by-pets-top-size-mtd" render={routeProps => <Ecommerce {...routeProps} reportType='mtd' reportName="E-Commerce - P-TOPSIZE -MTD" reportNumber='RPT#DB-0312' class='top-size-table' genderId='12' querytype='top size' key={Date.now()} />} />
          <Route path="/e-commerce-by-pets-top-size-today" render={routeProps => <Ecommerce {...routeProps} reportType='today' reportName="E-Commerce - P-TOPSIZE -TODAY" reportNumber='RPT#DB-0313' class='top-size-table' genderId='12' querytype='top size' key={Date.now()} />} />


          <Route path="/business-leaderboard" component={BusinessBoard} ></Route>
          <Route path="/business-leaderboard-region-wise" component={BusinessBoardRegionWise} ></Route>
          <Route path="/business-board-test" component={BusinessBoardTest} ></Route>
          <Route path="/sale-comparison-report" component={SalesComparisonReport} ></Route>
          <Route path="/stock-report" component={StockReport} ></Route>
          <Route path="/b-grade-stock-report" component={BGradeStockReport} ></Route>
          <Route path="/product-report" component={ProductReport} ></Route>
          <Route path="/product-composition-report" component={ProductCompositionReport} ></Route>
          <Route path="/customer-report" component={CustomerReport} ></Route>
          <Route path="/order-by-po" component={SalesReport} ></Route>
          <Route path="/order-by-product" component={OrderByProduct} ></Route>
          <Route path="/purchase-order" component={PurchaseOrderReport} ></Route>
          <Route path="/sku-lookup" component={SKU_LOOKUP} ></Route>
          <Route path="/sku-lookupv2" component={SKU_LOOKUPV2} ></Route>
          <Route path="/cumulative-ots" component={CUMULATIVE_OTS} ></Route>
          <Route path="/inventory-product-report" component={InventoryProductReport} ></Route>
          <Route path="/inventory-ots-summary" component={InventoryOtsSummaryReport} ></Route>
          <Route path="/inventory-ots-detail" component={InventoryOtsDetailReport} ></Route>
          <Route path="/inventory-production-summary" component={InventoryProductionSummaryReport} ></Route>
          <Route path="/inventory-production-detail" component={InventoryProductionDetailReport} ></Route>
          <Route path="/invoice" component={Invoice} ></Route>
          <Route path="/sku-wise-search" component={SkuWiseSearch} ></Route>
          <Route path="/goods-required" component={GoodsRequired} ></Route>
          <Route path="/admin" component={AdminConfig} ></Route>
          <Route path="/order-booking" component={OrderBooking} ></Route>
          <Route path="/stock-location-wise" component={StockLocationWise} ></Route>
        </Switch>
      </Router>
    </>
  );
}
export default App;   