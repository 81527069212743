import moment from 'moment';
import React, { useEffect } from 'react';

const DateRange = (props) => {

    /**
     * Return date to its parent component whenever date changes by the user.
     */
    function returnDate() {
        const fromDate = document.getElementById('fromdate').value;
        const toDate = document.getElementById('todate').value;
        props.dateData({ fromDate: fromDate, toDate: toDate });
    }

    /**
     * Get today date from moment.js and set in both date picker. Also send selected default ( today Date ) to parent component.
     * Should be called on first render of component.
     */
    function setDate() {
        //const today = moment(new Date()).format('YYYY-MM-DD');
        if (props.defaultDate != null) {
            document.getElementById('fromdate').value = props.defaultDate.fromDate;
            document.getElementById('todate').value = props.defaultDate.toDate;
            props.dateData(props.defaultDate);
        } else {
            // Send fromDate and toDate to parent component on first render which is today date.
            const today = moment(new Date()).format('YYYY-MM-DD');
            document.getElementById('fromdate').value = today;
            document.getElementById('todate').value = today;
            props.dateData({ fromDate: today, toDate: today });
        }

    }

    /**
     * Call function setDate on first render of component.
     */
    useEffect(() => {
        setDate();
    }, []); // Passing empty array will only call useEffect on first render of component.

    return (
        <div className="date-filter">
            <h3 className="filter-title">Date Range</h3>
            <form>
                <div className="dt-inr-left dt-inr">
                    <label>From</label>
                    <input type="date" id="fromdate" onChange={returnDate} />
                </div>
                <div className="dt-inr-right dt-inr">
                    <label>To</label>
                    <input type="date" id="todate" onChange={returnDate} />
                </div>
            </form>
        </div>
    );
}

export default DateRange;