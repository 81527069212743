import React, {useState} from 'react';
import Header from '../../header/header';
import config from '../../../config/config';
import GroupOn from './../../common/groupOn';
import Group from './../../common/group';
import DateRange from './../../common/dateRange';
import SalesReportFilter from './salesReportFilter';
import moment from 'moment';
import ReportDialog from './../../common/reportDialog';
import {Redirect} from 'react-router';

const groupsOn = require('./../../../data/sales/groupOn.json');

const SalesReport = (props) => {
	const [groupOnArray, setGroupOnArray] = useState([]);
	const [date, setDate] = useState({});
	const [closeReport, setCloseReport] = useState(false);
	const [formState, setFormState] = useState({});
	const [display, setDisplay] = useState(false);
	const [reportUrl, setReportUrl] = useState();
	const [pdfUrl, setPDFUrl] = useState();
	const [isGroup, setIsGroup] = useState(true);

	function showTable(){
		let selectedArray = groupOnArray;
		let url = config.SALE_ORDER_URL;
		let pdfUrl = config.SALE_ORDER_PDF_URL;
		setFormState({...formState, groupBy: selectedArray});
		pdfUrl +=
			'&fromDate=' +
			moment(date.fromDate).format('MM/DD/YYYY') +
			'&toDate=' +
			moment(date.toDate).format('MM/DD/YYYY');
		setReportUrl(url);
		setPDFUrl(pdfUrl);
		setDisplay(true);
	}

	function setIsGroupValue(groupId){
		// Change the group state so it can disabled or enabled sortOn by calling it.


			groupId == '1' ? setIsGroup(false) :
			setIsGroup(true); // If id is 1 then set group to true else false.
	}

	return (
		<div>
			<Header />
			<div className="main-content">
				<div className="report-container group">
					<div className="report-container-header">
						<h1>Order By PO #</h1>
						<button id="close-report-btn" onClick={() => setCloseReport(true)}>
							Close
						</button>
						{
							closeReport == true ? <Redirect to="/dashboard" /> :
							null}
					</div>
					<div className="report-container-inner">
						<div className="r-left">
							<DateRange
								defaultDate={{fromDate: '2022-01-01', toDate: '2023-12-31'}}
								dateData={(date) => setDate(date)}
							/>

							<SalesReportFilter
								filterData={(state) => {
									setFormState(state);
								}}
								date={date}
							/>
						</div>
						<div className="r-right">
							<Group groupData={setIsGroupValue} disabled={false} />
							<GroupOn
								disabled={isGroup}
								groupOn={groupsOn}
								groupOnData={(arr) => setGroupOnArray(arr)}
							/>
							<button className="report-submit-btn" id="genreport" onClick={showTable}>
								SEARCH
							</button>
						</div>
					</div>
				</div>
				<ReportDialog
					display={display}
					callBack={() => {
						setDisplay(false);
					}}
					url={reportUrl}
					pdfUrl={pdfUrl}
                    body={formState}
                    fromDate={date.fromDate}
                    toDate={date.toDate}
				/>
			</div>
		</div>
	);
};

export default SalesReport;
