import React from 'react';

const Ecom = ( props ) => {

    /**
     * @param event // Event object.
     * Send back id of the selected radio button to its parent component. 
     */
    function returnData( event ) {
        props.ecomData( event.target.id );
    }

    return(
        <div className="status-filter">
            <h3 className="filter-title">Ecommerce</h3>
            <div className="status-buttons">
                <div className="btns-main">
                    <form>
                        <span className="st-btns">
                            <label><input id="1" type="radio" name="isecom" className="isecom" value="1"  disabled={true} onChange={ returnData }/><span>Yes</span></label>
                        </span>
                        <span className="st-btns">
                            <label><input id="2" type="radio" name="isecom" className="isecom" value="1" defaultChecked={true} onChange={ returnData }/><span>No</span></label>
                        </span>
                    </form>
                </div> 
            </div>
        </div>
    );
}

export default Ecom;