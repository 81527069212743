import React, { useEffect, useState } from 'react';

const GroupOn = (props) => {

    const groupOn = props.groupOn; // SortOn array that passed by its parent component.

    const [selectedGroup, setSelectedGroup] = useState([]);

    useEffect(() => {
        disabledLabel();
    }, [props.disabled]);


    useEffect(() => {
        props.groupOnData(selectedGroup);
    }, [selectedGroup]);


    function unCheckAll() {
        groupOn.forEach(filter => {
            document.getElementById("checkbox" + filter.id).checked = false;
        });
    }
    // AJ
    function unCheckAllExceptForSelectedOne(selectedOne) {
        groupOn.forEach(filter => {
            if (filter.id != selectedOne) {
                document.getElementById("checkbox" + filter.id).checked = false;
            }
        });
    }
    // AJ
    function disabledLabel() {
        if (document != null && document.getElementById("report-filter") != null) {
            if (props.disabled && !document.getElementById("report-filter").classList.contains("group-label")) {
                document.getElementById("report-filter").classList.add("group-label");
                setSelectedGroup([]);
                unCheckAll();
            } else {
                document.getElementById("report-filter").classList.remove("group-label");
            }
        }
    }

    function addToSelectedArray(selectedOption) {
        if (document.getElementById("checkbox" + selectedOption.id).checked) {
            unCheckAllExceptForSelectedOne(selectedOption.id)
            var array = selectedGroup;
            array.pop()// AJ
            array.push(selectedOption.id);
            setSelectedGroup(array);
        } else {

            var array = selectedGroup;

            const idx = array.indexOf(selectedOption.id);
            if (idx != -1) {
                array.splice(idx, 1)
                setSelectedGroup(array);
            }
        }
        props.groupOnData(array);
    }

    /**
     * Function that will map options array object to the spans of options. 
     */
    function renderGroupOn() {
        return groupOn.map(filter => {
            return (
                <span key={filter.id} className="st-btns-right">
                    <label id={"label" + filter.id}>
                        <input id={"checkbox" + filter.id} type="checkbox" name={ props.name ? props.name :'groupon'} disabled={props.disabled} onChange={addToSelectedArray.bind(this, filter)} />
                        <span>{filter.value}</span>
                    </label>
                </span>
            );
        });
    }

    return (
        <div className="report-filter-right" id="report-filter">
            <h3 className="filter-title">Group On</h3>
            <div className="btns-main-right">
                {renderGroupOn()}
            </div>
        </div>
    );
}

export default GroupOn;