import React, {useState, useLayoutEffect} from 'react';
import AutoCompleteSelect from '../../../common/autoCompleteSelect';
import useDidMountEffect from '../../../common/useDidMountEffect';
import config from '../../../../config/config';
import moment from 'moment';
const LOCAL_URL = config.GET_ALL_URL;
let JWT;

const BusinessBoardFilter = (props) => {
	const [otherThanFirst, setOtherThanFirst] = useState(true);
	const [genderId, setGenderId] = useState('-1');
	const [genderText, setGenderText] = useState('All');
	const [regionId, setRegionId] = useState('-1');
	const [regionText, setRegionText] = useState('All');
	const [Genders, setGenders] = useState([]);
	const [regions, setRegions] = useState([]);
	// Loading booleans to be set on AutoComplete.
	const [genderLoading, setGenderLoading] = useState(true);
	const [regionsLoading, setRegionsLoading] = useState(true);

	useState(() => {
		JWT = localStorage.getItem('access_token');
	}, []);

	function getNewState(){
		return {
			currentYearStartDate: moment().startOf('year').format('MM/DD/YYYY'),
			currentYearEndDate: moment().endOf('year').format('MM/DD/YYYY'),
			lastYearStartDate: moment().startOf('year').subtract(1, 'year').format('MM/DD/YYYY'),
			lastYearEndDate: moment().endOf('year').subtract(1, 'year').format('MM/DD/YYYY'),
			genderId: genderId,
			genderText: genderText,
			regionText: regionText,
			regionId: regionId
			
		};
	}

	useLayoutEffect(() => {
		if (otherThanFirst) {
			const newState = {
				currentYearStartDate: moment().startOf('year').format('MM/DD/YYYY'),
				currentYearEndDate: moment().endOf('year').format('MM/DD/YYYY'),
				lastYearStartDate: moment().startOf('year').subtract(1, 'year').format('MM/DD/YYYY'),
				lastYearEndDate: moment().endOf('year').subtract(1, 'year').format('MM/DD/YYYY'),
				genderText: 'All',
				regionText: 'All',
				genderId: '-1',
				regionId: '-1'
			};
			props.filterData(newState);
			getGenders(newState);
		}
	}, []);

	useDidMountEffect(
		() => {
			const newState = getNewState();
			props.filterData(newState);
		},
		[genderId, regionId,genderText,regionText]
	);

	useDidMountEffect(
		() => {
			const newState = {
				currentYearStartDate: moment().startOf('year').format('MM/DD/YYYY'),
				currentYearEndDate: moment().endOf('year').format('MM/DD/YYYY'),
				lastYearStartDate: moment().startOf('year').subtract(1, 'year').format('MM/DD/YYYY'),
				lastYearEndDate: moment().endOf('year').subtract(1, 'year').format('MM/DD/YYYY'),
				genderId: genderId,
				genderText: genderText,
				regionId: '-1',
				regionText: 'All',

			};
			getRegion(newState);
		},
		[genderId]
	);

	function getGenders(state){
		setGenderLoading(true);
		fetch(LOCAL_URL + 'businessDashBoardGenders', {
			method: 'POST',
			headers: {Authorization: JWT, Accept: 'application/json', 'Content-Type': 'application/json'},
			body: JSON.stringify(state)
		})
			.then((res) => res.json())
			.then((result) => {
				setGenderLoading(false);
				let options = [];
				// Set Items according to the requirement of AutoCompleteSelect component to render options.
				result.forEach((item) => {
					options.push({value: item.genderid, label: item.gendername});
				});
				setGenders(options); // Set types array in the state so it can call next component to render the options.
			});
		getRegion(state);
	}

	function getRegion(state){
		setRegionsLoading(true);
		fetch(LOCAL_URL + 'businessDashBoardRegions', {
			method: 'POST',
			headers: {Authorization: JWT, Accept: 'application/json', 'Content-Type': 'application/json'},
			body: JSON.stringify(state)
		})
			.then((res) => res.json())
			.then((result) => {
				setRegionsLoading(false);
				let options = [];
				// Set Items according to the requirement of AutoCompleteSelect component to render options.
				result.forEach((item) => {
					options.push({value: item.regionid, label: item.regionname});
				});
				setRegions(options); // Set types array in the state so it can call next component to render the options.
			});
	}

	return (
		<div className="slt-filters" id="mainfilters">
			<h3 className="filter-title">Filter</h3>
			<div>
				<span className="b-ftr-inr">
					<label>Gender</label>
					<AutoCompleteSelect
						list={Genders}
						loading={genderLoading}
						callBack={(a) => {
							setGenderId(a);
						}}

						callBackText={(a) => {
							setGenderText(a);
						}}
					/>
				</span>
			</div>
			<div>
				<span className="b-ftr-inr">
					<label>Region</label>
					<AutoCompleteSelect
						list={regions}
						loading={regionsLoading}
						callBack={(a) => {
							setRegionId(a);
						}}
						callBackText={(a) => {
							setRegionText(a)
						}}
					/>
				</span>
			</div>
		</div>
	);
};
export default BusinessBoardFilter;
