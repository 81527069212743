//Araj
//let URL = "http://172.16.1.85:80/api/";

//Farrukh
//let URL = "http://172.16.1.90:90/api/";
let URL = "https://api.fasreports.com/api/";

const config = {
	/******************DASHBOARD START**********************/
	BOOKING_BY_SALE_PERSON: URL + "GetAll?q=dashboardBookingbySP",
	BOOKING_BY_GENDER: URL + "GetAll?q=dashboardBookingByGender",
	BOOKING_BY_REGION: URL + "GetAll?q=dashboardBookingByRegion",
	BOOKING_BY_CUSTOMER: URL + "GetAll?q=dashboardBookingByCustomer",
	BOOKING_BY_FIT_STYLE: URL + "GetAll?q=dashboardBookingByFitStyle",
	BOOKING_BY_FIT_STYLE_TOP_SKU: URL + "GetAll?q=dashboardBookingByFitStyleTopSKU",
	BOOKING_BY_FIT_STYLE_TOP_SIZE: URL + "GetAll?q=dashboardBookingByFitStyleTopSize",

	SHIPPING_BY_SALE_PERSON: URL + "GetAll?q=dashboardShippedBySP",
	SHIPPED_BY_GENDER: URL + "GetAll?q=dashboardShippedByGender",
	SHIPPED_BY_CUSTOMER: URL + "GetAll?q=dashboardShippedByCustomer",
	SHIPPED_BY_REGION: URL + "GetAll?q=dashboardShippedByRegion",
	SHIPPED_BY_REGION_NEW: URL + "GetAll?q=dashboardShippedByRegionNew",
	SHIPPED_BY_FIT_STYLE: URL + "GetAll?q=dashboardShippedByFitStyle",

	PENDING_BY_SALE_PERSON: URL + "GetAll?q=dashboardPendingBySP",
	PENDING_BY_GENDER: URL + "GetAll?q=dashboardPendingByGender",
	PENDING_BY_CUSTOMER: URL + "GetAll?q=dashboardPendingByCustomer",
	PENDING_BY_REGION: URL + "GetAll?q=dashboardPendingByRegion",
	PENDING_BY_FIT_STYLE: URL + "GetAll?q=dashboardPendingByFitStyle",

	PENDING_POD_BY_SALE_PERSON: URL + "GetAll?q=dashboardPendingPODBySP",
	PENDING_POD_BY_REGION: URL + "GetAll?q=dashboardPendingPODByRegion",

	PENDING_POD: URL + "GetAll?q=dashboardPendingPOD",
	RETURN_BY_SALE_PERSON: URL + "GetAll?q=dashboardReturnBySP",
	RETURN_BY_GENDER: URL + "GetAll?q=dashboardReturnByGender",
	RETURN_BY_CUSTOMER: URL + "GetAll?q=dashboardReturnByCustomer",
	RETURN_BY_REGION: URL + "GetAll?q=dashboardReturnByRegion",
	RETURN_BY_FIT_STYLE: URL + "GetAll?q=dashboardReturnByFitStyle",

	NET_SHIPPED_BY_SALE_PERSON: URL + "GetAll?q=dashboardNetShippedBySP",
	NET_SHIPPED_BY_GENDER: URL + "GetAll?q=dashboardNetShippedByGender",
	NET_SHIPPED_BY_CUSTOMER: URL + "GetAll?q=dashboardNetShippedByCustomer",
	NET_SHIPPED_BY_REGION: URL + "GetAll?q=dashboardNetShippedByRegion",
	NET_SHIPPED_BY_FIT_STYLE: URL + "GetAll?q=dashboardNetShippedByFitStyle",
	NET_SHIPPED_BY_FIT_STYLE_TOP_SKU: URL + "GetAll?q=dashboardNetShippedByFitStyleTopSKU",
	NET_SHIPPED_BY_FIT_STYLE_TOP_SIZE: URL + "GetAll?q=dashboardNetShippedByFitStyleTopSize",

	NET_SHIPPED: URL + "GetAll?q=dashboardNetShipped",
	E_COMMERCE: URL + "GetAll?q=dashboardEcommerce",

	BUSINESS_LEADER_BOARD: URL + "GetAll?q=businessDashBoard",
	BUSINESS_LEADER_REGION_WISE: URL + "GetAll?q=businessDashBoardYearlyDetail",
	/******************DASHBOARD END**********************/
	GOOD_REQUIRED: URL + "GetAll?q=goodsRequired",
	COMPARE_SALE: URL + "GetAll?q=compareSales",
	GET_ALL_URL: URL + "GetAll?q=",
	PRODUCT_URL: URL + "GetAll?q=product",
	PRODUCT_UPC: URL + "GetAll?q=productUpc",
	LOGIN_URL: URL + "Login?q=Login",
	CUSTOMER_URL: URL + "GetAll?q=customer",
	SALE_ORDER_URL: URL + "GetAll?q=saleOrder",
	SALE_ORDER_PRODUCT_URL: URL + "GetAll?q=saleOrderProduct",
	PRODUCT_INVENTORY_URL: URL + "GetAll?q=productInventory",
	SKU_LOOKUP: URL + "GetAll?q=skuLookup",
	SKU_LOOKUPV2: URL + "GetAll?q=skuLookupV2",
	CUMULATIVE_OTS: URL + "GetAll?q=cumulativeOTS",
	OTS_PRODUCT_SUMMARY: URL + "GetAll?q=otsProductSummary",
	OTS_PRODUCT_DETAIL: URL + "GetAll?q=otsProductDetail",
	STOCK_LOCATION_WISE: URL + "GetAll?q=stockLocationWise",
	PRODUCTION_SUMMARY: URL + "GetAll?q=productionProductSummary",
	PRODUCTION_DETAIL: URL + "GetAll?q=productionDetail",
	PRODUCTION_PRODUCT_DETAIL: URL + "GetAll?q=productionProductDetail",
	STOCK_REPORT: URL + "GetAll?q=skuWiseStock",
	B_GRADE_STOCK_REPORT: URL + "GetAll?q=bGradeStock",
	SKU_DETAIL_URL: URL + "GetAll?q=skuDetail",
	PURCHASE_ORDER_URL: URL + "GetAll?q=purchaseOrder",
	BUSINESS_DASHBOARD_TEST: URL + "GetAll?q=businessDashBoardTest",
	//PDF URLS
	CUSTOMER_PDF_URL: URL + "getPdf?q=customerPdf",
	PRODUCT_INVENTORY_PDF_URL: URL + "getPdf?q=productInventoryPdf",
	STOCK_LOCSTION_WISE_PDF_URL: URL + "getPdf?q=productInventoryPdf",
	INVOICE_PDF_URL: URL + "getPdf?q=invoicePdf",
	COMMERCIAL_INVOICE_PDF_URL: URL + "getPdf?q=commercialInvoicePdf",
	SALE_ORDER_PRODUCT_PDF_URL: URL + "getPdf?q=saleOrderByProductPdf",
	SALE_ORDER_PDF_URL: URL + "getPdf?q=saleOrderByPOPdf",
	ORDER_BOOKING: URL + "GetAll?q=orderBooking",
	PRODUCT_PDF_URL: URL + "getPdf?q=productPdf",

	PURCHASE_ORDER_PDF_URL: URL + "getPdf?q=purchaseOrderPdf",
	GOODS_REQUIRED_PDF_URL: URL + "getPdf?q=goodsRequiredPdf",
	SALE_PERSON_PDF_URL: URL + "getPdf?q=saleComparePdf",
	//EMAIL URL
	PRODUCT_EMAIL_URL: URL + "getPdf?q=emailProduct",
};
export default config;
//https://ft1api.fasreport.com/api/
