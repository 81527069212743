import React, { useEffect, useState } from 'react';
import DateRange from '../../../common/dateRange';
import Header from './../../../header/header';
import Ecom from './../../../common/ecom';
import StockReportFilter from './stockReportFilter';
import StockReportStatus from './stockReportStatus';
import StockReportType from './stockReportType';
import SortsOn from './../../../common/sortsOn';
import Group from './../../../common/group';
import StockReportDialog from '../../../common/stockReportDialog';
import { Redirect } from 'react-router';


const sortsOn =  require('./../../../../data/stockReport/sortsOn.json');

const StockReport = () => {

    const [closeReport, setCloseReport] = useState(false);

    const [ stockReportType, setStockReportType ] = useState('');
    const [ stockReportDate, setStockReportDate ] = useState({});
    const [ stockReportStatus, setStockReportStatus ] = useState('');
    const [ stockReportEcom, setStockReportEcom ] = useState('');
    const [ stockReportSortsOn, setStockReportSortsOn ] = useState('');
    const [ stockReportGroup, setStockReportGroup ] = useState('');

    const [ display, setDisplay ] = useState(false);
    const [ selectedGenderId, setSelectedGenderId ] = useState('');
    const [ selectedTypeId, setSelectedTypeId ] = useState('');
    const [ selectedSkuId, setSelectedSkuId ] = useState('');
    const [ selectedFitId, setSelectedFitId ] = useState('');
    const [ selectedWashId, setSelectedWashId ] = useState('');
    const [ isGroup, setIsGroup ] = useState(true);
    const [ selectedCustomerTypeId, setSelectedCustomerTypeId ] = useState('');

    const [ reportUrl, setReportUrl ] = useState();
    const [ pdfUrl, setPDFUrl ] = useState();

    const stockReportTitles = [ 'S.No', 'SKU', 'Style', 'Wash', 'Season', 'Production', 'PK', 'Intransit', 'Bergen', 'Ecom', 'Total', 'Pending', 'OTS' ]
    const stockReportDataNames = [ 'sno', 'sku', 'stylename', 'washname', 'season', 'production', 'pk', 'intransit', 'bergen', 'bergenecom', 'total', 'pendingorder', 'ots' ];
    const stockReportHyperLinks = [ 1 ];

    function stockReportDateValue( s ) {
        setStockReportDate( s );
    }

    function stockReportStatusValue( s ) {
        setStockReportStatus( s );
    }

    function stockReportEcomValue( s ) {
        setStockReportEcom( s );
    }

    function stockReportTypeValue( s ) {
        setStockReportType( s );
    }

    function setFiltersState( s ) {
        const { fitId, genderId, skuId, typeId, washId } = s;
        setSelectedFitId( fitId );
        setSelectedGenderId( genderId );
        setSelectedSkuId( skuId );
        setSelectedTypeId( typeId );
        setSelectedWashId( washId );
    }

    function stockReportFilterValue( s ) {
        setFiltersState(s);
    }

    function stockReportGroupValue( groupId ) {
        // Change the group state so it can disabled or enabled sortOn by calling it.
        groupId == "1" ? setIsGroup( true ): setIsGroup( false ); // If id is 1 then set group to true else false.
        setStockReportGroup( groupId );
    }

    function stockReportsSortsOnValue( s ) {
        setStockReportSortsOn( s );
    }

    function checkNullOrAll( data ) {
        return data != null && data != "" && data != '-1';
    }

    function generateReport() {
        let url = `https://api.fasreport.com/sql/json/?status=${ stockReportStatus }`

        if( checkNullOrAll( selectedGenderId ) )
			url += `&gender=${ selectedGenderId }`

        if( checkNullOrAll( selectedTypeId ) )
			url += `&type=${ selectedTypeId }`

	    if( checkNullOrAll( selectedFitId ) )
			url += `&fit=${selectedFitId}`				

		if( checkNullOrAll( selectedWashId ) )
			url += `&wash=${selectedWashId}`		

		if( checkNullOrAll( selectedSkuId ) )
			url += `&sku=${selectedSkuId}`					

        if( checkNullOrAll( stockReportDate.fromDate ) )
			url += `&fromdate=${ stockReportDate.fromDate }`

        if( checkNullOrAll( stockReportDate.toDate ) )
			url += `&todate=${ stockReportDate.toDate }`

		url += "&download=0"
		//var win = window.open(url, '_blank');
		//win.focus();
        setPDFUrl( url );
    }

    function search() {
        generateReport()
    }

    function showTable() {
        let url = `https://api.fasreport.com/sql/json/?status=${ stockReportStatus }`

        if( checkNullOrAll( selectedGenderId ) )
			url += `&gender=${ selectedGenderId }`

        if( checkNullOrAll( selectedTypeId ) )
			url += `&type=${ selectedTypeId }`

	    if( checkNullOrAll( selectedFitId ) )
			url += `&fit=${selectedFitId}`				

		if( checkNullOrAll( selectedWashId ) )
			url += `&wash=${selectedWashId}`		

		if( checkNullOrAll( selectedSkuId ) )
			url += `&sku=${selectedSkuId}`					

        if( checkNullOrAll( stockReportDate.fromDate ) )
			url += `&fromdate=${ stockReportDate.fromDate }`

        if( checkNullOrAll( stockReportDate.toDate ) )
			url += `&todate=${ stockReportDate.toDate }`

		url += "&json=1"
        search();
        setReportUrl( url );
        setDisplay(true);
    }

    return(
        <div>
            <Header/>
            <div className="main-content">
                
                <div className="report-container group">
                    <div className="report-container-header">
                        <h1>Stock Report</h1>
                        <button id="close-report-btn" onClick={ () => setCloseReport(true) } >Close</button>
                        { closeReport == true ? <Redirect to="/dashboard" />: null }
                    </div>
                    <div className="report-container-inner">
                        <div className="r-left">
                            <DateRange dateData={ stockReportDateValue }/>
                            <StockReportStatus statusData={ stockReportStatusValue }/>
                            <Ecom ecomData={ stockReportEcomValue }/>
                            <StockReportFilter filterData={ stockReportFilterValue }/>
                        </div>
                        <div className="r-right">
                            <StockReportType reportTypeData={ stockReportTypeValue }/>
                            <Group groupData={ stockReportGroupValue }/>
                            <SortsOn sortsOn = { sortsOn } sortsOnData={ stockReportsSortsOnValue} isGroup = { isGroup }/>
                            <button className="report-submit-btn" id="show" onClick={ showTable } >SEARCH</button>
                        </div>
                    </div>
                </div>
                <StockReportDialog 
                    display={ display }     
                    callBack={ () => { setDisplay(false) } } 
                    url= { reportUrl }
                    pdfUrl = { pdfUrl }
                    titles={ stockReportTitles }
                    dataNames={ stockReportDataNames }
                    hyperLinks={ stockReportHyperLinks }
                />
            </div>
        </div>
    );
}

export default StockReport;