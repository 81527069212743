import React, { useEffect, useState } from 'react';
import config from './../../config/config';
const LOCAL_URL = config.GET_ALL_URL;
let JWT;

const ProductStatus = ( props ) => {

    const [ status, setStatus ] = useState([]);

    function returnStatus(filter) {
        props.statusData( filter.value );
    }

    useEffect(() => {

        JWT = localStorage.getItem('access_token');
        getproductStatus();
    },[])

    async function getproductStatus() {
        let url = props.url ? props.url:"productStatus"
        const data = await fetch( LOCAL_URL +url, { method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json' }});
        const productStatus = await data.json();
        const productStatusOptions = [];
        // Set Fits according to the requirement of AutoCompleteSelect component to render options.
        //Remove All option
        // if (!props.all) productStatus.pop();
        productStatus.map(item => {
            productStatusOptions.push( { value: item.productstatusid, label: item.productstatusname } );
        });
        setStatus( productStatusOptions ); // Set sku array in the state so it can call next component to render the options.
        returnStatus(productStatusOptions[0]);
        if (document.getElementById('genreport')) {
            document.getElementById('genreport').disabled = false;
            document.getElementById('genreport').classList.remove('disabled');
        }
    }

    function renderFilter() {
        return status.map( ( filter, index ) => {
            if( index == 3 ) return null;
            return (
                <div key={ filter.value }>
                    <span className="st-btns">
                        <label><input  type="radio" name="status" id={ filter.id } onChange={ returnStatus.bind( this, filter ) } defaultChecked={ index === 0 } /><span>{ filter.label }</span></label>
                    </span>                  
                </div>
            );
        })
    } 

    return(
        <div className="status-filter">
            <h3 className="filter-title">Product Status</h3>
            <div className="status-buttons">
                <div className="btns-main">
                    { renderFilter() }
                </div>
            </div>
        </div>
    );
}
export default ProductStatus;