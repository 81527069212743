import React, {useState} from 'react';
import Input from '../../common/input';
import useDidMountEffect from '../../common/useDidMountEffect';

let JWT;

const DetailReportFilter = (props) => {
	const [sku, setSkuId] = useState('-1');

	function getNewState(){
		return {
			sku: sku
		};
	}
	useState(() => {
		JWT = localStorage.getItem('access_token');
	}, []);
	/**
     * Send back data to the parent component i.e ProductReport whenever selected option changes.
     */
	useDidMountEffect(
		() => {
			const newState = getNewState();
			props.filterData(newState);
		},
		[sku]
	);
	return (
		<div className="slt-filters" id="mainfilters">
			<h3 className="filter-title">Filter</h3>
			<div>
				<span className="b-ftr-inr">
					<label>SKU</label>
					<Input
						type="number"
						name="sku"
						callBack={(a) => {
							setSkuId(a);
						}}
					/>
				</span>
			</div>
		</div>
	);
};
export default DetailReportFilter;