import React, { useEffect, useState } from 'react';

const SortOrder = (props) => {

    const groupOn = props.groupOn; // SortOn array that passed by its parent component.
    const [Order, setOrder] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);

    useEffect(() => {
        props.groupOnData(Order);
    }, [Order]);

    useEffect(() => {
        //ByDefault OTS Checked
        document.getElementById("checkboxOrderots").checked = true;
        props.groupOnData(selectedGroup);
    }, [selectedGroup]);


    function unCheckAllExceptForSelectedOne(selectedOne) {   
        groupOn.forEach(filter => {
            if (filter.id != selectedOne) {
                document.getElementById("checkboxOrder" + filter.id).checked = false;
            }
        });
    }

    function addToSelectedArray(selectedOption) {
     
        if (document.getElementById("checkboxOrder" + selectedOption.id).checked) {
           unCheckAllExceptForSelectedOne(selectedOption.id)
            var array = selectedGroup;
            array.pop()// AJ
            array.push(selectedOption.id);
            setSelectedGroup(array);

        } else {
            var array = selectedGroup;
            const idx = array.indexOf(selectedOption.id);
            if (idx != -1) {
                array.splice(idx, 1)
                setSelectedGroup(array);
            }
        }
        props.groupOnData(array);
    }

    /**
     * Function that will map options array object to the spans of options. 
     */
    function renderSortOrder() {
        return groupOn.map(filter => {
            return (<>
                <span key={"span"+filter.id} className="st-btns-right">
                    <label key={"lable"+filter.id} id={"label" + filter.id}>
                        <input key={"input"+filter.id}   id={"checkboxOrder" + filter.id} type="checkbox"
//                            checked={filter.id == 'ots' ? true : false}
                            name="orderon" onChange={addToSelectedArray.bind(this, filter)} />
                        <span>{filter.value}</span>
                    </label>
                </span>
                </>
            );
        });
    }

    return (
        <div key={props.title}  className="report-filter-right" id="report-filter">
            <h3 className="filter-title">{props.title ? props.title : 'Sort On'}</h3>            
            <div className="btns-main-right">
                {renderSortOrder()}
            </div>
        </div>
    );
}

export default SortOrder;