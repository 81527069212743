import React, { useState } from 'react';
import Header from '../../../header/header';
import SortsOn from '../../../common/sortsOn';
import CustomerReportFilter from './customerReportFilter';
import ProductStatus from '../../../common/productStatus'
import Active from '../../../common/active';
import config from '../../../../config/config';
import GroupOn from '../../../common/groupOn';
import Group from '../../../common/group';
import Ecom from '../../../common/ecom';
import ReportDialog from '../../../common/reportDialog';
import { Redirect } from 'react-router';


const sortsOn = require('../../../../data/productReport/sortsOn.json');
const groupsOn = require('../../../../data/customerReport/groupOn.json');


const URL = config.CUSTOMER_URL;
const PRODUCT_STATUS = "&productStatus=";
const IS_INACTIVE ="&isInActive=";
const IS_ECOM = "&isEcom=";
const PDF_URL = config.CUSTOMER_PDF_URL;

const CustomerReport = () => {


    const [ stockReportSortsOn, setStockReportSortsOn ] = useState('');

    const [ groupOnArray, setGroupOnArray ] = useState([]);
    
    const [closeReport, setCloseReport] = useState(false);

    // Report filters
    const [ isInactive, setIsInActive ] = useState("F");
    const [ selectedProductStatus, setSelectedProductStatus ] = useState('1');
    const [ searchState, setSearchState ] = useState(false);
    const [ formState, setFormState ] = useState({});

    const [ display, setDisplay ] = useState(false);
    const [ reportUrl, setReportUrl ] = useState();
    const [ pdfUrl, setPDFUrl ] = useState();
    const stockReportHyperLinks = [  ];

    const [ isGroup, setIsGroup ] = useState(true);
    const [ isEcom, setIsEcom ] = useState(true);
    const [ stockReportGroup, setStockReportGroup ] = useState('');

    function showTable() {
        let selectedArray = groupOnArray;
        let url = URL
        let pdfUrl = PDF_URL;
        setFormState( {...formState, "groupBy": selectedArray } )
        url += IS_INACTIVE + isInactive;
        pdfUrl += IS_INACTIVE + isInactive;
        setReportUrl( url );
        setPDFUrl(pdfUrl);
        setDisplay(true);
    }

    function stockReportGroupValue( groupId ) {
        // Change the group state so it can disabled or enabled sortOn by calling it.
        groupId == "1" ? setIsGroup( false ): setIsGroup( true ); // If id is 1 then set group to true else false.
        setStockReportGroup( groupId );
    }

    return(
        <div>
            <Header/>
            <div className="main-content">
                <div className="report-container group">
                    <div className="report-container-header">
                        <h1>Customer Report</h1>
                        <button id="close-report-btn" onClick={ () => setCloseReport(true) } >Close</button>
                        { closeReport == true ? <Redirect to="/dashboard" />: null }
                    </div>
                    <div className="report-container-inner">
                        <div className="r-left">
                            <Active sendStatus={ (a) => { a == 1 ? setIsInActive('F'): setIsInActive('T') }  } />
                            <Ecom ecomData={ (a) => setIsEcom(a) }/>
                            <CustomerReportFilter
                                filterData={ (state) => { setFormState(state) } } 
                                productStatus={ selectedProductStatus } 
                                isInActive={ isInactive } 
                                searchState={ searchState }
                                isEcom={ isEcom }
                            />
                        </div>
                        <div className="r-right">
                            <Group groupData={ stockReportGroupValue } disabled={false}/>
                            {/* <SortsOn sortsOn = { sortsOn } sortsOnData={ (id) => { setStockReportSortsOn( id ) }} isGroup={ false } /> */}
                            <GroupOn groupData={ stockReportGroupValue } disabled={ isGroup } groupOn = { groupsOn } groupOnData={ (arr => setGroupOnArray( arr )) } />
                            <button className="report-submit-btn" id="genreport" onClick={ showTable }>SEARCH</button>
                        </div>
                    </div>
                </div>
                <ReportDialog 
                    display={ display }     
                    callBack={ () => { setDisplay(false) } } 
                    url= { reportUrl }
                    pdfUrl = { pdfUrl }
                    hyperLinks={ stockReportHyperLinks }
                    body={ formState }
                />
            </div>
        </div>
    );
}

export default CustomerReport;