import React from 'react';
const Group = ( props ) => {

    /**
     * @param event // Event object.
     * Send back id of the selected radio button to its parent component. 
     */
    function returnData(event) {
        if(props.setOrder)props.setOrder(event.target.id);
        props.groupData( event.target.id );
    }

    return(
        <div className="report-filter-right">
            <h3 className="filter-title">{props.title ? props.title : 'Group'}</h3>
            <div className="btns-main-right">
                <span className="st-btns-right-2">
                    <label><input id={props.id ? props.id[1] : '1'} type="radio" name={props.name ? props.name : 'isgroup'} onChange={returnData} disabled={props.disabled} /><span>{ props.label1 ? props.label1:'Yes' }</span></label>
                </span>
                <span className="st-btns-right-2">
                    <label><input id={props.id ? props.id[0]:'2'} type="radio" name={props.name ? props.name:'isgroup' } onChange={ returnData } defaultChecked="true"/><span>{ props.label1 ? props.label2:'No' }</span></label>
                </span>
            </div>
        </div>
    );
}
export default Group;