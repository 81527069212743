import React, { useEffect, useState } from 'react';

const Input = (props) => {

 
    const [input, setInput] = useState([]);

    function clear() {
        setInput('');
        props.callBack('-1');
    }

       // Callback to return the value of selected item in select.
    function onChangeValue(input) {

        if (input.target.value == '') {
            clear();
        }
        else {
            setInput(input.target.value);
            props.callBack(input.target.value);
        }
    }
    /**
     * Function that will map options array object to the spans of options. 
     */ 
    return (
        <>
        <input type={props.type} name={props.name} autoComplete="nope" value={ input }  onChange={ onChangeValue }  />
        <button className="auto-complete-text-btn"   onClick={ clear }  >CLEAR</button>
        </>
    );
}
export default Input;