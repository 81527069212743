import React, {useState} from 'react';
import Header from '../../../header/header';
import config from '../../../../config/config';
import BusinessBoardReportFilter from './businessBoardFilter';
import ReportDialog from '../../../common/reportDialog';
import {Redirect} from 'react-router';

const BusinessBoardTest = (props) => {
	const [closeReport, setCloseReport] = useState(false);
	const [formState, setFormState] = useState({});
	const [display, setDisplay] = useState(false);
	const [reportUrl, setReportUrl] = useState();
	const [pdfUrl, setPDFUrl] = useState();

	function showTable() {
		let url = config.BUSINESS_DASHBOARD_TEST;
		let pdfUrl = config.SALE_PERSON_PDF_URL;
		setFormState({ ...formState });
		setReportUrl(url);
		setPDFUrl(pdfUrl);
		setDisplay(true);
	}

	return (
		<div>
			<Header />
			<div className="main-content business-dashboard">
				<div className="report-container group">
					<div className="report-container-header">
						<h1>Business Dashboard</h1>
						<button id="close-report-btn" onClick={() => setCloseReport(true)}>
							Close
						</button>
						{ closeReport == true ? <Redirect to="/dashboard" /> :null }
					</div>
					<div className="report-container-inner">
						<div className="r-left">
							<BusinessBoardReportFilter
								filterData={(state) => {
									setFormState(state);
								}}
							/>
							<button
								style={{width: '50%', margin: '0 auto', float: 'none'}}
								className="report-submit-btn"
								id="genreport"
								onClick={showTable}
								id="genreport"
							>
								SEARCH
							</button>
                        </div>

					</div>
				</div>
				<ReportDialog
					tableHeight={630}
					TableClassName="business-dashboard business-dashboard-test"
					
					defaultPageSize={100}
					display={display}
					callBack={() => {
						setDisplay(false);
					}}
					url={reportUrl}
					pdfUrl=''
					body={formState}
				/>{' '}
			</div>
		</div>
	);
};
export default BusinessBoardTest;