import React, { useEffect, useState, useLayoutEffect } from 'react';
import AutoCompleteSelect from '../../../common/autoCompleteSelect';
import Input from '../../../common/input';
import useDidMountEffect from '../../../common/useDidMountEffect';
import config from '.././../../../config/config';
import moment from 'moment';


const LOCAL_URL = config.GET_ALL_URL;
const PRODUCT_STATUS = "&productStatus=";
const IS_INACTIVE = "&isInActive="
let JWT;

const DetailReportFilter = ( props ) => {

    const [ otherThanFirst, setOtherThanFirst ] = useState(true)

    // Selected Ids from the options list in AutoCompleteSelect.

    const [genderId, setGenderId] = useState('-1'); // You can set useState(Your default value to be selected in list).
    const [seasonId, setSeasonId] = useState('-1'); // You can set useState(Your default value to be selected in list).
    const [currentSeasonId, setCurrentSeasonId] = useState('-1'); // You can set useState(Your default value to be selected in list).
    const [ genderCategoryId, setGenderCategoryId ] = useState('-1');
    const [ washId, setWashId ] = useState('-1');
    const [ styleId, setStyleId ] = useState('-1');
    const [ skuId, setSkuId ] = useState('-1');

    // Array that have data to sent to AutoCompleteSelect component to render options.

    const [genders, setGenders] = useState([]);
    const [season, setSeason] = useState([]);
    const [currentSeason, setCurrentSeason] = useState([]);
     const [ genderCategories, setGenderCategories ] = useState([]);
     const [ styles, setStyles ] = useState([]);
    const [ wash, setWash ] = useState([]);
    const [skus, setSkus] = useState([]);

    // Loading booleans to be set on AutoComplete.
    const [gendersLoading, setGendersLoading] = useState(true);
    const [seasonLoading, setSeasonLoading] = useState(true);
    const [currentSeasonLoading, setCurrentSeasonLoading] = useState(true);
    const [ genderCategoriesLoading, setGenderCategoriesLoading ] = useState(true);
    const [ stylesLoading, setStylesLoading ] = useState(true);
    const [ washLoading, setWashLoading ] = useState(true);
    const [skusLoading, setSkusLoading] = useState(true);

    function getNewState() {
        return { 
            genderId: genderId,
            seasonId: seasonId,
            currentSeasonId: currentSeasonId,
            genderCategoryId: genderCategoryId, 
            washId:washId,
            styleId: styleId,
            skuId:skuId,
        };
    }

    useState( () => {
       JWT = localStorage.getItem('access_token');
    },[]);
  
    /**
     * Send back data to the parent component i.e ProductReport whenever selected option changes.
     */
    useDidMountEffect( () => {
        const newState = getNewState();
        props.filterData(newState);
    },[genderId, seasonId,currentSeasonId,genderCategoryId,styleId, skuId,washId]);

    
    useLayoutEffect( () => {
        if (otherThanFirst) {
            const newState = {
                genderId: '-1',
                seasonId: '-1',
                currentSeasonId: '-1',
                genderCategoryId: '-1', 
                styleId: '-1',  
                washId:'-1',
                skuId: skuId,

            }
            props.filterData(newState);    
            getGenders(newState);
            
        }
    },[props.productStatus, props.isInActive]);
    

    useDidMountEffect( () => {
        const newState = {
            genderId: genderId,
            seasonId:'-1',
            currentSeasonId: '-1',
            genderCategoryId: '-1',
            styleId: '-1',  
            washId: '-1',
            skuId: '-1'
        }
        getSeason(newState);
    }, [genderId]);

    useDidMountEffect( () => {
        const newState = {
            genderId: genderId,
            seasonId:seasonId,
            currentSeasonId: '-1',
            genderCategoryId: '-1',
            styleId: '-1',  
            washId: '-1',
            skuId: '-1'
        }
        getCurrentSeason(newState);
    }, [seasonId]);

    useDidMountEffect( () => {
        const newState = {
            genderId: genderId,
            seasonId:seasonId,
            currentSeasonId: currentSeasonId,
            genderCategoryId: '-1',
            styleId: '-1',  
            washId: '-1',
            skuId: '-1'
        }
        getGendersCategory(newState);
    }, [currentSeasonId,]);

    useDidMountEffect( () => {
        const newState = {
            genderId: genderId,
            seasonId: seasonId,
            currentSeasonId: currentSeasonId,
            genderCategoryId: genderCategoryId, 
            styleId: '-1',
            washId:'-1',
            skuId: '-1'
        }
      getStyle(newState);
    }, [genderCategoryId]);
   


    useDidMountEffect( () => {
        const newState = {
            genderId: genderId,
            seasonId: seasonId,
            currentSeasonId: currentSeasonId,
            genderCategoryId: genderCategoryId, 
            styleId: styleId, 
            washId:'-1',
            skuId: '-1'
        }
        getWash(newState);
    }, [styleId]);


    useDidMountEffect( () => {
        const newState = {
            genderId: genderId,
            seasonId: seasonId,
            currentSeasonId: currentSeasonId,
            genderCategoryId: genderCategoryId, 
            styleId: styleId, 
            washId:washId,
            skuId: '-1',
        }
        getSKU(newState);
    }, [washId]);

    function getDate() {
        return "&fromDate=" + moment(props.date.fromDate).format('MM/DD/YYYY') + "&toDate=" + moment(props.date.toDate).format('MM/DD/YYYY');
    }
   
    function getGenders(state) {
        setGendersLoading(true);
        fetch( LOCAL_URL + "productionInventoryGenders" + getDate() + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive ,{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setGendersLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.genderid, label: item.gendername } );
            });
            setGenders( options ); // Set types array in the state so it can call next component to render the options.
        });
        getSeason(state);
    }


    
    function getSeason(state) {
        setSeasonLoading(true);
        fetch( LOCAL_URL + "productionInventorySeasons" + getDate() + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive ,{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setSeasonLoading(false);
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.seasonid, label: item.seasonname } );
            });
            setSeason( options ); // Set types array in the state so it can call next component to render the options.
        });
        getCurrentSeason(state);
    }

    function getCurrentSeason(state) {
        setCurrentSeasonLoading(true);
        fetch( LOCAL_URL + "productionInventoryCurrentSeason" + getDate() + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive ,{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setCurrentSeasonLoading(false);
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.currentseasonid, label: item.currentseasonname } );
            });
            setCurrentSeason( options ); // Set types array in the state so it can call next component to render the options.
        });
        getGendersCategory(state);
    }

    function getGendersCategory(state) {
        setGenderCategoriesLoading(true);
        fetch( LOCAL_URL + "productionInventoryGendersCategory" + getDate() + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive ,{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( genderCategories => {
            setGenderCategoriesLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            genderCategories.forEach( item => {
                options.push( { value: item.gendercategoryid, label: item.gendercategoryname } );
            });
            setGenderCategories( options ); // Set types array in the state so it can call next component to render the options.
        });       
        getStyle(state);
    }

    function getStyle(state) {
        setStylesLoading(true);
        fetch( LOCAL_URL + "productionInventoryStyle" + getDate() + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive ,{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( fits => {
            setStylesLoading( false );
            let options = [];
            // Set Fits according to the requirement of AutoCompleteSelect component to render options.
            fits.forEach( item => {
                options.push( { value: item.styleid, label: item.stylename } )
            });
            setStyles( options ); // Set fits array in the state so it can call next component to render the options.
        });   
        getWash(state);
    }


    function getWash(state) {
        setWashLoading(true);
        fetch( LOCAL_URL + "productionInventoryWash" + getDate() + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive ,{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( wash => {
            setWashLoading( false );
            let options = [];
            // Set Fits according to the requirement of AutoCompleteSelect component to render options.
            wash.forEach(item => {
               
                options.push( { value: item.washid, label: item.washname } )
            });
            setWash( options ); // Set fits array in the state so it can call next component to render the options.
        });        
       // getSKU(state);
    }

    function getSKU(state) {
        setSkusLoading(true);
        fetch( LOCAL_URL + "productionInventorySku" + getDate() + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive ,{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( skus => {
            setSkusLoading(false);
            const options = [];
            // Set Fits according to the requirement of AutoCompleteSelect component to render options.
            skus.map(item => {
                options.push( { value: item.skuid, label: item.skuname } );
            });
            setSkus( options ); // Set sku array in the state so it can call next component to render the options.
        });        
    }

    return(
        <div className="slt-filters" id="mainfilters">
            <h3 className="filter-title">Filter</h3>
            <div>
                <span className="b-ftr-inr">
                    <label>Gender</label>
                    <AutoCompleteSelect list={ genders } loading={ gendersLoading } callBack={ (a) => { setGenderId(a) } }/>
                </span>
            </div>
            <div className="hidden">
                <span className="b-ftr-inr">
                    <label>Season</label>
                    <AutoCompleteSelect list={season} loading={seasonLoading} callBack={(a) => { setSeasonId(a) }}/>
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Current Season</label>
                    <AutoCompleteSelect list={ currentSeason } loading={ currentSeasonLoading } callBack={ (a) => { setCurrentSeasonId(a) } } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Gender Category</label>
                    <AutoCompleteSelect list={ genderCategories } loading={ genderCategoriesLoading } callBack={ (a) => { setGenderCategoryId(a) } } />
                </span>
            </div>
           
            <div>
                <span className="b-ftr-inr">
                    <label>Style</label>
                    <AutoCompleteSelect list={ styles } loading={ stylesLoading } callBack={ (a) => { setStyleId(a) } }/>
                </span>
            </div>


            <div>
                <span className="b-ftr-inr">
                    <label>Wash</label>
                    <AutoCompleteSelect list={ wash } loading={ washLoading } callBack={ (a) => { setWashId(a) } }/>            
                </span>
            </div>
            
            <div>
                <span className="b-ftr-inr">
                    <label>SKU</label>                 
                    <Input type='number' name='skuId' callBack={(a) => {setSkuId(a);}} />
                </span>
            </div>
        </div>
    );
}

export default DetailReportFilter;