import React, {useEffect, useState} from "react";
import Header from "./../../../header/header";
import config from "./../../../../config/config";
import {NavLink} from "react-router-dom";
import {Redirect} from "react-router";
import moment from "moment";
import Table from "./../../../common/HTMLTable";
import {getExcelStandard} from "../../../../helper/excel";
import {
  montFromDate,
  monthToDate,
  yearFromDate,
  yearToDate,
} from "../../../../helper/date";
let JWT;
let fromDate;
let toDate;

const ShippedByCustomer = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [closeReport, setCloseReport] = useState(false);
  const [reportName, setReportName] = useState(props.reportName);
  const [reportType, setReportType] = useState(props.reportType);
  const [reportNumber, setReportNumber] = useState(props.reportNumber);
  const [genderId, setGenderId] = useState(props.genderId);
  const [formState, setFormState] = useState({genderId: genderId});
  const URL = config.SHIPPED_BY_CUSTOMER;

  useEffect(() => {
    JWT = localStorage.getItem("access_token");
    if (props.reportType) fetchData();
  }, [props]);

  async function fetchData() {
    setLoading(true);
    fromDate = reportType == "ytd" ? yearFromDate() : montFromDate();
    toDate = reportType == "ytd" ? yearToDate() : monthToDate();
    let url = URL;

    url +=
      "&fromDate=" +
      moment(fromDate).format("MM/DD/YYYY") +
      "&toDate=" +
      moment(toDate).format("MM/DD/YYYY");

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: JWT,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formState),
    });

    const data1 = await response.json();
    data1.reportName = "Booking Leaderboard - MTD";
    setData(data1);
    setLoading(false);
  }

  return (
    <div>
      <Header />
      <div className="dash-body">
        <div style={{paddingLeft: "0%", paddingRight: "0%", marginTop: "-2%"}}>
          <div className="report-result-head">
            <h2>{reportName}</h2> <br />
            <h2>{reportNumber}</h2>
            <div className="navigation-area">
              <NavLink
                activeClassName="selected"
                className="link-react"
                to="shipped-by-customer-ytd"
              >
                ALL-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="shipped-by-customer-mtd"
              >
                ALL-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="shipped-by-customer-women-ytd"
              >
                WOMEN-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="shipped-by-customer-women-mtd"
              >
                WOMEN-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="shipped-by-customer-men-ytd"
              >
                MEN-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="shipped-by-customer-men-mtd"
              >
                MEN-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="shipped-by-customer-kids-ytd"
              >
                KIDS-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="shipped-by-customer-kids-mtd"
              >
                KIDS-MTD
              </NavLink>
              <NavLink
                activeClassName="selected"
                className="link-react"
                to="shipped-by-customer-pets-ytd"
              >
                PET-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="shipped-by-customer-pets-mtd"
              >
                PET-MTD
              </NavLink>
            </div>
            {props.reportType ? (
              <div className="report-result-right-top">
                <button
                  id="close-dialog-btn"
                  onClick={() => setCloseReport(true)}
                >
                  Close
                </button>
                <button
                  id="download-excel-button"
                  onClick={() => {
                    getExcelStandard(data);
                  }}
                >
                  Download EXCEL
                </button>
                {closeReport == true ? <Redirect to="/dashboard" /> : null}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="report-data">
            {props.reportType ? (
              <Table display={true} data={data} loading={loading} />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ShippedByCustomer;
