import React, { useEffect } from 'react';

const SortsOn = ( props ) => {

    const sortsOn = props.sortsOn; // SortOn array that passed by its parent component.
    
    /**
     * @param selectedOption // Object that is selected by the user.
     * Send back the id of the selected option to its parent component.
     */
    function returnSortsOn( selectedOption ) {
        props.sortsOnData( selectedOption.id );
    }

    useEffect( () => {
        disabledLabel();
    },[ props.isGroup ]);


    function disabledLabel( ) {
        if( document != null && document.getElementById( "report-filter" ) != null ) {
            if( props.isGroup && !document.getElementById( "report-filter" ).classList.contains( "group-label" ) ) {
                document.getElementById( "report-filter" ).classList.add( "group-label" );
            } else {
                document.getElementById( "report-filter" ).classList.remove( "group-label" );       
            }
        }   
    }

    /**
     * Function that will map options array object to the spans of options. 
     */
    function rendersortsOn() {
        return sortsOn.map( filter => {
            return(
                <span key={ filter.id } className="st-btns-right">
                    <label id={ "label"+filter.id }><input type="radio" name="sorton" onChange={ returnSortsOn.bind( this, filter ) } disabled={ props.isGroup }/><span>{ filter.value }</span></label>
                </span>
            );
        });
    }

    return(
        <div className="report-filter-right" id="report-filter">
            <h3 className="filter-title">Sort On</h3>
            <div className="btns-main-right">
                { rendersortsOn() }
            </div>
        </div>
    );
}

export default SortsOn;