import React from 'react';
import {useState} from 'react';
import {Redirect} from 'react-router-dom';
import config from './../../config/config';

const USERNAME = 'username';
const PASSWORD = 'pass';
const INVALID_LOGIN = 'Invalid login';
const FASCOM = 'fascom';

const Login = () => {
	const [shouldLogin, setShouldLogin] = useState(false);

	async function onSubmitBtn(){
		const name = document.getElementById(USERNAME).value;
		const password = document.getElementById(PASSWORD).value;
		const body = {userName: name, password: password};
		const data = await fetch(config.LOGIN_URL, {
			method: 'POST',
			headers: {Accept: 'application/json', 'Content-Type': 'application/json'},
			body: JSON.stringify(body)
		});
		const res = await data.json();
		if (data.status == 200) {
			localStorage.setItem('access_token', res.token);
			localStorage.setItem('current_user', res.user);
			setShouldLogin(true);
		}
		else {
			alert(INVALID_LOGIN);
		}
	}

	return (
		<div className="limiter">
			{
				shouldLogin ? <Redirect to="/dashboard" /> :
				null}
			<div className="container-login100">
				<div className="wrap-login100">
					<form className="login100-form validate-form">
						<span className="f-logo-login">Fascom</span>
						<div className="fas-login-desc">Fascom Login</div>
						<div className="input-login-email" data-validate="Enter username">
							<input className="input100" placeholder="Email" type="text" name="username" id="username" />
						</div>
						<div className="input-login-pass" data-validate="Enter password">
							<input className="input100" placeholder="Password" type="password" name="pass" id="pass" />
						</div>
						<div className="rem-me">
							<input className="rem-me-check" id="rem-check" type="checkbox" />
							<label className="rem-me-label" htmlFor="rem-check">
								Remember Me
							</label>
						</div>
						<div className="container-login100-form-btn">
							<button className="login100-form-btn" id="btnlogin" type="button" onClick={onSubmitBtn}>
								Log In
							</button>
						</div>
						<div className="forget-pass link">
							<a href="#">Forget your password?</a>
						</div>
					</form>
					<div className="login-footer">
						<div className="link">
							By clicking on the Log In button, you understand and agree to{' '}
							<a className="login-term-link" href="#">
								Fascom Terms of Use
							</a>{' '}
							and{' '}
							<a className="login-term-link" href="#">
								Fascom Privacy Policy
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;