import React, { useEffect, useState } from 'react';
import AutoCompleteSelect from '../../../common/autoCompleteSelect';

// https://regencymw.herokuapp.com/

const GET_TYPE_URL = 'https://regencymw.herokuapp.com/sql/gettype?gender=';
const GET_FIT_URL = 'https://regencymw.herokuapp.com/sql/getfit?gender=';
const GET_WASH_URL = 'https://regencymw.herokuapp.com/sql/getwash?gender=';
const GET_SKU_URL = 'https://regencymw.herokuapp.com/sql/getsku?gender=';

const genders = require('./../../../../data/stockReport/genders.json');


const StockReportFilter = ( props ) => {

    // Selected Ids from the options list in AutoCompleteSelect.
    const [ genderId, setGenderId ] = useState(''); // You can set useState(Your default value to be selected in list).
    const [ typeId, setTypeId ] = useState('');
    const [ washId, setWashId ] = useState('');
    const [ fitId, setFitId ] = useState('');
    const [ skuId, setSkuId ] = useState('');
    const [ customerId, setCustomerId ] = useState('');

    // Array that will have data to sent to AutoCompleteSelect component to render options.
    const [ types, setTypes ] = useState([]);
    const [ fits, setFits ] = useState([]);
    const [ washes, setWashes ] = useState([]);
    const [ skus, setSkus ] = useState([]);

    const [ categoryLoading, setCategoryLoading ] = useState(false);
    const [ fitLoading, setFitLoading ] = useState(false);
    const [ washLoading, setWashLoading ] = useState(false);
    const [ skuLoading, setSkuLoading ] = useState(false);
    /**
     * Send back data to the parent component i.e StockReport whenever selected option changes.
     */
    useEffect( () => {
        props.filterData( { genderId: genderId, typeId: typeId, skuId: skuId, fitId: fitId, washId: washId } )
    },[ genderId, typeId, washId, fitId, skuId ]);

    /**
     * Get Types on first time when component render. You can comment this if you dont want to load types on load automatically.
     */
    useEffect( () => {
        getTypes( genders[0].value )
    },[]);

    /**
     * 
     * @param genderId // Selected Gender ID we get from AutoCompleteSelect component.
     * Get the Types correspoding to the selected gender id.
     */
    async function getTypes( genderId ) {
        setGenderId( genderId ); // Set selected gender id to state so it will callback to parent component i.e StockReport with useEffect.
        setCategoryLoading( true );
        const data = await fetch( GET_TYPE_URL + genderId );
        const types = await data.json();
        setCategoryLoading( false );
        let itemOptions = [];
        // Set Items according to the requirement of AutoCompleteSelect component to render options.
        types.items.forEach( item => {
            itemOptions.push( { value: item.categorytypeid, label: item.categorytype } );
        });
        setTypes( itemOptions ); // Set types array in the state so it can call next component to render the options.
        setTypeId( '-1' ); // Set first element of the options in the state so it can callback to set in the parent component as a default selected option.
    }
    
    /**
     * 
     * @param typeId // Selected Type ID we get from AutoCompleteSelect component.
     * Get the Fits correspoding to the selected gender id and type id.
     */
    async function getFit( typeId ) {
        setTypeId( typeId ); // Set selected type id to state so it will callback to parent component i.e StockReport with useEffect.
        setFitLoading( true );
        const data = await fetch( `${ GET_FIT_URL }${ genderId }&type=${ typeId }` );
        const fits = await data.json();
        setFitLoading( false );
        let fitOptions = [];
        // Set Fits according to the requirement of AutoCompleteSelect component to render options.
        fits.items.forEach( item => {
            fitOptions.push( { value: item.styleid, label: item.style } )
        });
        setFits( fitOptions ); // Set fits array in the state so it can call next component to render the options.
        setFitId( '-1' ); // Set first element of the options in the state so it can callback to set in the parent component as a default selected option.
    }

    /**
     * 
     * @param fitId // Selected Fit ID we get from AutoCompleteSelect component.
     * Get Washes correspoding to the selected gender id, type id and fit id.
     */
    async function getWash( fitId ) {
        setFitId( fitId ); // Set selected fit id to state so it will callback to parent component i.e StockReport with useEffect.
        setWashLoading(true);
        const data = await fetch( `${ GET_WASH_URL }${ genderId }&type=${ typeId }&fit=${ fitId }` );
        const washes = await data.json();
        setWashLoading(false);
        const washOptions = [];
        // Set Fits according to the requirement of AutoCompleteSelect component to render options.
        washes.items.map( item => {
            washOptions.push( { value: item.washid, label: item.washname } );
        });
        setWashes( washOptions ); // Set washes array in the state so it can call next component to render the options.
        setWashId( '-1' ); // Set first element of the options in the state so it can callback to set in the parent component as a default selected option.
    }

    /**
     * 
     * @param washId // Selected Wash ID we get from AutoCompleteSelect component.
     * Get Sku correspoding to the selected gender id, type id, fit id and wash id.
     */
    async function getSKU( washId ) {
        setWashId( washId ); // Set selected sku id to state so it will callback to parent component i.e StockReport with useEffect.
        setSkuLoading(true);
        const data = await fetch( `${ GET_SKU_URL }${ genderId }&type=${ typeId }&fit=${ fitId }&wash=${ washId }` );
        const skus = await data.json();
        setSkuLoading(false);
        const skuOptions = [];
        // Set Fits according to the requirement of AutoCompleteSelect component to render options.
        skus.items.map( item => {
            skuOptions.push( { value: item.skuid, label: item.sku } );
        });
        setSkus( skuOptions ); // Set sku array in the state so it can call next component to render the options.
        setSkuId( '-1' ); // Set first element of the options in the state so it can callback to set in the parent component as a default selected option.
    }

    return(
        <div className="slt-filters" id="mainfilters">
            <h3 className="filter-title">Filter</h3>
            <div>
                <span className="b-ftr-inr">
                    <label>Gender</label>
                    <AutoCompleteSelect list={ genders } callBack = { getTypes } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Category</label>
                    <AutoCompleteSelect list={ types } callBack = { getFit } loading={ categoryLoading } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Fit</label>
                    <AutoCompleteSelect list={ fits } callBack = { getWash } loading={ fitLoading } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Wash</label>
                    <AutoCompleteSelect list={ washes } callBack = { getSKU } loading={ washLoading }/>
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>SKU</label>
                    <AutoCompleteSelect list={ skus } callBack = { ( skuId ) => {  }} loading={ skuLoading } />            
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Customer Type</label>
                    <AutoCompleteSelect list={ skus } callBack = { ( skuId ) => {  }} />            
                </span>
            </div>
        </div>
    );
}

export default StockReportFilter;