import React, {useState} from 'react';
import {Alert, Modal} from 'antd';

const AlertBox = (props) => {
	const [modalText, setModalText] = useState('There are some problems with your operation');
	return (
		<Modal
			className="alert-modal"
			title={<Alert message="Oops, something went wrong" type="warning" showIcon />}
			visible={props.visible}
			onCancel={props.handleCancel}
		><Alert message="Error" description={modalText} type="warning" showIcon />
	</Modal>
	);
};
export default AlertBox;