import React, {useState,useLayoutEffect} from 'react';
import AutoCompleteSelect from './../../common/autoCompleteSelect';
import Input from '../../common/input';
import useDidMountEffect from '../../common/useDidMountEffect';
import config from './../../../config/config';

const LOCAL_URL = config.GET_ALL_URL;
let JWT;

const DetailReportFilter = (props) => {
    
	const [otherThanFirst, setOtherThanFirst] = useState(true)
	const [genders, setGenders] = useState([]);
	const [gendersLoading, setGendersLoading] = useState(true);

	const [genderId, setGenderId] = useState('-1'); // You can set useState(Your default value to be selected in list).
    const [ sku, setSkuId ] = useState('-1');
	const productStatus = props.productStatus ? props.productStatus : '-1';

	function getNewState() {
        return { 
            genderId: genderId,
			sku: sku,
			productStatus:productStatus
        };
	}
	
	useDidMountEffect( () => {
        const newState = getNewState();
        props.filterData(newState);
    },[genderId,sku,productStatus]);

	useLayoutEffect( () => {
        if (otherThanFirst) {
            const newState = {
				genderId: '-1',
				productStatus:productStatus,
                sku: sku,
            }
            props.filterData(newState);    
            getGenders(newState);
        }
	}, [props.productStatus, props.isInActive]);
	
	function getGenders(state) {
        setGendersLoading(true);
        fetch( LOCAL_URL + "goodsRequiredGenders" ,{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setGendersLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.genderid, label: item.gendername } );
            });
            setGenders( options ); // Set types array in the state so it can call next component to render the options.
        });
    }

	useState(() => {
		JWT = localStorage.getItem('access_token');
	}, []);

	return (
		<div className="slt-filters" id="mainfilters">
			<h3 className="filter-title">Filter</h3>
			<div>
				<span className="b-ftr-inr">
					<label>Gender</label>
					<AutoCompleteSelect
						list={genders}
						loading={gendersLoading}
						callBack={(a) => {
							setGenderId(a);
						}}
					/>
				</span>
			</div>
			<div>
				<span className="b-ftr-inr">
					<label>SKU</label>
					<Input
						type="number"
						name="skuId"
						callBack={(a) => {
							setSkuId(a);
						}}
					/>
				</span>
			</div>
		</div>
	);
};
export default DetailReportFilter;