import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { jwtDecode } from "../../helper/jwt-decode";
import companyLogo from "./../../images/DL1961_Charcoal.svg";
import "./../../fonts/font-awesome-4.7.0/css/font-awesome.min.css";

const MOUSE_DOWN = "mousedown";
const OPTIONS_LIST_INNNER = "options-list-inner";
const SHOW = "show";
const NAV_LINK = ".nav-link";
const DROPDOWN_MENU = "dropdown-menu";

const Header = () => {
  const [loggedIn, setloggedIn] = useState(
    localStorage.getItem("access_token")
  );
  const token = jwtDecode(loggedIn);
  const [logout, setLogout] = useState(false); // Boolean state to be set when user logout from the application.
  const [closeReport, setCloseReport] = useState(false);

  /**
   * Close list of account options on outise click.
   */
  function handleClickOutside() {
    window.onclick = function (event) {
      if (!event.target.matches(NAV_LINK)) {
        var dropdowns = document.getElementsByClassName(DROPDOWN_MENU);
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains(SHOW)) {
            openDropdown.classList.remove(SHOW);
          }
        }
      }
    };
  }

  var loadScript = function (src) {
    var tag = document.createElement("script");
    tag.async = false;
    tag.src = src;
    document.getElementsByTagName("body")[0].appendChild(tag);
  };

  // useScript('/vendor/jquery/jquery-3.2.1.min.js');
  // useScript('/vendor/bootstrap/js/popper.js');
  // useScript('/vendor/bootstrap/js/bootstrap.min.js');

  // Passing e
  /**
   * Set event listener of mousedown on first render of component and remove when component destroy.
   */

  useEffect(() => {
    //Set Page Title
    let pageTitle;
    pageTitle = document.querySelector(".report-result-head")
      ? document.querySelector(".report-result-head").firstChild.innerHTML
      : "";
    if (!pageTitle)
      pageTitle = document.querySelector(".report-container-header")
        ? document.querySelector(".report-container-header").firstChild
          .innerHTML
        : "";
    document.title = pageTitle ? pageTitle : "Dashboard";
    //End Page Title
    document.addEventListener(MOUSE_DOWN, handleClickOutside);
    // Function to be called on when component destroy.
    return () => {
      document.removeEventListener(MOUSE_DOWN, handleClickOutside);
    };
  }, []); // Passing empty array will only call useEffect on first render of component.

  /**
   * Class to be added on list to show on click.
   */

  function showOptions() {
    document.getElementById(OPTIONS_LIST_INNNER).classList.toggle(SHOW);
  }

  /**
   * Function to set state of user to logout.
   */
  function logoutUser() {
    setLogout(true);
    localStorage.removeItem("access_token");
  }

  if (loggedIn == null || token.exp < new Date().getTime() / 1000) {
    return <Redirect to="/" />;
  }

  return (
    <div className="header-main">
      <div className="header-inner">
        <div>
          <a href="#" onClick={(e) => e.preventDefault()} className="f-logo">
            OMS
          </a>
        </div>
        <a
          href="#"
          onClick={(e) => e.preventDefault()}
          className="company-logo"
        >
          <img className="svg" src={companyLogo} />
        </a>
        <div className="header-right">
          <a href="#" onClick={(e) => e.preventDefault()} className="feed-back">
            <i className="fa fa-question-circle" aria-hidden="true" /> Help
          </a>
          <a href="#" onClick={(e) => e.preventDefault()} className="feed-back">
            <i className="fa fa-commenting" aria-hidden="true" /> Feedback
          </a>
          <div
            className="nav-item dropdown"
            id="options-list-outer"
            onClick={showOptions}
          >
            <button
              data-toggle="dropdown"
              className="nav-link dropdown-toggle user-action"
            >
              <i className="fa fa-user" aria-hidden="true" />{" "}
              fascom <b className="caret" />
            </button>

            <div className="dropdown-menu" id="options-list-inner">
              <a
                href="#"
                onClick={(e) => e.preventDefault()}
                className="dropdown-item"
              >
                <i className="fa fa-user-o" /> Profile
              </a>
              <div className="dropdown-divider" />
              <a
                href="#"
                onClick={(e) => e.preventDefault()}
                className="dropdown-item"
              >
                <i className="fa fa-calendar-o" /> Calendar
              </a>
              <div className="dropdown-divider" />
              <Link to="#" className="dropdown-item">
                <i className="fa fa-cog" /> Settings
              </Link>
              <div className="dropdown-divider" />
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                  logoutUser()
                }}
                className="dropdown-item"

              >
                <i className="fa fa-power-off" aria-hidden="true" /> Logout
              </a>
              {
                logout ? (
                  <Redirect to="/" />
                ) : null /** Redirect to login page when state changes to true in logout. */
              }
            </div>
          </div>
        </div>
      </div>
      <div className="f-main-menu">
        <div id="menu_area" className="menu-area">
          <div className="container-fluid">
            <div className="row">
              <nav className="navbar navbar-light navbar-expand-lg mainmenu pad-0">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav mr-auto">
                    <Link className="" to="/dashboard">
                      <i
                        className="fa fa-home home-menu-icon"
                        aria-hidden="true"
                      ></i>
                    </Link>

                    <li className="dropdown home-icon">
                      <a
                        href="#"
                        onClick={(e) => e.preventDefault()}
                        className="dropdown-toggle"


                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Dashboard <span className="sr-only">(current)</span>
                      </a>

                      <ul
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <li className="dropdown">
                          <a
                            className="dropdown-toggle"
                            href="#"
                            onClick={(e) => e.preventDefault()}
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Booked
                          </a>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <Link
                              className="link-react"
                              to="/bookings-by-sale-person"
                            >
                              <li>Booked By Sale Person</li>
                            </Link>

                            <Link
                              className="link-react"
                              to="/booking-by-gender"
                            >
                              <li>Booked By Gender</li>
                            </Link>

                            <Link
                              className="link-react"
                              to="/bookings-by-customer"
                            >
                              <li>Booked By Customer Top 30</li>
                            </Link>

                            <Link
                              className="link-react"
                              to="/bookings-by-region"
                            >
                              <li>Booked By Region</li>
                            </Link>

                            <Link
                              className="link-react"
                              to="/bookings-by-fit-style"
                            >
                              <li>Booked By FIT/Style</li>
                            </Link>
                          </ul>
                        </li>


                        <li className="dropdown">
                          <a
                            className="dropdown-toggle"
                            href="#"
                            onClick={(e) => e.preventDefault()}
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Pending Orders
                          </a>

                          <ul
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <Link
                              className="link-react"
                              to="/pending-by-sale-person"
                            >
                              <li>Pending Orders By Sale Person</li>
                            </Link>

                            <Link
                              className="link-react"
                              to="/pending-by-gender"
                            >
                              <li>Pending Orders By Gender</li>
                            </Link>

                            <Link
                              className="link-react"
                              to="/pending-by-customer"
                            >
                              <li>Pending Orders By Customer Top 30</li>
                            </Link>

                            <Link
                              className="link-react"
                              to="/pending-by-region"
                            >
                              <li>Pending Orders By Region</li>
                            </Link>

                            <Link
                              className="link-react"
                              to="/pending-by-fit-style"
                            >
                              <li>Pending Orders By Fit/Style</li>
                            </Link>

                            {/*
														<Link className="link-react" to="/net-shipped-by-fit-style-ytd">
															<li>Net Shipped Fit/Style</li>
														</Link> */}
                          </ul>
                        </li>


                        <li className="dropdown">
                          <a
                            className="dropdown-toggle"
                            href="#"
                            onClick={(e) => e.preventDefault()}
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Pending POD
                          </a>

                          <ul
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <Link
                              className="link-react"
                              to="/pending-pod-by-sale-person"
                            >
                              <li>Pending POD By Sale Person</li>
                            </Link>

                            <Link
                              className="link-react"
                              to="/pending-pod-by-region"
                            >
                              <li>Pending POD By Region</li>
                            </Link>


                            {/* <Link
                              className="link-react"
                              to="/pending-by-gender-ytd"
                            >
                              <li>Pending Pod By Gender</li>
                            </Link>

                            <Link
                              className="link-react"
                              to="/pending-by-customer-ytd"
                            >
                              <li>Pending Pod By Customer Top 30</li>
                            </Link>


                            <Link
                              className="link-react"
                              to="/pending-by-fit-style-ytd"
                            >
                              <li>Net Shipped By Fit/Style</li>
                            </Link> */}

                            {/*
														<Link className="link-react" to="/net-shipped-by-fit-style-ytd">
															<li>Net Shipped Fit/Style</li>
														</Link> */}
                          </ul>
                        </li>
                        <li className="dropdown">
                          <a
                            className="dropdown-toggle"
                            href="#"
                            onClick={(e) => e.preventDefault()}
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Shipped
                          </a>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <Link
                              className="link-react"
                              to="/shipped-by-sale-person"
                            >
                              <li>Shipped By Sale Person</li>
                            </Link>
                            <Link
                              className="link-react"
                              to="/shipped-by-gender"
                            >
                              <li>Shipped By Gender</li>
                            </Link>
                            <Link
                              className="link-react"
                              to="/shipped-by-customer"
                            >
                              <li>Shipped By Customer Top 30</li>
                            </Link>
                            <Link
                              className="link-react"
                              to="/shipped-by-region"
                            >
                              <li>Shipped By Region</li>
                            </Link>
                            <Link
                              className="link-react"
                              to="/shipped-by-region-new"
                            >
                              <li>Shipped By Region New</li>
                            </Link>
                            <Link
                              className="link-react"
                              to="/shipped-by-fit-style"
                            >
                              <li>Shipped By Fit/Style</li>
                            </Link>
                          </ul>
                        </li>

                        <li className="dropdown">
                          <a
                            className="dropdown-toggle"
                            href="#"
                            onClick={(e) => e.preventDefault()}
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Returned
                          </a>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <Link
                              className="link-react"
                              to="/return-by-sale-person"
                            >
                              <li>Returned By Sale Person</li>
                            </Link>

                            <Link
                              className="link-react"
                              to="/return-by-gender"
                            >
                              <li>Returned By Gender</li>
                            </Link>

                            <Link
                              className="link-react"
                              to="/return-by-customer"
                            >
                              <li>Returned By Customer Top 30</li>
                            </Link>
                            <Link
                              className="link-react"
                              to="/return-by-region"
                            >
                              <li>Returned By Region</li>
                            </Link>
                            <Link
                              className="link-react"
                              to="/return-by-fit-style"
                            >
                              <li>Returned By Fit/Style</li>
                            </Link>
                          </ul>
                        </li>

                        <li className="dropdown">
                          <a
                            className="dropdown-toggle"
                            href="#"
                            onClick={(e) => e.preventDefault()}
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Net Shipped
                          </a>

                          <ul
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <Link
                              className="link-react"
                              to="/net-shipped-by-sale-person"
                            >
                              <li>Net Shipped By Sale Person</li>
                            </Link>

                            <Link
                              className="link-react"
                              to="/net-shipped-by-gender"
                            >
                              <li>Net Shipped By Gender</li>
                            </Link>

                            <Link
                              className="link-react"
                              to="/net-shipped-by-customer"
                            >
                              <li>Net Shipped By Customer Top 30</li>
                            </Link>

                            <Link
                              className="link-react"
                              to="/net-shipped-by-region"
                            >
                              <li>Net Shipped By Region</li>
                            </Link>

                            <Link
                              className="link-react"
                              to="/net-shipped-by-fit-style"
                            >
                              <li>Net Shipped By Fit/Style</li>
                            </Link>

                            {/*
														<Link className="link-react" to="/net-shipped-by-fit-style-ytd">
															<li>Net Shipped Fit/Style</li>
														</Link> */}
                          </ul>
                        </li>





                        {/* <Link className="link-react"  to="/net-shipped-ytd">
                          <li>Net Shipped</li>
                        </Link> */}
                        <Link className="link-react" to="/e-commerce-by-region">
                          <li>E-Commerce</li>
                        </Link>
                        <Link className="link-react" to="/business-leaderboard">
                          <li>Business Dashboard</li>
                        </Link>
                        <Link
                          className="link-react"
                          to="/business-leaderboard-region-wise"
                        >
                          <li>Business Dashboard - Region Wise</li>
                        </Link>
                      </ul>
                    </li>

                    <li className="dropdown">
                      <a
                        href="#"
                        onClick={(e) => e.preventDefault()}
                        className="dropdown-toggle"


                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Sales
                      </a>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <Link className="link-react" to="/order-by-po">
                          <li>Order By PO #</li>
                        </Link>
                        <Link className="link-react" to="/order-by-product">
                          <li>Order By Product</li>
                        </Link>
                        <Link
                          className="link-react"
                          to="/sale-comparison-report"
                        >
                          <li>Sales Targets</li>
                        </Link>
                        <Link
                          className="link-react"
                          to="/order-booking"
                        >
                          <li>Order Booking</li>
                        </Link>
                      </ul>
                    </li>
                    <li className="dropdown">
                      <a
                        href="#"
                        onClick={(e) => e.preventDefault()}
                        className="dropdown-toggle"


                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Purchase
                      </a>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <Link className="link-react" to="/purchase-order">
                          <li>Purchase Order</li>
                        </Link>
                      </ul>
                    </li>

                    {/*	<li className="dropdown">
											<a href="#" onClick={e => e.preventDefault()} className="dropdown-toggle" href="#" onClick={e => e.preventDefault()} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Inventory</a>
											<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
											 <Link className="link-react" to="/stock-report">
													<li>Stock</li>
												</Link> 
												<Link className="link-react" to="/inventory-product-report">
													<li>Product</li>
												</Link>
											</ul>
										</li>
										*/}
                    <li className="dropdown">
                      <a
                        href="#"
                        onClick={(e) => e.preventDefault()}
                        className="dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Inventory
                      </a>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <Link className="link-react" to="/sku-lookupv2">
                          <li>SKU Lookup</li>
                        </Link>
                        <Link className="link-react" to="/cumulative-ots">
                          <li>CUMULATIVE OTS</li>
                        </Link>
                        <li className="dropdown">
                          <a
                            className="dropdown-toggle"
                            href="#"
                            onClick={(e) => e.preventDefault()}
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            OTS
                          </a>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <Link
                              className="link-react"
                              to="/inventory-ots-summary"
                            >
                              <li>Summary</li>
                            </Link>
                            <Link
                              className="link-react"
                              to="/inventory-ots-detail"
                            >
                              <li>Detail</li>
                            </Link>
                          </ul>
                        </li>

                        <li className="dropdown">
                          <a
                            className="dropdown-toggle"
                            href="#"
                            onClick={(e) => e.preventDefault()}
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Production
                          </a>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            {/* <Link className="link-react" to="/inventory-production-summary">
															<li>Summary</li>
														</Link> */}
                            <Link
                              className="link-react"
                              to="/inventory-production-detail"
                            >
                              <li>Detail</li>
                            </Link>
                          </ul>
                        </li>
                        <Link className="link-react" to="/b-grade-stock-report">
                          <li>B Grade Stock</li>
                        </Link>
                      </ul>
                    </li>
                    <li className="dropdown">
                      <a
                        href="#"
                        onClick={(e) => e.preventDefault()}
                        className="dropdown-toggle"


                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Master
                      </a>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <li className="dropdown">
                          <a
                            className="dropdown-toggle"
                            href="#"
                            onClick={(e) => e.preventDefault()}
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Products
                          </a>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <Link className="link-react" to="/product-report">
                              <li>Products</li>
                            </Link>
                            <Link
                              className="link-react"
                              to="/product-composition-report"
                            >
                              <li>Products UPC</li>
                            </Link>
                          </ul>
                        </li>

                        <li className="dropdown">
                          <Link className="link-react" to="/customer-report">
                            Customers
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown">
                      <a
                        href="#"
                        onClick={(e) => e.preventDefault()}
                        className="dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Stock{" "}
                      </a>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        {/* <li className="dropdown">
													<Link className="link-react" to="/sku-wise-search">
														Stock Report
													</Link>
												</li> */}
                        <li className="dropdown">
                          <Link className="link-react" to="/goods-required">
                            Goods Required
                          </Link>
                        </li>
                        <li className="dropdown">
                          <Link className="link-react" to="/stock-location-wise">
                            Stock Location Wise
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
