import React, { useEffect, useState, useLayoutEffect } from 'react';
import AutoCompleteSelect from '../../../common/autoCompleteSelect';
import Input from '../../../common/input';
import useDidMountEffect from '../../../common/useDidMountEffect';
import config from '../../../../config/config';
import moment from 'moment';


const LOCAL_URL = config.GET_ALL_URL;
const PRODUCT_STATUS = "&productStatus=";
const IS_INACTIVE = "&isInActive="
let JWT;

const StockLocationWiseReportLocationFilter = (props) => {

    const [otherThanFirst, setOtherThanFirst] = useState(true)

    const [locationId, setLocationId] = useState('-1'); // You can set useState(Your default value to be selected in list).
    const [location, setLocation] = useState([]);
    const [locationLoading, setLocationLoading] = useState(true);

    function getNewState() {
        return {
            localtionId: locationId,
        };
    }

    useState(() => {
        JWT = localStorage.getItem('access_token');
    }, []);

    /**
     * Send back data to the parent component i.e ProductReport whenever selected option changes.
     */
    useDidMountEffect(() => {
        const newState = getNewState();
        props.filterData(newState);
    }, [locationId]);

    useLayoutEffect(() => {
        if (otherThanFirst) {
            const newState = {
                locationId: '-1',
            }
            props.filterData(newState);
            getLocation(newState)
        }
    }, [props.productStatus, props.isInActive]);

    function getDate() {
        return "&fromDate=" + moment(props.date.fromDate).format('MM/DD/YYYY') + "&toDate=" + moment(props.date.toDate).format('MM/DD/YYYY');
    }

    function getLocation(state) {
        setLocationLoading(true);
        fetch(LOCAL_URL + "stockLocationWiseLocations" + getDate() + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive, { method: 'POST', headers: { 'Authorization': JWT, 'Accept': 'application/json', 'Content-Type': 'application/json' }, body: JSON.stringify(state) })
            .then(res => res.json())
            .then(result => {
                setLocationLoading(false);
                let options = [];
                // Set Items according to the requirement of AutoCompleteSelect component to render options.
                result.forEach(item => {
                    options.push({ value: item.locationid, label: item.locationname });
                });
                setLocation(options); // Set types array in the state so it can call next component to render the options.
            });
    }
    return (
        <div className="slt-filters" id="mainfilters">
            <h3 className="filter-title">Location Filter </h3>
            <div>
                <span className="b-ftr-inr">
                    <label>Location</label>
                    <AutoCompleteSelect defaultValue={1} list={location} loading={locationLoading} callBack={(a) => { setLocationId(a) }} />
                </span>
            </div>
        </div>
    );
}

export default StockLocationWiseReportLocationFilter;