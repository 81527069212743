function montFromDate(){
    var d = new Date();
    var date = 1;
    var month = d.getMonth() + 1;
    var year = d.getFullYear();
    var fromDate = month + '/' + date + '/' + year;
    return fromDate;
}

function monthToDate(day,month,year){
    var d = new Date();
    var date = day ? day :d.getDate();
    var m = month ? month :d.getMonth() + 1;
    var y = year ? year:d.getFullYear();
    var toDate = m + '/' + date + '/' + y;
    return toDate;
}

function yearFromDate(day,month,year) {
    var d = new Date();
    var date = day ? day:1;
    var m = month? month:1;
    var y = year ? year:d.getFullYear();
    var fromDate = m + '/' + date + '/' + y;
    return fromDate;
}


function yearToDate(day,month,year){
    var d = new Date();
    var date = day ? day : d.getDate();
    var m = month ? month : d.getMonth() + 1;
    var y = year ? year : d.getFullYear();
    var toDate = m + '/' + date + '/' + y;
    return toDate;
}



export {montFromDate,monthToDate,yearFromDate,yearToDate };