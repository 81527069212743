import React, {useState} from "react";
import {Alert, Spin, Space, Form, Input, Button, Modal} from "antd";
import CreatableSelect from "react-select/creatable";

const {TextArea} = Input;

const EmailPopup = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState("");

  const onFinish = (values) => {
    setLoading(true);
    props.callBack(values);
    setTimeout(() => {
      form.resetFields();
      setLoading(false);
    }, 3000);
  };

  const onFinishFailed = (errorInfo) => {
    //Form.resetFields();
  };

  return (
    <Modal
      className="send-email-form"
      title={<button id="email-button">Send Email</button>}
      visible={props.visible}
      onCancel={() => {
        props.handleCancel();
      }}
    >
      <Form
        form={form}
        name="send-email-form"
        labelCol={{
          span: 3,
        }}
        wrapperCol={{
          span: 20,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="To"
          name="to"
          rules={[
            {
              required: true,
              message: "Please Enter Email",
            },
          ]}
        >
                  <CreatableSelect
                      placeholder=''
                      isMulti />

          {/* <Input type="email" /> */}
        </Form.Item>

        <Form.Item
          label="Subject"
          name="subject"
          rules={[
            {
              required: true,
              message: "Please Enter Subject",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Message"
          name="message"
          rules={[
            {
              required: true,
              message: "Please Enter Your Message",
            },
          ]}
        >
          <TextArea rows={6} />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 21,
            span: 0,
          }}
        >
          {loading ? (
            <Space size="middle">
              <Spin />
            </Space>
          ) : (
            ""
          )}
          <Button disabled={loading} type="primary" htmlType="submit">
            Send
          </Button>
        </Form.Item>
        {loading ? (
          <Alert message="Email sent successfully" type="success" />
        ) : (
          ""
        )}
      </Form>
    </Modal>
  );
};
export default EmailPopup;