import {Table} from "antd";
import React, {useEffect, useState} from "react";
import moment from "moment";
import {getExcelSpecific} from "../../helper/excel";
import PopUp from "../common/popup";

const GroupHtmlTable = (props) => {


  const [column, setColumns] = useState([]);
  const [displayPopup, setDisplayPopup] = useState(false);
  const [popupUrl, setpopupUrl] = useState("");
  const [destroy, setDestroy] = useState(false);
  const [tabs, setTabs] = useState(false);
  const [type, setType] = useState(false);
  const [columnAttr, setColumnAttr] = useState(false);

  const [skuId, setSkuId] = useState("");
  const [location, setLocation] = useState("");
  const [inseam, setInseam] = useState("");
  const [exfactdate, setExfactdate] = useState("");
  const [genderID, setGenderId] = useState("");
  const [styleId, setStyleId] = useState("");
  const [size, setSize] = useState("");
  const [regionId, setRegionId] = useState("");
  const [salesPersonId, setSalesPersonId] = useState("");
  const [popupTitle, setPopupTitle] = useState("");
  const [reportNumber, setReportNumber] = useState("");

  const [seconds, setSeconds] = useState(0);
  const [reportType, setReportType] = useState("");
  const [yearDesc, setYearDesc] = useState("");

  const columnAttribute = props.columnAttributes ? props.columnAttributes : "";
  let countWhen = props.display;

  useEffect(() => {
    if (props.data != null) {
      destroy == true ? setDestroy(false) : setDestroy(true);
      getColumns();
    }
  }, [props.data]);

  useEffect(() => {
    if (countWhen) {
      setSeconds(0);
      const timer = setInterval(() => {
        setSeconds((t) => t + 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [countWhen]);

  function showPopup(record, column) {

    let columnAttr = columnAttribute[column.field];
    setColumnAttr(columnAttr);

    let title = columnAttr && columnAttr.title ? columnAttr.title : "";
    let reportNumbers = columnAttr && columnAttr.reportNumber ? columnAttr.reportNumber : "";
    let endPoint = column.endpoint ? column.endpoint : columnAttr && columnAttr.endPoint ? columnAttr.endPoint: "";
    let tabs = columnAttr && columnAttr.tabs ? columnAttr.tabs : false;
    let type = columnAttr && columnAttr.type ? columnAttr.type : "";

    setTabs(tabs);
    setType(type);
    setDisplayPopup(true);
    setPopupTitle(title);
    setReportNumber(reportNumbers);
    setpopupUrl(endPoint);
    setGenderId(record.genderid);
    setSize(record.size);
    setStyleId(record.styleid);
    setRegionId(record.regionid);
    setSkuId(record.sku);
    setSalesPersonId(record.salespersonid);
    setLocation(record.location);
    setReportType(record.reportdesc);
    setYearDesc(record.yeardesc);
    setInseam(record.inseam);
    setExfactdate(record.exfactdate);
  }

  function reorder(array, column, position) {
    //remove object
    let findColumn = array.filter(function (el) {
      return el.dataIndex == column;
    });
    //get allobject
    array = array.filter(function (el) {
      return el.dataIndex != column;
    });
    //insert object to arary
    array.splice(position, 0, findColumn[0]);
    return array;
  }

  function groupBy(xs, f) {
    return xs.reduce(
      (r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r),
      {}
    );
  }

  function getColumns() {
    if (props.data.columns != null) {
      let columns = [];
      //filter only parent column
      let filterParentColumn = props.data.columns.filter((i) =>
        i.hasOwnProperty("parent")
      );
      //Group Parent Filter Column
      const parentColumn = groupBy(filterParentColumn, (c) => c.parent);
      Object.keys(parentColumn).forEach((title) => {
        columns.push({
          title: title,
          children: [],
        });
      });
      props.data.columns.forEach((column, index) => {
        let columnObject = {
          title: () =>
            column.upper ? column.title.toUpperCase() : column.title,
          dataIndex: column.field,
          width: column.width && column.width != undefined ? column.width : 100,
          align:
            column.convert != null && column.convert == true ? "right" : null,
          sorter: (a, b) => {
            if (!Object.values(a).includes("Total")) {
              //Exclude Last Row
              let fielda = a[column.field];
              let fieldb = b[column.field];
              let isValidNumber = /^[0-9,.]*$/.test(fielda);
              //Date Sorter

              if (moment(fielda, "MM/DD/YYYY", true).isValid()) {
                return new Date(fielda) - new Date(fieldb);
              }
              //String Sorter
              else if (typeof fielda === "string") {
                if (
                  fielda.includes("$") ||
                  isValidNumber ||
                  fielda.includes("%")
                ) {
                  fielda = fielda ? fielda.replaceAll("$", "") : "";
                  fieldb = fieldb ? fieldb.replaceAll("$", "") : "";
                  fielda = fielda ? fielda.replaceAll(",", "") : "";
                  fieldb = fieldb ? fieldb.replaceAll(",", "") : "";
                  fielda = fielda ? fielda.replaceAll("%", "") : "";
                  fieldb = fieldb ? fieldb.replaceAll("%", "") : "";
                  return fielda - fieldb;
                }
                return fielda.localeCompare([fieldb]);
              }
              return fielda - fieldb;
            }
          },
          sortDirections: ["descend", "ascend"],
          render: (text, record) => {
            return column.link ? (
              <button
                className="link-button"
                sku_id={text}
                onClick={() => showPopup(record, column)}
              >
                {text}
              </button>
            ) : column.bold != null && column.bold == true ? (
              <b>{text}</b>
            ) : (
              text
            );
          },
        };
        // check if has parent
        if (column.parent) {
          //Find Column Index By Value
          let columnIndex = [columns.findIndex((x) => x.title == column.parent)];
          //push child to parents
          columns[columnIndex].children.push(columnObject);
        } else {
          columns.push(columnObject);
        }
      });

      let finalColumn = {};

      for (let i = 0; i < props.data.columns.length; i++) {
        let columnss = props.data.columns[i].field;
        let parent = props.data.columns[i].parent;
        if (parent) 
          break;
        finalColumn[columnss] = i;
      }

      //      console.log(finalColumn);
      Object.keys(finalColumn).forEach(function (key) {
        // console.log(key);
        // console.log(finalColumn[key]);
        // console.log(props.data.columns);
        //          console.log(finalColumn);
        //console.log(props.data.columns);
        //`    columns = reorder(columns, key, props.reorder[key]);


     //   console.log('columns',columns)

        columns = reorder(columns, key, finalColumn[key]);
      });

    //  console.log('finalcoluns',columns)
      setColumns(columns);
    }
  }
  const Loading = {
    spinning: props.loading,
    indicator: (
      <>
        <div className="loader-wrapper">
          <div id="progress-0" 
            // className="loader"
          >
          {/*   {seconds}
            <span>sec</span> */}
          </div>
        </div>
      </>
    ),
  };
  const summary = (
    <div className="total-results">
      {
        <p>
          <span>
            <b className="summary-title">Report Summary</b>
          </span>
        </p>
      }
      {props.data.dateRange != null ? (
        <p>
          <b>Date Range: {props.data.dateRange}</b>
        </p>
      ) : null}
      {props.data.summaryTitle != null ? (
        <p>
          <b>{props.data.summaryTitle}</b>
        </p>
      ) : null}
      {props.data.groupOn != null && props.data.groupOn.length > 0 ? (
        <p>
          <b>Group On: {props.data.groupOn}</b>
        </p>
      ) : null}
      {props.data.sortOn != null && props.data.sortOn.length > 0 ? (
        <p>
          <b>
            Sort On: {props.data.sortOn}, {props.data.order}
          </b>
        </p>
      ) : null}
      {props.data.queryFilters != null ? (
        <p>
          <b>{props.data.queryFilters}</b>
        </p>
      ) : null}
      {props.data.totalQuantityCommited != null ? (
        <p>
          <b>Total Quantity Committed: {props.data.totalQuantityCommited}</b>
        </p>
      ) : null}
      {props.data.totalQuantityOnHand != null ? (
        <p>
          <b>Total Quantity On Hand: {props.data.totalQuantityOnHand}</b>
        </p>
      ) : null}
      {props.data.totalQuantityAvailable != null ? (
        <p>
          <b>Total Quantity Available: {props.data.totalQuantityAvailable}</b>
        </p>
      ) : null}
      {props.data.totalQuantityBackOrder != null ? (
        <p>
          <b>Total Quantity Back Order: {props.data.totalQuantityBackOrder}</b>
        </p>
      ) : null}
      {props.data.totalQuantityOnOrder != null ? (
        <p>
          <b>Total Quantity On Order: {props.data.totalQuantityOnOrder}</b>
        </p>
      ) : null}
      {props.data.totalQuantity != null ? (
        <p>
          <b>Total Quantity: {props.data.totalQuantity}</b>
        </p>
      ) : null}
      {props.data.totalQuantityOts != null ? (
        <p>
          <b>Total Quantity OTS: {props.data.totalQuantityOts}</b>
        </p>
      ) : null}
      {props.data.totalQuantityBooked != null ? (
        <p>
          <b>Total Quantity Booked: {props.data.totalQuantityBooked}</b>
        </p>
      ) : null}
      {props.data.totalQuantityShipped != null ? (
        <p>
          <b>Total Quantity Shipped: {props.data.totalQuantityShipped}</b>
        </p>
      ) : null}
      {props.data.totalAmount != null ? (
        <p>
          <b>Total Amount: {props.data.totalAmount}</b>
        </p>
      ) : null}
      {props.data.totalSku != null && props.data.totalSku != "" ? (
        <p>
          <b>Total SKUs: {props.data.totalSku}</b>
        </p>
      ) : null}
      {props.data.totalCustomer != null && props.data.totalCustomer != "" ? (
        <p>
          <b>Total Customers: {props.data.totalCustomer}</b>
        </p>
      ) : null}
      {props.data.totalResults != null ? (
        <p>
          <b>Total Results: {props.data.totalResults}</b>
        </p>
      ) : null}
      {props.data.totalTime != null ? (
        <p>
          <b>Response Time: {props.data.totalTime}</b>
        </p>
      ) : null}

      <br></br>
      {/* {<p><span><b className="summary-title" >Filter(s) Applied</b></span></p>} */}
    </div>
  );

  return (
    <>
      {" "}
      {props.popupTable ? (
        <div className="report-result-right-top">
          <button
            id="download-excel-button"
            onClick={() => {
              setDisplayPopup(false);
              getExcelSpecific(props.data);
            }}
          >
            Download EXCEL
          </button>
        </div>
      ) : (
        ""
      )}
      <Table
        className={props.TableClassName}
        key={destroy}
        dataSource={props.data.rows}
        columns={column}
        bordered // AJ
        rowClassName={(record, index) => {
          if(record.bold) {
              return "bold-row";
          }
          else if(record.className) { 
            return record.className;
          }
          else {
              return "";
           }
        }
      }
        loading={props.popupTable ? props.loading : Loading}
        pagination={{
          defaultPageSize: props.defaultPageSize ? props.defaultPageSize : 15,
          showSizeChanger: true,
          pageSizeOptions: ["15", "30", "50", "100"],
        }}
        scroll={{x: 1500, y: props.tableHeight ? props.tableHeight : 566}}
      />

      {props.data.rows && !props.popupTable ? summary : null}

      <PopUp
        table={props.table}
        className={props.popUpClassName}
        TableClassName={props.TableClassName + " popUpTableComparison"}
        columnAttribute={columnAttr}
        popupNavigation={tabs}
        hyperLinksUrl={props.hyperLinksUrl}
        popupUrl={popupUrl}
        popupTitle={popupTitle}
        reportNumber={reportNumber}
        formState={props.body}
        reportType={reportType}
        yearDesc={yearDesc}
        skuUrl={props.skuUrl}
        location={location}
        inseam={inseam}
        skuId={skuId}
        body={props.body}
        exfactdate={exfactdate}
        genderID={genderID}
        styleId={styleId}
        size={size}
        regionId={regionId}
        type={type}
        salesPersonId={salesPersonId}
        display={displayPopup}
        callBack={() => setDisplayPopup(false)}
      />
    </>
  );
};
export default GroupHtmlTable;
