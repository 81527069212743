import React, { useEffect, useState } from 'react';
import MainTable from './mainTable';

const HIDE_DIALOG = "hide-dialog";
const SHOW_DIALOG = "show-dialog";
const REPORT_RESULT = "report-result";

/**
 * 
 * @param {*} props 
 * Params: 
 *      1. url to fetch the data from api and show in the table.
 *      2. titles for the table head.
 *      3. dataNames are the attribute names in the object.
 *
 */
const StockReportDialog = ( props ) => {

    const titles = props.titles;
    const dataNames = props.dataNames;
    const hyperLinks = props.hyperLinks;

    const URL = props.url;
    const PDF_URL = props.pdfUrl;

    const [ loading, setLoading ] = useState(true);
    const [ data, setData ] = useState([]);
    const [ title, setTitle ] = useState('');

    async function fetchData() {
        setLoading(true);
        const res = await fetch(URL);
        const option = await res.json();
        setData( option );
        setLoading(false);
        setTitle( option.number );
    }
     
    useEffect( () => {
        if( props.display == true ) {
            showDialog();
            fetchData();
        }
    },[ props.display ]);


    function showDialog() {
        if( document != null && document.getElementById( REPORT_RESULT ) != null ) {
            const element =  document.getElementById( REPORT_RESULT );
            if( element.classList.contains( HIDE_DIALOG ) ) {
                element.classList.add( SHOW_DIALOG );
                element.classList.remove( HIDE_DIALOG );
            } 
        }
    }

    function hideDialog() {
        setData([]);
        if( document != null && document.getElementById( REPORT_RESULT ) != null ) {
            const element =  document.getElementById( REPORT_RESULT );
           if( ( element.classList.contains( SHOW_DIALOG ))) {
                element.classList.add( HIDE_DIALOG );
                element.classList.remove( SHOW_DIALOG );
                props.callBack();
            }
        }
    }
    
    function getPDF() {
        const win = window.open(PDF_URL, '_blank');
		win.focus();
    }

    return(
        <div id="report-result" className="report-result hide-dialog">
            <div className="report-result-head">
                <h2>{ title }</h2>
                <div className="report-result-right-top">
                    <button id="download-csv-button" onClick={ getPDF }>Download CSV</button>
                    <button id="download-excel-button" onClick={ getPDF }>Download EXCEL</button>
                    <button id="download-pdf-button" onClick={ getPDF }>Download PDF</button>  
                    <button id="close-dialog-btn" onClick={ hideDialog }>Close</button>
                </div>
            </div>
            <div className="report-data">
                <MainTable
                    data={ data }
                    loading={ loading }
                    titles={ titles }
                    dataNames={ dataNames }
                    hyperLinks={ hyperLinks } 
                />
            </div>
        </div>
    );
}

export default StockReportDialog;