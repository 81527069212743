import React, { useEffect, useState, useLayoutEffect } from 'react';
import AutoCompleteSelect from '../../../common/autoCompleteSelect';
import Input from '../../../common/input';
import useDidMountEffect from '../../../common/useDidMountEffect';
import config from '../../../../config/config';
import moment from 'moment';


const LOCAL_URL = config.GET_ALL_URL;
const PRODUCT_STATUS = "&productStatus=";
const IS_INACTIVE = "&isInActive="
let JWT;

const StockLocationWiseFilter = (props) => {

    const [otherThanFirst, setOtherThanFirst] = useState(true)

    // Selected Ids from the options list in AutoCompleteSelect.

    const [genderId, setGenderId] = useState('-1'); // You can set useState(Your default value to be selected in list).
    const [locationId, setLocationId] = useState('-1'); // You can set useState(Your default value to be selected in list).
    const [seasonId, setSeasonId] = useState('-1'); // You can set useState(Your default value to be selected in list).
    const [currentSeasonId, setCurrentSeasonId] = useState('-1'); // You can set useState(Your default value to be selected in list).
    const [fabricId, setFabricId] = useState('-1'); // You can set useState(Your default value to be selected in list).
    const [genderCategoryId, setGenderCategoryId] = useState('-1');
    const [fitCategoryId, setFitCategoryId] = useState('-1');
    const [riseId, setRiseId] = useState('-1');
    const [fitId, setFitId] = useState('-1');
    const [inseamId, setInseamId] = useState('-1');
    const [washId, setWashId] = useState('-1');
    const [styleId, setStyleId] = useState('-1');
    const [skuId, setSkuId] = useState('-1');

    // Array that have data to sent to AutoCompleteSelect component to render options.

    const [genders, setGenders] = useState([]);
    const [location, setLocation] = useState([]);
    const [season, setSeason] = useState([]);
    const [currentSeason, setCurrentSeason] = useState([]);
    const [fabric, setFabric] = useState([]);
    const [genderCategories, setGenderCategories] = useState([]);
    const [fitCategories, setFitCategories] = useState([]);
    const [rises, setRises] = useState([]);
    const [fits, setFits] = useState([]);
    const [inseams, setInseams] = useState([]);
    const [styles, setStyles] = useState([]);
    const [wash, setWash] = useState([]);
    const [skus, setSkus] = useState([]);

    // Loading booleans to be set on AutoComplete.
    const [gendersLoading, setGendersLoading] = useState(true);
    const [seasonLoading, setSeasonLoading] = useState(true);
    const [locationLoading, setLocationLoading] = useState(true);
    const [currentSeasonLoading, setCurrentSeasonLoading] = useState(true);
    const [fabricLoading, setFabricLoading] = useState(true);
    const [genderCategoriesLoading, setGenderCategoriesLoading] = useState(true);
    const [fitCategoriesLoading, setFitCategoriesLoading] = useState(true);
    const [risesLoading, setRisesLoading] = useState(true);
    const [fitsLoading, setFitsLoading] = useState(true);
    const [inseamsLoading, setInseamsLoading] = useState(true);
    const [stylesLoading, setStylesLoading] = useState(true);
    const [washLoading, setWashLoading] = useState(true);
    const [skusLoading, setSkusLoading] = useState(true);

    function getNewState() {
        return {
            localtionId: locationId,
            genderId: genderId,
            seasonId: seasonId,
            currentSeasonId: currentSeasonId,
            fabricId: fabricId,
            genderCategoryId: genderCategoryId,
            fitCategoryId: fitCategoryId,
            riseId: riseId,
            fitId: fitId,
            inseamId: inseamId,
            washId: washId,
            styleId: styleId,
            skuId: skuId,
        };
    }

    useState(() => {
        JWT = localStorage.getItem('access_token');
    }, []);

    /**
     * Send back data to the parent component i.e ProductReport whenever selected option changes.
     */
    useDidMountEffect(() => {
        const newState = getNewState();
        props.filterData(newState);
    }, [genderId, seasonId, currentSeasonId, fabricId, genderCategoryId, fitCategoryId, riseId, fitId, inseamId, styleId, skuId, washId, locationId]);

    useLayoutEffect(() => {
        if (otherThanFirst) {
            const newState = {
                locationId: '-1',
                genderId: '-1',
                seasonId: '-1',
                currentSeasonId: '-1',
                fabricId: '-1',
                genderCategoryId: '-1',
                fitCategoryId: '-1',
                riseId: '-1',
                fitId: '-1',
                styleId: '-1',
                inseamId: '-1',
                washId: '-1',
                skuId: skuId,
            }
            props.filterData(newState);
            getGenders(newState);
            getLocation(newState)
        }
    }, [props.productStatus, props.isInActive]);

    useDidMountEffect(() => {
        const newState = {
            locationId: '-1',
            genderId: genderId,
            seasonId: '-1',
            currentSeasonId: '-1',
            fabricId: '-1',
            genderCategoryId: '-1',
            fitCategoryId: '-1',
            riseId: '-1',
            fitId: '-1',
            styleId: '-1',
            inseamId: '-1',
            washId: '-1',
            skuId: '-1',
        }
        getSeason(newState);
    }, [genderId]);

    useDidMountEffect(() => {
        const newState = {
            locationId: '-1',
            genderId: genderId,
            seasonId: seasonId,
            currentSeasonId: '-1',
            fabricId: '-1',
            genderCategoryId: '-1',
            fitCategoryId: '-1',
            riseId: '-1',
            fitId: '-1',
            styleId: '-1',
            inseamId: '-1',
            washId: '-1',
            skuId: '-1',


        }
        getCurrentSeason(newState);
    }, [seasonId]);

    useDidMountEffect(() => {
        const newState = {
            // locationId: '-1',
            genderId: genderId,
            seasonId: seasonId,
            currentSeasonId: currentSeasonId,
            fabricId: '-1',
            genderCategoryId: '-1',
            fitCategoryId: '-1',
            riseId: '-1',
            fitId: '-1',
            styleId: '-1',
            inseamId: '-1',
            washId: '-1',
            skuId: '-1',


        }
        getFabric(newState);
    }, [currentSeasonId]);

    useDidMountEffect(() => {
        const newState = {
            // locationId: '-1',
            genderId: genderId,
            seasonId: seasonId,
            currentSeasonId: currentSeasonId,
            fabricId: fabricId,
            genderCategoryId: '-1',
            fitCategoryId: '-1',
            riseId: '-1',
            fitId: '-1',
            styleId: '-1',
            inseamId: '-1',
            washId: '-1',
            skuId: '-1',


        }
        getGendersCategory(newState);
    }, [fabricId]);

    useDidMountEffect(() => {
        const newState = {
            // locationId: '-1',
            genderId: genderId,
            seasonId: seasonId,
            currentSeasonId: currentSeasonId,
            fabricId: fabricId,
            genderCategoryId: genderCategoryId,
            fitCategoryId: '-1',
            riseId: '-1',
            fitId: '-1',
            styleId: '-1',
            inseamId: '-1',
            washId: '-1',
            skuId: '-1',


        }
        getFitCategory(newState);
    }, [genderCategoryId]);

    useDidMountEffect(() => {
        const newState = {
            // locationId: '-1',
            genderId: genderId,
            seasonId: seasonId,
            currentSeasonId: currentSeasonId,
            fabricId: fabricId,
            genderCategoryId: genderCategoryId,
            fitCategoryId: fitCategoryId,
            riseId: '-1',
            fitId: '-1',
            styleId: '-1',
            inseamId: '-1',
            washId: '-1',
            skuId: '-1',


        }
        getStyle(newState);
    }, [fitCategoryId]);

    // useDidMountEffect( () => {
    //     const newState = {
    //         genderId: genderId,
    //         seasonId: seasonId,
    //         currentSeasonId:currentSeasonId,
    //         fabricId:fabricId,
    //         genderCategoryId: genderCategoryId, 
    //         fitCategoryId: fitCategoryId, 
    //         riseId: riseId, 
    //         fitId: '-1', 
    //         styleId: '-1',
    //         inseamId: '-1',
    //         washId:'-1',
    //         skuId: '-1'
    //     }
    //     getFit(newState);
    // }, [riseId]);

    // useDidMountEffect( () => {
    //     const newState = {
    //         genderId: genderId,
    //         seasonId: seasonId,
    //         currentSeasonId:currentSeasonId,
    //         fabricId:fabricId,
    //         genderCategoryId: genderCategoryId, 
    //         fitCategoryId: fitCategoryId, 
    //         riseId: riseId, 
    //         fitId: fitId, 
    //         styleId: '-1', 
    //         inseamId: '-1',
    //         washId:'-1',
    //         skuId: '-1'
    //     }
    //     getStyle(newState);
    // }, [fitId]);

    useDidMountEffect(() => {
        const newState = {
            // locationId: '-1',
            genderId: genderId,
            seasonId: seasonId,
            currentSeasonId: currentSeasonId,
            fabricId: fabricId,
            genderCategoryId: genderCategoryId,
            fitCategoryId: fitCategoryId,
            riseId: riseId,
            fitId: fitId,
            styleId: styleId,
            inseamId: '-1',
            washId: '-1',
            skuId: '-1'
        }
        getWash(newState);
    }, [styleId]);

    // useDidMountEffect( () => {
    //     const newState = {
    //         genderId: genderId,
    //         seasonId: seasonId,
    //         currentSeasonId:currentSeasonId,
    //         fabricId:fabricId,
    //         genderCategoryId: genderCategoryId, 
    //         fitCategoryId: fitCategoryId, 
    //         riseId: riseId, 
    //         fitId: fitId, 
    //         styleId: styleId, 
    //         inseamId: inseamId,
    //         washId:washId,
    //         skuId: '-1'
    //     }
    //     getWash(newState);
    // }, [inseamId]);

    useDidMountEffect(() => {
        const newState = {
            // locationId: '-1',
            genderId: genderId,
            seasonId: seasonId,
            currentSeasonId: currentSeasonId,
            fabricId: fabricId,
            genderCategoryId: genderCategoryId,
            fitCategoryId: fitCategoryId,
            riseId: riseId,
            fitId: fitId,
            styleId: styleId,
            inseamId: inseamId,
            washId: washId,
            skuId: '-1',
        }
        getSKU(newState);
    }, [washId]);

    function getDate() {
        return "&fromDate=" + moment(props.date.fromDate).format('MM/DD/YYYY') + "&toDate=" + moment(props.date.toDate).format('MM/DD/YYYY');
    }

    function getLocation(state) {
        setLocationLoading(true);
        fetch(LOCAL_URL + "stockLocationWiseLocations" + getDate() + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive, { method: 'POST', headers: { 'Authorization': JWT, 'Accept': 'application/json', 'Content-Type': 'application/json' }, body: JSON.stringify(state) })
            .then(res => res.json())
            .then(result => {
                setLocationLoading(false);
                let options = [];
                // Set Items according to the requirement of AutoCompleteSelect component to render options.
                result.forEach(item => {
                    options.push({ value: item.locationid, label: item.locationname });
                });
                setLocation(options); // Set types array in the state so it can call next component to render the options.
            });
    }
    function getGenders(state) {
        setGendersLoading(true);
        fetch(LOCAL_URL + "stockLocationWiseGenders" + getDate() + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive, { method: 'POST', headers: { 'Authorization': JWT, 'Accept': 'application/json', 'Content-Type': 'application/json' }, body: JSON.stringify(state) })
            .then(res => res.json())
            .then(result => {
                setGendersLoading(false);
                let options = [];
                // Set Items according to the requirement of AutoCompleteSelect component to render options.
                result.forEach(item => {
                    options.push({ value: item.genderid, label: item.gendername });
                });
                setGenders(options); // Set types array in the state so it can call next component to render the options.
            });
        getSeason(state);
    }

    function getSeason(state) {
        setSeasonLoading(true);
        fetch(LOCAL_URL + "stockLocationWiseSeasons" + getDate() + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive, { method: 'POST', headers: { 'Authorization': JWT, 'Accept': 'application/json', 'Content-Type': 'application/json' }, body: JSON.stringify(state) })
            .then(res => res.json())
            .then(result => {
                setSeasonLoading(false);
                let options = [];
                // Set Items according to the requirement of AutoCompleteSelect component to render options.
                result.forEach(item => {
                    options.push({ value: item.seasonid, label: item.seasonname });
                });
                setSeason(options); // Set types array in the state so it can call next component to render the options.
            });
        getCurrentSeason(state);
    }

    function getCurrentSeason(state) {
        setCurrentSeasonLoading(true);
        fetch(LOCAL_URL + "stockLocationWiseCurrentSeasons" + getDate() + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive, { method: 'POST', headers: { 'Authorization': JWT, 'Accept': 'application/json', 'Content-Type': 'application/json' }, body: JSON.stringify(state) })
            .then(res => res.json())
            .then(result => {
                setCurrentSeasonLoading(false);
                let options = [];
                // Set Items according to the requirement of AutoCompleteSelect component to render options.
                result.forEach(item => {
                    options.push({ value: item.currentseasonid, label: item.currentseasonname });
                });
                setCurrentSeason(options); // Set types array in the state so it can call next component to render the options.
            });
        getFabric(state);
    }

    function getFabric(state) {
        setFabricLoading(true);
        fetch(LOCAL_URL + "stockLocationWiseFabric" + getDate() + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive, { method: 'POST', headers: { 'Authorization': JWT, 'Accept': 'application/json', 'Content-Type': 'application/json' }, body: JSON.stringify(state) })
            .then(res => res.json())
            .then(result => {
                setFabricLoading(false);
                let options = [];
                // Set Items according to the requirement of AutoCompleteSelect component to render options.
                result.forEach(item => {
                    options.push({ value: item.fabricid, label: item.fabricname });
                });
                setFabric(options); // Set types array in the state so it can call next component to render the options.
            });
        getGendersCategory(state);
    }
    /**
     * 
     * @param genderId // Selected Gender ID we get from AutoCompleteSelect component.
     * Get the Types correspoding to the selected gender id.
     */
    function getGendersCategory(state) {
        setGenderCategoriesLoading(true);
        fetch(LOCAL_URL + "stockLocationWiseGendersCategory" + getDate() + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive, { method: 'POST', headers: { 'Authorization': JWT, 'Accept': 'application/json', 'Content-Type': 'application/json' }, body: JSON.stringify(state) })
            .then(res => res.json())
            .then(genderCategories => {
                setGenderCategoriesLoading(false);
                let options = [];
                // Set Items according to the requirement of AutoCompleteSelect component to render options.
                genderCategories.forEach(item => {
                    options.push({ value: item.gendercategoryid, label: item.gendercategoryname });
                });
                setGenderCategories(options); // Set types array in the state so it can call next component to render the options.
            });
        getFitCategory(state);
    }

    function getFitCategory(state) {
        setFitCategoriesLoading(true);
        fetch(LOCAL_URL + "stockLocationWiseFitCategory" + getDate() + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive, { method: 'POST', headers: { 'Authorization': JWT, 'Accept': 'application/json', 'Content-Type': 'application/json' }, body: JSON.stringify(state) })
            .then(res => res.json())
            .then(fitCategories => {
                setFitCategoriesLoading(false);
                let options = [];
                // Set Fits according to the requirement of AutoCompleteSelect component to render options.
                fitCategories.forEach(item => {
                    options.push({ value: item.fitcategoryid, label: item.fitcategoryname })
                });
                setFitCategories(options); // Set fits array in the state so it can call next component to render the options.
            });
        getRiseLabel(state);
    }

    function getRiseLabel(state) {
        setRisesLoading(true);
        fetch(LOCAL_URL + "stockLocationWiseRise" + getDate() + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive, { method: 'POST', headers: { 'Authorization': JWT, 'Accept': 'application/json', 'Content-Type': 'application/json' }, body: JSON.stringify(state) })
            .then(res => res.json())
            .then(fitCategories => {
                setRisesLoading(false);
                let options = [];
                // Set Fits according to the requirement of AutoCompleteSelect component to render options.
                fitCategories.forEach(item => {
                    options.push({ value: item.riseid, label: item.risename })
                });
                setRises(options); // Set fits array in the state so it can call next component to render the options.
            });
        getFit(state);
    }

    /**
     * 
     * @param typeId // Selected Type ID we get from AutoCompleteSelect component.
     * Get the Fits correspoding to the selected gender id and type id.
     */
    function getFit(state) {
        setFitsLoading(true);
        fetch(LOCAL_URL + "stockLocationWiseFit" + getDate() + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive, { method: 'POST', headers: { 'Authorization': JWT, 'Accept': 'application/json', 'Content-Type': 'application/json' }, body: JSON.stringify(state) })
            .then(res => res.json())
            .then(fits => {
                setFitsLoading(false);
                let options = [];
                // Set Fits according to the requirement of AutoCompleteSelect component to render options.
                fits.forEach(item => {
                    options.push({ value: item.fitid, label: item.fitname })
                });
                setFits(options); // Set fits array in the state so it can call next component to render the options.
            });
        getStyle(state);
    }

    function getStyle(state) {
        setStylesLoading(true);
        fetch(LOCAL_URL + "stockLocationWiseStyle" + getDate() + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive, { method: 'POST', headers: { 'Authorization': JWT, 'Accept': 'application/json', 'Content-Type': 'application/json' }, body: JSON.stringify(state) })
            .then(res => res.json())
            .then(fits => {
                setStylesLoading(false);
                let options = [];
                // Set Fits according to the requirement of AutoCompleteSelect component to render options.
                fits.forEach(item => {
                    options.push({ value: item.styleid, label: item.stylename })
                });
                setStyles(options); // Set fits array in the state so it can call next component to render the options.
            });
        getIneam(state);
    }

    function getIneam(state) {
        setInseamsLoading(true);
        fetch(LOCAL_URL + "stockLocationWiseInseam" + getDate() + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive, { method: 'POST', headers: { 'Authorization': JWT, 'Accept': 'application/json', 'Content-Type': 'application/json' }, body: JSON.stringify(state) })
            .then(res => res.json())
            .then(inseams => {
                setInseamsLoading(false);
                let options = [];
                // Set Fits according to the requirement of AutoCompleteSelect component to render options.
                inseams.forEach(item => {
                    options.push({ value: item.inseamid, label: item.inseamname })
                });
                setInseams(options); // Set fits array in the state so it can call next component to render the options.
            });
        getWash(state);
    }

    function getWash(state) {
        setWashLoading(true);
        fetch(LOCAL_URL + "stockLocationWiseWash" + getDate() + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive, { method: 'POST', headers: { 'Authorization': JWT, 'Accept': 'application/json', 'Content-Type': 'application/json' }, body: JSON.stringify(state) })
            .then(res => res.json())
            .then(wash => {
                setWashLoading(false);
                let options = [];
                // Set Fits according to the requirement of AutoCompleteSelect component to render options.
                wash.forEach(item => {

                    options.push({ value: item.washid, label: item.washname })
                });
                setWash(options); // Set fits array in the state so it can call next component to render the options.
            });
        // getSKU(state);
    }

    function getSKU(state) {
        setSkusLoading(true);
        fetch(LOCAL_URL + "stockLocationWiseSku" + getDate() + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive, { method: 'POST', headers: { 'Authorization': JWT, 'Accept': 'application/json', 'Content-Type': 'application/json' }, body: JSON.stringify(state) })
            .then(res => res.json())
            .then(skus => {
                setSkusLoading(false);
                const options = [];
                // Set Fits according to the requirement of AutoCompleteSelect component to render options.
                skus.map(item => {
                    options.push({ value: item.skuid, label: item.skuname });
                });
                setSkus(options); // Set sku array in the state so it can call next component to render the options.
            });
    }

    return (
        <div className="slt-filters" id="mainfilters">
            <h3 className="filter-title">Filter</h3>
            <div>
                <span className="b-ftr-inr">
                    <label>Location</label>
                    <AutoCompleteSelect list={location} loading={locationLoading} callBack={(a) => { setLocationId(a) }} />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Gender</label>
                    <AutoCompleteSelect list={genders} loading={gendersLoading} callBack={(a) => { setGenderId(a) }} />
                </span>
            </div>
            <div className="hidden">
                <span className="b-ftr-inr">
                    <label>Season</label>
                    <AutoCompleteSelect list={season} loading={seasonLoading} callBack={(a) => { setSeasonId(a) }} />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Current Season</label>
                    <AutoCompleteSelect list={currentSeason} loading={currentSeasonLoading} callBack={(a) => { setCurrentSeasonId(a) }} />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Fabric</label>
                    <AutoCompleteSelect list={fabric} loading={fabricLoading} callBack={(a) => { setFabricId(a) }} />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Gender Category</label>
                    <AutoCompleteSelect list={genderCategories} loading={genderCategoriesLoading} callBack={(a) => { setGenderCategoryId(a) }} />
                </span>
            </div>

            <div>
                <span className="b-ftr-inr">
                    <label>Fit Category</label>
                    <AutoCompleteSelect list={fitCategories} loading={fitCategoriesLoading} callBack={(a) => { setFitCategoryId(a) }} />
                </span>
            </div>
            {/* <div>
                <span className="b-ftr-inr">
                    <label>Rise Label</label>
                    <AutoCompleteSelect list={ rises }  loading={ risesLoading } callBack={ (a) => { setRiseId(a) } }/>
                </span>
            </div> 
            <div>
                <span className="b-ftr-inr">
                    <label>FIT/CUT</label>
                    <AutoCompleteSelect list={ fits } loading={ fitsLoading } callBack={ (a) => { setFitId(a) } }/>
                </span>
            </div>
            */}
            <div>
                <span className="b-ftr-inr">
                    <label>Style</label>
                    <AutoCompleteSelect list={styles} loading={stylesLoading} callBack={(a) => { setStyleId(a) }} />
                </span>
            </div>
            {/* <div>
                <span className="b-ftr-inr">
                    <label>Inseam Label</label>
                    <AutoCompleteSelect list={ inseams } loading={ inseamsLoading } callBack={ (a) => { setInseamId(a) } }/>
                </span>
            </div> */}

            <div>
                <span className="b-ftr-inr">
                    <label>Wash</label>
                    <AutoCompleteSelect list={wash} loading={washLoading} callBack={(a) => { setWashId(a) }} />
                </span>
            </div>

            <div>
                <span className="b-ftr-inr">
                    <label>SKU</label>
                    <Input type='number' name='skuId' callBack={(a) => { setSkuId(a); }} />
                </span>
            </div>
        </div>
    );
}

export default StockLocationWiseFilter;