import React, { useState } from 'react';
import Header from '../../header/header';
import config from '../../../config/config';
import SalesReportFilter from './salesComparisonReportFilter';
import ReportDialog from './../../common/reportDialog';
import { Redirect } from 'react-router';

const SalesComparisonReport = (props) => {

	const [closeReport, setCloseReport] = useState(false);
	const [formState, setFormState] = useState({});
	const [display, setDisplay] = useState(false);
	const [reportUrl, setReportUrl] = useState();
	const [pdfUrl, setPDFUrl] = useState();

	function showTable() {
		let url = config.COMPARE_SALE;
		let pdfUrl = config.SALE_PERSON_PDF_URL;
		setFormState({ ...formState });
		setReportUrl(url);
		setPDFUrl(pdfUrl);
		setDisplay(true);
	}


	const columnAttributes = {
		saleperson: {
			link: true,
			tabs: false,
			type: "booked",
			endPoint: "compareSalesMonth",
			title: "Sales Targets - Detail",
			reportNumber: props.reportNumber,
		},
		region: {
			link: true,
			tabs: false,
			type: "booked",
			endPoint: "compareSalesRegionCustomer",
			title: "Sales Targets Region - Detail",
			reportNumber: props.reportNumber,
		},
	};

	return (
		<div>
			<Header />
			<div className="main-content one-column-layout-filter sale-target-report">
				<div className="report-container group">
					<div className="report-container-header">
						<h1>Sales Targets</h1>
						<button id="close-report-btn" onClick={() => setCloseReport(true)}>
							Close
						</button>
						{
							closeReport == true ? <Redirect to="/dashboard" /> :
								null}
					</div>
					<div className="report-container-inner">
						<div className="r-left">
							<SalesReportFilter
								filterData={(state) => {
									setFormState(state);
								}}
							/>
							<button
								style={{ width: '25%', float: 'right' }}
								className="report-submit-btn"
								onClick={showTable}
								id="genreport"
							>
								SEARCH
							</button>
						</div>
					</div>
				</div>
				<ReportDialog
					excelVersion="Specific"
					columnAttributes={columnAttributes}
					TableClassName="comparisonTable"
					defaultPageSize={15000}
					table="groupTable"
					display={display}
					callBack={() => {
						setDisplay(false);
					}}
					url={reportUrl}
					pdfUrl={pdfUrl}
					//hyperLinks={ stockReportHyperLinks }
					body={formState}
				/>
			</div>
		</div>
	);
};
export default SalesComparisonReport;