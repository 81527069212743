import React, { useEffect, useState } from 'react';
import AutoCompleteSelect from '../../common/autoCompleteSelect';
import useDidMountEffect from '../../common/useDidMountEffect';
import config from '../../../config/config';
import moment from 'moment';

const URL = config.GET_ALL_URL
let JWT;

const OrderByProductFilters = ( props ) => {

    // Selected Ids from the options list in AutoCompleteSelect.
    const [ genderId, setGenderId ] = useState('-1'); // You can set useState(Your default value to be selected in list).
    const [ genderCategoryId, setGenderCategoryId ] = useState('-1');
    const [ salesPersonId, setSalesPersonId ] = useState('-1');
    const [ regionId, setRegionId ] = useState('-1');
    const [ typeId, setTypeId ] = useState('-1');
    const [ customerId, setCustomerId ] = useState('-1'); 
    const [ styleId, setStyleId ] = useState('-1'); 
    const [ washId, setWashId ] = useState('-1');
    const [ sku, setSku ] = useState('-1');
    const [ statusId, setStatusId ] = useState('-1');

    // Array that have data to sent to AutoCompleteSelect component to render options.
    const [ genders, setGenders ] = useState('-1'); 
    const [ gendersCategory, setGendersCategory ] = useState('-1');
    const [ salesPersons, setSalesPersons ] = useState('-1');
    const [ regions, setRegions ] = useState('-1');
    const [ types, setTypes ] = useState('-1');
    const [ customers, setCustomers ] = useState([]);
    const [ styles, setStyles ] = useState([]); 
    const [ washes, setWashes ] = useState([]);
    const [ skus, setSkus ] = useState([]);
    const [ status, setStatus ] = useState([]);
    
    // Loading booleans to be set on AutoComplete.
    const [ gendersLoading, setGendersLoading ] = useState(true);
    const [ gendersCategoryLoading, setGendersCategoryLoading ] = useState(true);
    const [ salesPersonsLoading, setSalesPersonsLoading ] = useState(true);
    const [ regionsLoading, setRegionsLoading ] = useState(true);
    const [ typesLoading, setTypesLoading ] = useState(true);
    const [ customersLoading, setCustomersLoading ] = useState(true);
    const [ stylesLoading, setStylesLoading ] = useState(true);
    const [ washesLoading, setWashesLoading ] = useState(true);
    const [ skusLoading, setSkusLoading ] = useState(true);
    const [ statusLoading, setStatusLoading ] = useState(true);
    
    useState( () => {
        JWT = localStorage.getItem('access_token');
     },[props.date]);

    function getNewState() {
        return { 
            genderId: genderId,
            genderCategoryId: genderCategoryId,
            salesPersonId: salesPersonId,
            regionId: regionId,
            typeId: typeId,
            customerId: customerId, 
            styleId: styleId, 
            washId: washId,
            sku: sku, 
            statusId: statusId, 
        };
    }
    
    useDidMountEffect( () => {
        const newState = {
            genderId: '-1',
            genderCategoryId: '-1',
            salesPersonId: '-1',
            regionId: '-1',
            typeId: '-1',
            customerId: '-1', 
            styleId: '-1', 
            washId: '-1',
            sku: '-1', 
            statusId: '-1'
        }
        getGenders(newState);
    },[props.date]);

    /**
     * Send back data to the parent component i.e CustomerReport whenever selected option changes.
     */
    useDidMountEffect( () => {
        const newState = getNewState();
        props.filterData(newState);
    },[ genderId, genderCategoryId, salesPersonId, regionId, typeId, customerId, styleId, washId, sku, statusId ]);

    useDidMountEffect( () => {
        const newState = {
            genderId: genderId,
            genderCategoryId: '-1',
            salesPersonId: '-1',
            regionId: '-1',
            typeId: '-1',
            customerId: '-1', 
            styleId: '-1', 
            washId: '-1',
            sku: '-1', 
            statusId: '-1'
        }
        getGendesrCategory(newState);
    }, [genderId]);

    useDidMountEffect( () => {
        const newState = {
            genderId: genderId,
            genderCategoryId: genderCategoryId,
            salesPersonId: '-1',
            regionId: '-1',
            typeId: '-1',
            customerId: '-1', 
            styleId: '-1', 
            washId: '-1',
            sku: '-1', 
            statusId: '-1'
        }
        getSalesPerson(newState);
    }, [genderCategoryId]);

    useDidMountEffect( () => {
        const newState = {
            genderId: genderId,
            genderCategoryId: genderCategoryId,
            salesPersonId: salesPersonId,
            regionId: '-1',
            typeId: '-1',
            customerId: '-1', 
            styleId: '-1', 
            washId: '-1',
            sku: '-1', 
            statusId: '-1'
        }
        getRegion(newState);
    }, [salesPersonId]);

    useDidMountEffect( () => {
        const newState = {
            genderId: genderId,
            genderCategoryId: genderCategoryId,
            salesPersonId: salesPersonId,
            regionId: regionId,
            typeId: '-1',
            customerId: '-1', 
            styleId: '-1', 
            washId: '-1',
            sku: '-1', 
            statusId: '-1'
        }
        getType(newState);
    }, [regionId]);

    useDidMountEffect( () => {
        const newState = {
            genderId: genderId,
            genderCategoryId: genderCategoryId,
            salesPersonId: salesPersonId,
            regionId: regionId,
            typeId: typeId,
            customerId: '-1', 
            styleId: '-1', 
            washId: '-1',
            sku: '-1', 
            statusId: '-1'
        }
        getCustomers(newState);
    }, [typeId]);

    useDidMountEffect( () => {
        const newState = {
            genderId: genderId,
            genderCategoryId: genderCategoryId,
            salesPersonId: salesPersonId,
            regionId: regionId,
            typeId: typeId,
            customerId: customerId, 
            styleId: '-1', 
            washId: '-1',
            sku: '-1', 
            statusId: '-1'
        }
        getStyle(newState);
    }, [customerId]);

    useDidMountEffect( () => {
        const newState = {
            genderId: genderId,
            genderCategoryId: genderCategoryId,
            salesPersonId: salesPersonId,
            regionId: regionId,
            typeId: typeId,
            customerId: customerId, 
            styleId: styleId, 
            washId: '-1',
            sku: '-1', 
            statusId: '-1'
        }
        getWash(newState);
    }, [styleId]);

    useDidMountEffect( () => {
        const newState = {
            genderId: genderId,
            genderCategoryId: genderCategoryId,
            salesPersonId: salesPersonId,
            regionId: regionId,
            typeId: typeId,
            customerId: customerId, 
            styleId: styleId, 
            washId: washId,
            sku: '-1', 
            statusId: '-1'
        }
        getSKU(newState);
    }, [washId]);

    useDidMountEffect( () => {
        const newState = {
            genderId: genderId,
            genderCategoryId: genderCategoryId,
            salesPersonId: salesPersonId,
            regionId: regionId,
            typeId: typeId,
            customerId: customerId, 
            styleId: styleId, 
            washId: washId,
            sku: sku, 
            statusId: '-1'
        }
        getStatus(newState);
    }, [sku]);

    function getDate() {
        return "&fromDate=" + moment(props.date.fromDate).format('MM/DD/YYYY') + "&toDate=" + moment(props.date.toDate).format('MM/DD/YYYY');
    }

    function getGenders(state) {
        setGendersLoading(true);
        fetch( URL + "saleOrderProductGender" + getDate(),{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setGendersLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.genderid, label: item.gendername } );
            });
            setGenders( options ); // Set array in the state so it can call next component to render the options.
        });
        getGendesrCategory(state);
    }

    function getGendesrCategory(state) {
        setGendersCategoryLoading(true);
        fetch( URL + "saleOrderProductGenderCategory" + getDate(),{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setGendersCategoryLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.gendercategoryid, label: item.gendercategoryname } );
            });
            setGendersCategory( options ); // Set array in the state so it can call next component to render the options.
        });
        getSalesPerson(state);
    }

    function getSalesPerson(state) {
        setSalesPersonsLoading(true);
        fetch( URL + "saleOrderProductSalePerson" + getDate(),{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setSalesPersonsLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.salepersonid, label: item.salepersonname } );
            });
            setSalesPersons( options ); // Set array in the state so it can call next component to render the options.
        });
        getRegion(state);
    }

    function getRegion(state) {
        setRegionsLoading(true);
        fetch( URL + "saleOrderProductRegion" + getDate(),{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setRegionsLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.regionid, label: item.regionname } );
            });
            setRegions( options ); // Set array in the state so it can call next component to render the options.
        });
        getType(state);
    }

    function getType(state) {
        setTypesLoading(true);
        fetch( URL + "saleOrderProductCustomerType" + getDate(),{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setTypesLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.typeid, label: item.typename } );
            });
            setTypes( options ); // Set array in the state so it can call next component to render the options.
        });
        getCustomers(state);
    }
    function getCustomers(state) {
        setCustomersLoading(true);
        fetch( URL + "saleOrderProductCustomers" + getDate(),{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setCustomersLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.customerid, label: item.customername } );
            });
            setCustomers( options ); // Set array in the state so it can call next component to render the options.
        });
        getStyle(state);
    }

    function getStyle(state) {
        setStylesLoading(true);
        fetch( URL + "saleOrderProductStyle" + getDate() ,{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setStylesLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.styleid, label: item.stylename } );
            });
            setStyles( options ); // Set array in the state so it can call next component to render the options.
        });
        getWash(state);
    }

    function getWash(state) {
        setWashesLoading(true);
        fetch( URL + "saleOrderProductWash" + getDate(),{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setWashesLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.washid, label: item.washname } );
            });
            setWashes( options ); // Set array in the state so it can call next component to render the options.
        });
        getSKU(state);
    }

    function getSKU(state) {
        setSkusLoading(true);
        fetch( URL + "saleOrderProductSku" + getDate(),{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setSkusLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.skuid, label: item.sku } );
            });
            setSkus( options ); // Set array in the state so it can call next component to render the options.
        });
        getStatus(state);
    }

    function getStatus(state) {
        setStatusLoading(true);
        fetch( URL + "saleOrderProductOrderStatus" + getDate(),{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setStatusLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.statusid, label: item.statusname } );
            });
            setStatus( options ); // Set array in the state so it can call next component to render the options.
        });
    }

    return(
        <div className="slt-filters" id="mainfilters">
            <h3 className="filter-title">Filter</h3>
            <div>
                <span className="b-ftr-inr">
                    <label>Gender</label>
                    <AutoCompleteSelect list={genders} loading={gendersLoading} callBack={ (a) => { setGenderId(a) } } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Gender Category</label>
                    <AutoCompleteSelect list={gendersCategory} loading={gendersCategoryLoading} callBack={ (a) => { setGenderCategoryId(a) } } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Sales Person</label>
                    <AutoCompleteSelect list={salesPersons} loading={salesPersonsLoading} callBack={ (a) => { setSalesPersonId(a) } } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Region</label>
                    <AutoCompleteSelect list={regions} loading={regionsLoading} callBack={ (a) => { setRegionId(a) } } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Type</label>
                    <AutoCompleteSelect list={types} loading={typesLoading} callBack={ (a) => { setTypeId(a) } } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Customer</label>
                    <AutoCompleteSelect list={customers} loading={customersLoading} callBack={ (a) => { setCustomerId(a) } } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Style</label>
                    <AutoCompleteSelect list={styles} loading={stylesLoading} callBack={ (a) => { setStyleId(a) } } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Wash</label>
                    <AutoCompleteSelect list={washes} loading={washesLoading} callBack={ (a) => { setWashId(a) } } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>SKU</label>
                    <AutoCompleteSelect list={skus} loading={skusLoading} callBack={ (a) => { setSku(a) } } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Order Status</label>
                    <AutoCompleteSelect list={status} loading={statusLoading} callBack={ (a) => { setStatusId(a) } } />
                </span>
            </div>
        </div>
    );
}

export default OrderByProductFilters;