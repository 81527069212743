import React, {useEffect, useState} from 'react';
import Header from './../../../header/header';
import config from './../../../../config/config';
import {NavLink} from 'react-router-dom';
import {Redirect} from 'react-router';
import moment from 'moment';
import Table from './../../../common/HTMLTable';
import {getExcelStandard} from '../../../../helper/excel';
import { montFromDate, monthToDate, yearFromDate, yearToDate } from '../../../../helper/date';

let JWT;
let fromDate;
let toDate;

const BookingByRegion = (props) => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [closeReport, setCloseReport] = useState(false);
	const [reportName, setReportName] = useState(props.reportName);
	const [reportType, setReportType] = useState(props.reportType);
	const [reportNumber, setReportNumber] = useState(props.reportNumber);
	const [genderId, setGenderId] = useState(props.genderId);
	const [formState, setFormState] = useState({ genderId: genderId });
	const URL = config.BOOKING_BY_REGION;

	const columnAttributes = {
		'region': {
			link:true,
			tabs: false,
			endPoint: 'dashboardBookingByRegionDetail',
			title: props.reportName + ' - Detail',
			reportNumber: props.reportNumber,
			popup: {
				'orderno': {
					link:true,
					tabs: false,
					endPoint: 'dashboardBookingBySPOrderDetail',
					title: 'Order Detail',
					reportNumber: props.reportNumber
				}
			}
		}
	}

	useEffect(
		() => {
			JWT = localStorage.getItem('access_token');
			if(props.reportType)fetchData();
		},
		[props]
	);

	async function fetchData() {
		setLoading(true);
		fromDate = reportType == 'ytd' ? yearFromDate() : montFromDate();
		toDate = reportType == 'ytd' ? yearToDate() : monthToDate();
		let url = URL;
		setFormState({
			fromDate: fromDate,
			toDate: toDate,
		});

		url += '&fromDate=' + moment(fromDate).format('MM/DD/YYYY') + '&toDate=' + moment(toDate).format('MM/DD/YYYY');
		const response = await fetch(url, {
			method: 'POST',
			headers: { Authorization: JWT, Accept: 'application/json', 'Content-Type': 'application/json' },
			body: JSON.stringify(formState)
		});
		const data1 = await response.json();
		data1.reportName = 'Booking Leaderboard - MTD';		
		setData(data1);
		setLoading(false);
	}

	return (
		<div>
			<Header />
			<div className="dash-body">
				<div style={{paddingLeft: '0%', paddingRight: '0%', marginTop: '-2%'}}>
					<div className="report-result-head">
						<h2>{reportName}</h2> <br />
						<h2>{reportNumber}</h2>
						<div className="navigation-area">
							<NavLink activeClassName="selected" className="link-react" to="bookings-by-region-ytd">
								ALL-YTD
							</NavLink>

							<NavLink activeClassName="selected" className="link-react" to="bookings-by-region-mtd">
								ALL-MTD
							</NavLink>

							<NavLink
								activeClassName="selected"
								className="link-react"
								to="bookings-by-region-women-ytd"
							>
								WOMEN-YTD
							</NavLink>

							<NavLink
								activeClassName="selected"
								className="link-react"
								to="bookings-by-region-women-mtd"
							>
								WOMEN-MTD
							</NavLink>

							<NavLink
								activeClassName="selected"
								className="link-react"
								to="bookings-by-region-men-ytd"
							>
								MEN-YTD
							</NavLink>

							<NavLink
								activeClassName="selected"
								className="link-react"
								to="bookings-by-region-men-mtd"
							>
								MEN-MTD
							</NavLink>

							<NavLink
								activeClassName="selected"
								className="link-react"
								to="bookings-by-region-kids-ytd"
							>
								KIDS-YTD
							</NavLink>

							<NavLink
								activeClassName="selected"
								className="link-react"
								to="bookings-by-region-kids-mtd"
							>
								KIDS-MTD
							</NavLink>
							<NavLink
								activeClassName="selected"
								className="link-react"
								to="bookings-by-region-pets-ytd"
							>
								PET-YTD
							</NavLink>

							<NavLink
								activeClassName="selected"
								className="link-react"
								to="bookings-by-region-pets-mtd"
							>
								PET-MTD
							</NavLink>
						</div>
						{props.reportType ? <div className="report-result-right-top">
							<button id="close-dialog-btn" onClick={() => setCloseReport(true)}>
								Close
							</button>
							<button
								id="download-excel-button"
								onClick={() => {
									getExcelStandard(data);
								}}
							>
								Download EXCEL
							</button>
							{
								closeReport == true ? <Redirect to="/dashboard" /> :
									null}
						</div> : ''}
					</div>
					<div className="report-data">
						{props.reportType ? <Table popUpClassName='booking-by-region-popup-table'
							body={formState}
							columnAttributes={columnAttributes}
							display={true}
							data={data}
							loading={loading} /> : ''}
					</div>
				</div>
			</div>
		</div>
	);
};
export default BookingByRegion;
