import React, {useState} from 'react';
import Header from '../../header/header';
import config from '../../../config/config';
import Input from './../../common/input';
import {Redirect} from 'react-router';
import AutoCompleteSelect from '../../common/autoCompleteSelect';

const Invoice = (props) => {
	const [closeReport, setCloseReport] = useState(false);
	const [pdfUrl, setPDFUrl] = useState(config.INVOICE_PDF_URL);
	const [invoiceNumber, setInvoiceNumber] = useState(true);
	const [body, setBody] = useState({});
	const [format, setFormat] = useState([{value: 'normal', label: 'Normal'}, {value: 'cit', label: 'Cit'},{value: 'commercialInvoice', label: 'Commercial Invoice'}]);
	const [formatLoading, setFormatLoading] = useState(false);
	const [formatId, setFormatId] = useState('-1'); // You can set useState(Your default value to be selected in list).

	async function getPDF(){
		var pair = {invoiceNumber: invoiceNumber, format: formatId};
		const win = window.open(pdfUrl + '&payload=' + JSON.stringify({...body, ...pair}), '_blank');
		win.focus();
	}

	return (
		<div>
			<Header />
			<div className="main-content one-column-layout-filter">
				<div className="report-container group">
					<div className="report-container-header">
						<h1>Invoice</h1>
						<button id="close-report-btn" onClick={() => setCloseReport(true)}>
							Close
						</button>
						{
							closeReport == true ? <Redirect to="/dashboard" /> :
							null}
					</div>
					<div className="report-container-inner">
						<div className="r-left">
							<div className="slt-filters" id="mainfilters">
								<h3 className="filter-title">Filter</h3>
								<div>
									<span className="b-ftr-inr">
										<label>Invoice #</label>
										<Input callBack={setInvoiceNumber} type="text" name="invoice" />
									</span>
								</div>
								<div>
									<span className="b-ftr-inr">
										<label>Format</label>
										<AutoCompleteSelect
											list={format}
											defaultValue={1}
											loading={formatLoading}
											callBack={(a) => {
												if(a ==='commercialInvoice'){
													setPDFUrl(config.COMMERCIAL_INVOICE_PDF_URL);
												}
												else {
													setPDFUrl(config.INVOICE_PDF_URL);
												}
												setFormatId(a);
											}}
										/>
									</span>
								</div>
							</div>						
							<button
								style={{width: '25%', float: 'right'}}
								className="report-submit-btn"
								onClick={() => getPDF(invoiceNumber)}
								id="genreport"
							>
								Print Preview
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Invoice;