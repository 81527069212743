import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Header from '../../header/header';
import Date from '../../common/date';
import config from '../../../config/config';
import ReportDialog from '../../common/reportDialog';
import { Redirect } from 'react-router';
// ARAJ
import DetailReportFilter from './goodsRequiredFilter';
import ProductStatus from './../../common/productStatus'
// ARAJ
let URL = config.GOOD_REQUIRED;
const PDF_URL = config.GOODS_REQUIRED_PDF_URL;
const SKU_URL = config.GET_ALL_URL;
const PRODUCT_STATUS = "&productStatus=";

const DetailReport = () => {
	const [reorder, setReorder] = useState({ srno: 0, sku: 1, inseam: 2, wash: 3, location: 4 });
	const [closeReport, setCloseReport] = useState(false);
	// Report filters
	const [date, setDate] = useState({});
	const [formState, setFormState] = useState({});
	const [display, setDisplay] = useState(false);
	const [reportUrl, setReportUrl] = useState();
	const [pdfUrl, setPDFUrl] = useState();
	const [skuUrl, setSKUUrl] = useState(SKU_URL);
	const [pdfReportId, setPdfReportId] = useState();
	const hyperLinks = [];
	const hyperLinksUrl = ['', '', 'productionDetail'];
	const popupUrl = 'productionDetail';

	// ARAJ
	const [searchState, setSearchState] = useState(false);
	const [selectedProductStatus, setSelectedProductStatus] = useState('1');
	// ARAJ

	console.log("dataee", date)
	function showTable() {
		setFormState({ ...formState, fromdate: moment(date.fromDate).format('MM/DD/YYYY') });
		let url = URL + PRODUCT_STATUS + selectedProductStatus;
		//	url += '&fromDate=' + moment(date.fromDate).format('MM/DD/YYYY');
		setPDFUrl(pdfUrl);
		setReportUrl(url);
		setDisplay(true);
	}

	useEffect(() => {
		document.getElementById('genreport').disabled = true
		document.getElementById('genreport').classList.add('disabled');
	}, [])


	return (
		<div>
			<Header />
			<div className="main-content goods-required-filter">
				<div className="report-container group">
					<div className="report-container-header">
						<h1>Goods Required</h1>
						<button id="close-report-btn" onClick={() => setCloseReport(true)}>
							Close
						</button>
						{
							closeReport == true ? <Redirect to="/dashboard" /> :
								null}
					</div>
					<div className="report-container-inner">
						<div className="r-left">
							<Date
								label="Date"
								dateData={(date) => setDate(date)}
								defaultDate={{ fromDate: moment().format('YYYY-MM-DD') }}
								onChange
							/>
							{/* ARAJ */}
							<ProductStatus url='goodsRequiredProductStatus' statusData={(id) => { setSelectedProductStatus(id); }} />
							<DetailReportFilter
								productStatus={selectedProductStatus}
								filterData={(state) => {
									setFormState(state);
								}}
								searchState={searchState}
							/>
							{/* ARAJ */}
							<button
								style={{
									width: '25%',
									float: 'right'
								}}
								className="report-submit-btn"
								id="genreport"
								onClick={showTable}
							>
								SEARCH
							</button>
						</div>
					</div>
				</div>
				<ReportDialog
					TableClassName="goodRequiredTable"
					reorder={reorder}
					defaultPageSize={100}
					display={display}
					callBack={() => {
						setDisplay(false);
					}}
					url={reportUrl}
					pdfUrl={PDF_URL}
					skuUrl={skuUrl}
					hyperLinks={hyperLinks}
					hyperLinksUrl={hyperLinksUrl}
					popupUrl={popupUrl}
					body={formState}
					pdfReportId={pdfReportId}
					fromDate={date.fromDate}
					toDate={date.toDate}
				/>
			</div>
		</div>
	);
};
export default DetailReport;