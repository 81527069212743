import moment from 'moment';
import React, { useEffect } from 'react';

const Date = (props) => {
    console.log("props", props)

    /**
     * Return date to its parent component whenever date changes by the user.
     */
    function returnDate() {
        const fromDate = document.getElementById('fromdate').value;
        props.dateData({ fromDate: fromDate });
    }

    /**
     * Get today date from moment.js and set in both date picker. Also send selected default ( today Date ) to parent component.
     * Should be called on first render of component.
     */
    function setDate() {
        //const today = moment(new Date()).format('YYYY-MM-DD');
        if (props.defaultDate != null) {
            document.getElementById('fromdate').value = props.defaultDate.fromDate;
            props.dateData(props.defaultDate);
        } else {
            // Send fromDate and toDate to parent component on first render which is today date.
            const today = moment(new Date()).format('YYYY-MM-DD');
            document.getElementById('fromdate').value = today;
            props.dateData({ fromDate: today });
        }

    }

    /**
     * Call function setDate on first render of component.
     */
    useEffect(() => {
        setDate();
    }, []); // Passing empty array will only call useEffect on first render of component.

    return (
        <div className="date-filter">
            <h3 className="filter-title">{props.label ? props.label : 'Pending Order As On'}</h3>
            <form>
                <div className="dt-inr-left dt-inr w-100">
                    <label>Date</label>
                    <input type="date" id="fromdate" onChange={returnDate} />
                </div>
            </form>
        </div>
    );
}

export default Date;