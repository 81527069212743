import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

function getColumnIds(data) {
	let names = [];
	data.columns.forEach((column) => {
		names.push(column.field);
	});
	return names;
}

function getColumnNames(data) {
	let names = [];
	data.columns.forEach((column) => {
		names.push(column.title);
	});
	return names;
}

function getRows(data) {
	let allData = [];
	//if rows < column then push extra rows

	// if (data.reportName === 'Goods Required') {
	data.rows.forEach(function (element) {
		element.c50 = 'false';
		element.c51 = 'false';
		element.c53 = 'false';
		element.c54 = 'false';
		element.c55 = 'false';
		element.c56 = 'false';
	});
	// }

	const ids = getColumnIds(data);
	data.rows.forEach((row) => {
		let dataArray = [];
		Object.entries(row).forEach((e, index) => {
			let field = row[ids[index]];
			if (data.reportName === 'Goods Required') {
				if (index == 7) {
					field = '-';
				}
			}
			const a = data.columns[index];
			if (a != null && a.convert != null) {
				if (field != null && field != '' && typeof field == 'string' && field.includes('$')) {
					field = field.split('$')[1];
				}

				if (field != null && field != '' && typeof field == 'string' && field.includes('%')) {
					field = field.replace('%', '')
				}

				if (field != null && field != '' && typeof field == 'string' && field.includes(',')) {
					field = field.split(',').join('');
				}
				field = Number(field);
			}
			dataArray.push(field);
		});
		allData.push(dataArray);
	});
	return allData;
}

const getExcelStandard = (data) => {
	var wb = window.XLSX.utils.book_new();
	data.reportName = 'DownloadExcel';

	wb.Props = {
		Title: data.reportName,
		Subject: data.reportName,
		Author: 'fascom',
		CreatedDate: new Date()
	};

	wb.SheetNames.push(data.reportName);
	let excelData = [];
	//console.log(JSON.stringify(data));
	const columns = getColumnNames(data);
	excelData.push(columns);
	getRows(data).forEach(function (row, j) {
		for (let i = 0; i < row.length; i++) {
			if (Number.isNaN(row[i])) {
				row[i] = '';
			}
		}
		excelData.push(row);
	});
	var ws = window.XLSX.utils.aoa_to_sheet(excelData);
	let objectMaxLength = [];
	excelData.map((arr) => {
		Object.keys(arr).map((key) => {
			let value = arr[key] === null ? '' : arr[key];

			if (typeof value === 'number') {
				return (objectMaxLength[key] = 10);
			}

			if (typeof value === 'string') {
				return (objectMaxLength[key] = value.length + 10);
			}
			// objectMaxLength[key] = value != null && value.length > 0 ? objectMaxLength[key] >= value ? objectMaxLength[key] + 0.01 : value.length + 0.01 : objectMaxLength[key];
		});
	});

	let worksheetCols = objectMaxLength.map((width) => {
		return { width };
	});
	ws['!cols'] = worksheetCols;
	wb.Sheets[data.reportName] = ws;
	var wbout = window.XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
	saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), `${data.reportName}.xlsx`);
	function s2ab(s) {
		var buf = new ArrayBuffer(s.length);
		var view = new Uint8Array(buf);
		for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
		return buf;
	}
};

const getExcelSpecific = (data) => {
	let workbook = XLSX.utils.book_new();
	let worksheet_header = document.querySelector('.ant-table-header table .ant-table-thead')  || 
	document.querySelector('.popUpTable .ant-table-header table .ant-table-thead')  || 
	document.querySelector('.popUpTableComparison .ant-table-header table .ant-table-thead');

	if(!data.reportName){
		worksheet_header = document.querySelector('.popUpTableComparison .ant-table-header table .ant-table-thead');
	}

	let worksheet_header_clone = worksheet_header.cloneNode(true);
	let worksheet_body = document.querySelector('.ant-table-body table') || 
						 document.querySelector('.popUpTableComparison .ant-table-body table') || 
						 document.querySelector('.popUpTable .ant-table-body table') ;


		
	if(!data.reportName){
		worksheet_body=document.querySelector('.popUpTableComparison .ant-table-body table');
	}
						 
	let worksheet = worksheet_body.cloneNode(true);
	let formatReport = false;

	if(data.reportName === 'Sale Comparison'){
		formatReport = false;
	}
	//Remove Percentage
	//DON, remove last row
	if (
		data.reportName === 'Business Dashboard' ||
		data.reportName === 'Booking Leaderboard - MTD' ||
		data.reportName === 'Booking Leaderboard - YTD' 
		
	) {

		let x = worksheet.rows.length;
		let html = '<tbody>';
		for (let i = 1; i < x; i++) {
			if (i === 1) continue;
			html += '<tr>';
			var tr = document.querySelector('tbody tr')[i];
			var tdl = tr.getElementsByTagName('td').length;
			for (let j = 0; j < tdl; j++) {
				var td = tr.getElementsByTagName('td')[j];
				let field = td.innerHTML.replace('%','');
				html += '<td>' + field + '</td>';
			}
			html += '</tr>';
		}
		html += '</tbody>';
		worksheet.querySelector('.ant-table-tbody').remove();
		worksheet.innerHTML = html;
	}

	else if (data.reportName === 'Sale Comparison') { 
		worksheet.querySelector('.ant-table-tbody tr:first-child').remove();
		console.log('work',worksheet)
		let x = worksheet.rows.length;
		let html = '<tbody>';
		for (let i = 0; i < x; i++) {
			html += '<tr>';
			for(let j = 0; j < worksheet.rows[i].cells.length ; j ++){

				if(j==18){
					let field = ''+worksheet.rows[i].cells[j].innerText.toString();
					html += '<td style="text-align:right">' + field+ '</td>';
				}		
				else {
				html += '<td>' + worksheet.rows[i].cells[j].innerText.toString()+ '</td>';
			}
		}
		html += '</tr>';
	}
	html += '</tbody>';
	worksheet.querySelector('.ant-table-tbody').remove();
	worksheet.innerHTML = html;
	}
	else {
		worksheet.querySelector('.ant-table-tbody tr:first-child').remove();
	}

	worksheet.prepend(worksheet_header_clone);
	var worksheet_body_table = XLSX.utils.table_to_sheet(worksheet);
	workbook.SheetNames.push('Sheet');
	workbook.Sheets['Sheet'] = worksheet_body_table;
	return XLSX.writeFile(workbook, 'DownloadExcel.xlsx');
};
export { getExcelSpecific, getExcelStandard };