import React, { useEffect, useState } from 'react';
import AutoCompleteSelect from '../../../common/autoCompleteSelect';
import useDidMountEffect from '../../../common/useDidMountEffect';
import config from './../../../../config/config';

const LOCAL_URL = config.GET_ALL_URL;
const PRODUCT_STATUS = "&productStatus=";
const IS_INACTIVE = "&isInActive="
let JWT;

const ProductReportFilter = ( props ) => {

    // Selected Ids from the options list in AutoCompleteSelect.
    const [ genderId, setGenderId ] = useState('-1'); // You can set useState(Your default value to be selected in list).
    const [ genderCategoryId, setGenderCategoryId ] = useState('-1');
    const [ fitCategoryId, setFitCategoryId ] = useState('-1');
    const [ riseId, setRiseId ] = useState('-1');
    const [ fitId, setFitId ] = useState('-1');
    const [ inseamId, setInseamId ] = useState('-1');
    const [ styleId, setStyleId ] = useState('-1');
    const [ skuId, setSkuId ] = useState('-1');

    // Array that have data to sent to AutoCompleteSelect component to render options.
    const [ genders, setGenders ] = useState([]); 
    const [ genderCategories, setGenderCategories ] = useState([]);
    const [ fitCategories, setFitCategories ] = useState([]);
    const [ rises, setRises ] = useState([]);
    const [ fits, setFits ] = useState([]);
    const [ inseams, setInseams ] = useState([]);
    const [ styles, setStyles ] = useState([]);
    const [ skus, setSkus ] = useState([]);

    // Loading booleans to be set on AutoComplete.
    const [ gendersLoading, setGendersLoading ] = useState(true);
    const [ genderCategoriesLoading, setGenderCategoriesLoading ] = useState(true);
    const [ fitCategoriesLoading, setFitCategoriesLoading ] = useState(true);
    const [ risesLoading, setRisesLoading ] = useState(true);
    const [ fitsLoading, setFitsLoading ] = useState(true);
    const [ inseamsLoading, setInseamsLoading ] = useState(true);
    const [ stylesLoading, setStylesLoading ] = useState(true);
    const [ skusLoading, setSkusLoading ] = useState(true);

    function getNewState() {
        return { 
            genderId: genderId, 
            genderCategoryId: genderCategoryId, 
            fitCategoryId: fitCategoryId, 
            riseId: riseId, 
            fitId: fitId, 
            inseamId: inseamId, 
            styleId: styleId,
            skuId:skuId,
        };
    }

    useState( () => {
       JWT = localStorage.getItem('access_token');
    },[]);

    /**
     * Send back data to the parent component i.e ProductReport whenever selected option changes.
     */
    useDidMountEffect( () => {
        const newState = getNewState();
        props.filterData(newState);
    },[ genderId, genderCategoryId, fitCategoryId, riseId, fitId, inseamId, styleId, skuId ]);

    
    useEffect( () => {
        const newState = {
            genderId: '-1', 
            genderCategoryId: '-1', 
            fitCategoryId: '-1', 
            riseId: '-1', 
            fitId: '-1',
            styleId: '-1',  
            inseamId: '-1', 
            skuId: '-1'
        }
        getGenders(newState);
    },[props.productStatus, props.isInActive]);

    useDidMountEffect( () => {
        const newState = {
            genderId: genderId, 
            genderCategoryId: '-1', 
            fitCategoryId: '-1', 
            riseId: '-1', 
            fitId: '-1',
            styleId: '-1',  
            inseamId: '-1', 
            skuId: '-1'
        }
        getGendersCategory(newState);
    }, [genderId]);

    useDidMountEffect( () => {
        const newState = {
            genderId: genderId, 
            genderCategoryId: genderCategoryId, 
            fitCategoryId: '-1', 
            riseId: '-1', 
            fitId: '-1', 
            styleId: '-1',
            inseamId: '-1', 
            skuId: '-1'
        }
        getFitCategory(newState);
    }, [genderCategoryId]);
   
    useDidMountEffect( () => {
        const newState = {
            genderId: genderId, 
            genderCategoryId: genderCategoryId, 
            fitCategoryId: fitCategoryId, 
            riseId: '-1', 
            fitId: '-1',
            styleId: '-1',  
            inseamId: '-1', 
            skuId: '-1'
        }
        getRiseLabel(newState);
    }, [fitCategoryId]);

    useDidMountEffect( () => {
        const newState = {
            genderId: genderId, 
            genderCategoryId: genderCategoryId, 
            fitCategoryId: fitCategoryId, 
            riseId: riseId, 
            fitId: '-1', 
            styleId: '-1',
            inseamId: '-1', 
            skuId: '-1'
        }
        getFit(newState);
    }, [riseId]);

    useDidMountEffect( () => {
        const newState = {
            genderId: genderId, 
            genderCategoryId: genderCategoryId, 
            fitCategoryId: fitCategoryId, 
            riseId: riseId, 
            fitId: fitId, 
            styleId: '-1', 
            inseamId: '-1', 
            skuId: '-1'
        }
        getStyle(newState);
    }, [fitId]);

    useDidMountEffect( () => {
        const newState = {
            genderId: genderId, 
            genderCategoryId: genderCategoryId, 
            fitCategoryId: fitCategoryId, 
            riseId: riseId, 
            fitId: fitId, 
            styleId: styleId, 
            inseamId: '-1', 
            skuId: '-1'
        }
        getIneam(newState);
    }, [styleId]);

    useDidMountEffect( () => {
        const newState = {
            genderId: genderId, 
            genderCategoryId: genderCategoryId, 
            fitCategoryId: fitCategoryId, 
            riseId: riseId, 
            fitId: fitId, 
            styleId: styleId, 
            inseamId: inseamId, 
            skuId: '-1'
        }
        getSKU(newState);
    }, [inseamId]);

    function getGenders(state) {
        setGendersLoading(true);
        fetch( LOCAL_URL + "productGenders" + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive ,{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setGendersLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.genderid, label: item.gendername } );
            });
            setGenders( options ); // Set types array in the state so it can call next component to render the options.
        });
        getGendersCategory(state);
    }

    /**
     * 
     * @param genderId // Selected Gender ID we get from AutoCompleteSelect component.
     * Get the Types correspoding to the selected gender id.
     */
    function getGendersCategory(state) {
        setGenderCategoriesLoading(true);
        const data = fetch( LOCAL_URL + "productGendersCategory" + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive, { method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state) })
        .then( res => res.json() )
        .then( genderCategories => {
            setGenderCategoriesLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            genderCategories.forEach( item => {
                options.push( { value: item.gendercategoryid, label: item.gendercategoryname } );
            });
            setGenderCategories( options ); // Set types array in the state so it can call next component to render the options.
        });       
        getFitCategory(state);
    }

    
    function getFitCategory(state) {
        setFitCategoriesLoading(true);
        fetch( LOCAL_URL + "productFitCategory" + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive, { method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state) })
        .then( res => res.json() )
        .then( fitCategories => {
            setFitCategoriesLoading( false );
            let options = [];
            // Set Fits according to the requirement of AutoCompleteSelect component to render options.
            fitCategories.forEach( item => {
                options.push( { value: item.fitcategoryid, label: item.fitcategoryname } )
            });
            setFitCategories( options ); // Set fits array in the state so it can call next component to render the options.
        });
        getRiseLabel(state);
    }


    function getRiseLabel(state) {
        setRisesLoading(true);
        fetch( LOCAL_URL + "productRise" + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive, { method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state) })
        .then( res => res.json() )
        .then( fitCategories => {
            setRisesLoading( false );
            let options = [];
            // Set Fits according to the requirement of AutoCompleteSelect component to render options.
            fitCategories.forEach( item => {
                options.push( { value: item.riseid, label: item.risename } )
            });
            setRises( options ); // Set fits array in the state so it can call next component to render the options.
        });        
        getFit(state);
    }

    /**
     * 
     * @param typeId // Selected Type ID we get from AutoCompleteSelect component.
     * Get the Fits correspoding to the selected gender id and type id.
     */
    function getFit(state) {
        setFitsLoading(true);
        fetch( LOCAL_URL + "productFit" + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive, { method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state) })
        .then( res => res.json() )
        .then( fits => {
            setFitsLoading( false );
            let options = [];
            // Set Fits according to the requirement of AutoCompleteSelect component to render options.
            fits.forEach( item => {
                options.push( { value: item.fitid, label: item.fitname } )
            });
            setFits( options ); // Set fits array in the state so it can call next component to render the options.
        });        
        getStyle(state);
    }

    function getStyle(state) {
        setStylesLoading(true);
        fetch( LOCAL_URL + "productStyle" + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive, { method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state) })
        .then( res => res.json() )
        .then( fits => {
            setStylesLoading( false );
            let options = [];
            // Set Fits according to the requirement of AutoCompleteSelect component to render options.
            fits.forEach( item => {
                options.push( { value: item.styleid, label: item.stylename } )
            });
            setStyles( options ); // Set fits array in the state so it can call next component to render the options.
        });   
        getIneam(state);
    }

    function getIneam(state) {
        setInseamsLoading(true);
        fetch( LOCAL_URL + "productInseam" + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive, { method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state) })
        .then( res => res.json() )
        .then( inseams => {
            setInseamsLoading( false );
            let options = [];
            // Set Fits according to the requirement of AutoCompleteSelect component to render options.
            inseams.forEach( item => {
                options.push( { value: item.inseamid, label: item.inseamname } )
            });
            setInseams( options ); // Set fits array in the state so it can call next component to render the options.
        });        
        getSKU(state);
    }

    function getSKU(state) {
        setSkusLoading(true);
        fetch( LOCAL_URL + "productSku" + PRODUCT_STATUS + props.productStatus + IS_INACTIVE + props.isInActive, { method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state) })
        .then( res => res.json() )
        .then( skus => {
            setSkusLoading(false);
            const options = [];
            // Set Fits according to the requirement of AutoCompleteSelect component to render options.
            skus.map( item => {
                options.push( { value: item.skuid, label: item.skuname } );
            });
            setSkus( options ); // Set sku array in the state so it can call next component to render the options.
        });        
    }

    return(
        <div className="slt-filters" id="mainfilters">
            <h3 className="filter-title">Filter</h3>
            <div>
                <span className="b-ftr-inr">
                    <label>Gender</label>
                    <AutoCompleteSelect list={ genders } loading={ gendersLoading } callBack={ (a) => { setGenderId(a) } }/>
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Genders Category</label>
                    <AutoCompleteSelect list={ genderCategories } loading={ genderCategoriesLoading } callBack={ (a) => { setGenderCategoryId(a) } } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Fit Category</label>
                    <AutoCompleteSelect list={ fitCategories } loading={ fitCategoriesLoading } callBack={ (a) => { setFitCategoryId(a) } } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Rise Label</label>
                    <AutoCompleteSelect list={ rises }  loading={ risesLoading } callBack={ (a) => { setRiseId(a) } }/>
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>FIT/CUT</label>
                    <AutoCompleteSelect list={ fits } loading={ fitsLoading } callBack={ (a) => { setFitId(a) } }/>
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Style</label>
                    <AutoCompleteSelect list={ styles } loading={ stylesLoading } callBack={ (a) => { setStyleId(a) } }/>
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Inseam Label</label>
                    <AutoCompleteSelect list={ inseams } loading={ inseamsLoading } callBack={ (a) => { setInseamId(a) } }/>
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>SKU</label>
                    <AutoCompleteSelect list={ skus } loading={ skusLoading } callBack={ (a) => { setSkuId(a) } }/>            
                </span>
            </div>
        </div>
    );
}

export default ProductReportFilter;