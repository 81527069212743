import React, {useState} from 'react';
import Header from '../../../header/header';
import config from '../../../../config/config';
import BusinessBoardReportFilter from './businessBoardFilter';
import ReportDialog from '../../../common/reportDialog';
import {Redirect} from 'react-router';

const BusinessBoard = (props) => {
	const [closeReport, setCloseReport] = useState(false);
	const [formState, setFormState] = useState({});
	const [display, setDisplay] = useState(false);
	const [reportUrl, setReportUrl] = useState();
	const [pdfUrl, setPDFUrl] = useState();
	const SKU_URL = config.GET_ALL_URL;
	const [skuUrl, setSKUUrl] = useState(SKU_URL);
	const hyperLinksUrl = ['businessDashBoardDetail'];
	const popupTableTitle = [''];
	const hyperLinks = [1];

	function showTable(){
		let url = config.BUSINESS_LEADER_BOARD;
		let pdfUrl = config.SALE_PERSON_PDF_URL;
		setFormState({...formState});
		setReportUrl(url);
		setPDFUrl(pdfUrl);
		setDisplay(true);
	}

	return (
		<div>
			<Header />
			<div className="main-content one-column-layout-filter business-dashboard">
				<div className="report-container group">	
					<div className="report-container-header">
						<h1>Business Dashboard</h1>
						<button id="close-report-btn" onClick={() => setCloseReport(true)}>
							Close
						</button>
						{
							closeReport == true ? <Redirect to="/dashboard" /> :
							null}
					</div>
					<div className="report-container-inner">
						<div className="r-left">
							<BusinessBoardReportFilter
								filterData={(state) => {
									setFormState(state);
								}}
							/>
							<button
								style={{width: '25%', float: 'right'}}
								className="report-submit-btn"
								id="genreport"
								onClick={showTable}
							>
								SEARCH
							</button>
						</div>
					</div>
				</div>
				<ReportDialog
					sorter={false}
					hyperLinks={hyperLinks}
					hyperLinksUrl={hyperLinksUrl}
					popupTableTitle={popupTableTitle}
					tableHeight={630}
					TableClassName="business-dashboard"
					excelVersion="Specific"
					defaultPageSize={1000}
					skuUrl={skuUrl}
					display={display}
					callBack={() => {
						setDisplay(false);
					}}
					url={reportUrl}
					pdfUrl=""
					body={formState}
				/>{' '}
			</div>
		</div>
	);
};
export default BusinessBoard;