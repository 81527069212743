import React, {useState, useEffect} from 'react';
import Header from '../../header/header';
import DetailReportFilter from '../sku/skuWiseSearchFilter';
import config from '../../../config/config';
import Date from '../../common/date';
import moment from 'moment';
import ReportDialog from '../../common/reportDialog';
import {Redirect} from 'react-router';

const URL = config.CUMULATIVE_OTS;
const PDF_URL = config.PRODUCT_INVENTORY_PDF_URL;
const SKU_URL = config.GET_ALL_URL;

const DetailReport = () => {
	const [closeReport, setCloseReport] = useState(false);
	// Report filters
	const [searchState, setSearchState] = useState(false);
	const [formState, setFormState] = useState({});
	const [date, setDate] = useState({});
	const [display, setDisplay] = useState(false);
	const [reportUrl, setReportUrl] = useState();
	const [pdfUrl, setPDFUrl] = useState();
	const [skuUrl, setSKUUrl] = useState(SKU_URL);
	const [pdfReportId, setPdfReportId] = useState();
	const hyperLinks = [6];
	const hyperLinksUrl = [''];
	const popupTableTitle = [''];

	const columnAttributes = {
		'location': {
			tabs: true,
		}
	}

	function showTable(){
		formState.fromDate = moment().startOf('year').format('MM/DD/YYYY');
		formState.toDate = moment(date.fromDate).format('MM/DD/YYYY');
		setFormState({...formState});
		setPDFUrl(PDF_URL);
		setReportUrl(URL);
		setDisplay(true);
	}

	useEffect(
		() => {

			const button = document.querySelector('#genreport');
			if (formState.sku === undefined || formState.sku == '-1') {
				button.disabled = true;
				button.style.opacity = '0.5';
			}
			else {
				button.disabled = false;
				button.style.opacity = '1';
			}
		},
		[formState.sku]
	);

	return (
		<div>
			<Header />
			<div className="main-content stock-filter-layout">
				<div className="report-container group">
					<div className="report-container-header">
						<h1>Cumulative OTS</h1>
						<button id="close-report-btn" onClick={() => setCloseReport(true)}>
							Close
						</button>
						{
							closeReport == true ? <Redirect to="/dashboard" /> :
							null}
					</div>
					<div className="report-container-inner">
						<div className="r-left">
							<Date
								label="To"
								dateData={(date) => setDate(date)}
								defaultDate={{fromDate: moment().endOf('year').add(1, 'year').format('YYYY-MM-DD')}}
							/>

							<DetailReportFilter
								filterData={(state) => {
									setFormState(state);
								}}
							/>
							<button
								style={{
									width: '25%',
									float: 'right'
								}}
								className="report-submit-btn"
								id="genreport"
								onClick={showTable}
							>
								SEARCH
							</button>
						</div>
					</div>
				</div>
				<ReportDialog
					excelVersion="Specific"
					table="groupTable"
					columnAttributes={columnAttributes}
					popupNavigation={true}
					TableClassName="cumulative-report"
					// TableClassName="sku-lookup-report"
					defaultPageSize={100}
					display={display}
					callBack={() => {
						setDisplay(false);
					}}
					url={reportUrl}
					pdfUrl=""
					skuUrl={skuUrl}
					hyperLinks={hyperLinks}
					hyperLinksUrl={hyperLinksUrl}
					popupTableTitle={popupTableTitle}
					body={formState}
					pdfReportId={pdfReportId}
					popUpClassName='sku-lookup-table-popup'
				/>
			</div>
		</div>
	);
};
export default DetailReport;