import React, { useEffect, useState } from 'react';
import AutoCompleteSelect from '../../common/autoCompleteSelect';
import useDidMountEffect from '../../common/useDidMountEffect';
import config from '../../../config/config';
import moment from 'moment';

const LOCAL_URL = config.GET_ALL_URL

// const PRODUCT_STATUS = "&productStatus=";
// const IS_INACTIVE = "&isInActive="
let JWT;


const SalesReportFilter = ( props ) => {

    // Selected Ids from the options list in AutoCompleteSelect.
    const [ typeId, setTypeId ] = useState('-1'); // You can set useState(Your default value to be selected in list).
    const [ genderId, setGenderId ] = useState('-1');
    const [ genderCategoryId, setGenderCategoryId ] = useState('-1'); 
    const [ salesPersonId, setSalesPersonId ] = useState('-1');
    const [ regionId, setRegionId ] = useState('-1');
    const [ customerId, setCustomerId ] = useState('-1');
    const [ orderStatusId, setOrderStatusId ] = useState('-1')

    // Array that have data to sent to AutoCompleteSelect component to render options.
    const [ types, setTypes ] = useState([]);
    const [ genders, setGenders ] = useState([]);
    const [ gendersCategory, setGendersCategory ] = useState([]); 
    const [ salesPersons, setSalesPersons ] = useState([]);
    const [ regions, setRegions ] = useState([]);
    const [ customers, setCustomers ] = useState([]);
    const [ orders, setOrders ] = useState([]);
    
    // Loading booleans to be set on AutoComplete.
    const [ typeLoading, setTypeLoading ] = useState(true);
    const [ gendersLoading, setGendersLoading ] = useState(true);
    const [ gendersCategoryLoading, setGendersCategoryLoading ] = useState(true);
    const [ salesPersonLoading, setSalesPersonLoading ] = useState(true);
    const [ regionsLoading, setRegionsLoading ] = useState(true);
    const [ customerLoading, setCustomerLoading ] = useState(true);
    const [ ordersLoading, setOrdersLoading ] = useState(true);

    const [dataUrl, setDateUrl] = useState('');

    useState( () => {
        JWT = localStorage.getItem('access_token');
        setDateUrl(getDate());
     },[props.date]);

    function getNewState() {
        return { 
            genderId: genderId,
            genderCategoryId: genderCategoryId, 
            salesPersonId: salesPersonId, 
            regionId: regionId,
            typeId: typeId, 
            customerId: customerId, 
            statusId: orderStatusId
        };
    }
    
    useEffect( () => {
        const newState = {
            genderId: '-1', 
            genderCategoryId: '-1',
            salesPersonId: '-1', 
            regionId: '-1', 
            typeId: '-1',
            customerId: '-1',
            statusId: '-1'
        }
        getGenders(newState);
    },[ props.isInActive, props.date]);

    useEffect( () => {
        const newState = {
                genderId: '-1',
                genderCategoryId: '-1', 
                salesPersonId: '-1', 
                regionId: '-1',
                typeId: '-1', 
                customerId: '-1',
                statusId: '-1'
        };
        props.filterData(newState);
        getGenders(newState);
    },[ props.isEcom ]);

     /**
     * Send back data to the parent component i.e CustomerReport whenever selected option changes.
     */
    useDidMountEffect( () => {
        const newState = getNewState();
        props.filterData(newState);
    },[ genderId, genderCategoryId, salesPersonId, regionId, typeId, customerId, orderStatusId ]);

    useDidMountEffect( () => {
        const newState = {
            genderId: genderId,
            genderCategoryId: '-1', 
            salesPersonId: '-1', 
            regionId: '-1',
            typeId: '-1', 
            customerId: '-1', 
            statusId: '-1'
        }
        getGendersCategory(newState);
    }, [genderId]);

    useDidMountEffect( () => {
        const newState = {
            genderId: genderId, 
            genderCategoryId: genderCategoryId,
            salesPersonId: '-1', 
            regionId: '-1', 
            typeId: '-1', 
            customerId: '-1', 
            statusId: '-1'
        }
        getSalesPerson(newState);
    }, [genderCategoryId]);

    useDidMountEffect( () => {
        const newState = {
            genderId: genderId,
            genderCategoryId: genderCategoryId, 
            salesPersonId: salesPersonId, 
            regionId: '-1', 
            typeId: '-1', 
            customerId: '-1', 
            statusId: '-1'
        }
        getRegion(newState);
    }, [salesPersonId]);

    useDidMountEffect( () => {
        const newState = {
            genderId: genderId, 
            genderCategoryId: genderCategoryId,
            salesPersonId: salesPersonId, 
            regionId: regionId, 
            typeId: '-1', 
            customerId: '-1', 
            statusId: '-1'
        }
        getType(newState);
    }, [regionId]);

    useDidMountEffect( () => {
        const newState = {
            genderId: genderId,
            genderCategoryId: genderCategoryId, 
            salesPersonId: salesPersonId, 
            regionId: regionId,
            typeId: typeId, 
            customerId: '-1', 
            statusId: '-1'
        }
        getCustomer(newState);
    }, [typeId]);

    useDidMountEffect( () => {
        const newState = {
            genderId: genderId, 
            genderCategoryId: genderCategoryId,
            salesPersonId: salesPersonId, 
            regionId: regionId, 
            typeId: typeId, 
            customerId: customerId, 
            statusId: '-1'
        }
        getOrderStatus(newState);
    }, [customerId]);

    function getDate() {
        return "&fromDate=" + moment(props.date.fromDate).format('MM/DD/YYYY') + "&toDate=" + moment(props.date.toDate).format('MM/DD/YYYY');
    }

    function getGenders(state) {
        if(Object.entries(props.date).length === 0) return;
        setGendersLoading(true);
        fetch( LOCAL_URL + "saleOrderGenders" + getDate(),{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setGendersLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.genderid, label: item.gendername } );
            });
            setGenders( options ); // Set types array in the state so it can call next component to render the options.
        });
        getGendersCategory(state);
    }

    function getGendersCategory(state) {
        setGendersCategoryLoading(true);
        fetch( LOCAL_URL + "saleOrderGenderCategory" + getDate(),{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setGendersCategoryLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.gendercategoryid, label: item.gendercategoryname } );
            });
            setGendersCategory( options ); // Set types array in the state so it can call next component to render the options.
        });
        getSalesPerson(state);
    }

    function getSalesPerson(state) {
        setSalesPersonLoading(true);
        fetch( LOCAL_URL + "saleOrderSalesPerson" + getDate() ,{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setSalesPersonLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.salespersonid, label: item.salespersonname } );
            });
            setSalesPersons( options ); // Set types array in the state so it can call next component to render the options.
        });
        getRegion(state);
    }

    function getRegion(state) {
        setRegionsLoading(true);
        fetch( LOCAL_URL + "saleOrderRegion" + getDate(),{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setRegionsLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.regionid, label: item.regionname } );
            });
            setRegions( options ); // Set types array in the state so it can call next component to render the options.
        });
        getType(state);
    }

    function getType(state) {
        setTypeLoading(true);
        fetch( LOCAL_URL + "saleOrderCustomerType" + getDate(),{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setTypeLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.typeid, label: item.typename } );
            });
            setTypes( options ); // Set types array in the state so it can call next component to render the options.
        });
        getCustomer(state);
    }

    function getCustomer(state) {
        setCustomerLoading(true);
        fetch( LOCAL_URL + "saleOrderCustomers" + getDate(),{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setCustomerLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.customerid, label: item.customername } );
            });
            setCustomers( options ); // Set types array in the state so it can call next component to render the options.
        });
        getOrderStatus(state);
    }

    function getOrderStatus(state) {
        setOrdersLoading(true);
        fetch( LOCAL_URL + "saleOrderStatus" + getDate(),{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setOrdersLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.statusid, label: item.status } );
            });
            setOrders( options ); // Set types array in the state so it can call next component to render the options.
        });
    }

    return(
        <div className="slt-filters" id="mainfilters">
            <h3 className="filter-title">Filter</h3>
            <div>
                <span className="b-ftr-inr">
                    <label>Gender</label>
                    <AutoCompleteSelect list={ genders } loading={ gendersLoading } callBack={ (a) => { setGenderId(a) } } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Gender Category</label>
                    <AutoCompleteSelect list={ gendersCategory } loading={ gendersCategoryLoading } callBack={ (a) => { setGenderCategoryId(a) } } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Sales Person</label>
                    <AutoCompleteSelect list={ salesPersons } loading={ salesPersonLoading } callBack={ (a) => { setSalesPersonId(a) } } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Region</label>
                    <AutoCompleteSelect list={ regions } loading={ regionsLoading } callBack={ (a) => { setRegionId(a) } } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Type</label>
                    <AutoCompleteSelect list={ types } loading={ typeLoading } callBack={ (a) => { setTypeId(a) } } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Customer</label>
                    <AutoCompleteSelect list={ customers } loading={ customerLoading } callBack={ (a) => setCustomerId(a) } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Order Status</label>
                    <AutoCompleteSelect list={ orders } loading={ ordersLoading } callBack={ (a) => { setOrderStatusId(a) } } />
                </span>
            </div>
        </div>
    );
}

export default SalesReportFilter;