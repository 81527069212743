import React, { useEffect, useState } from 'react';
import AutoCompleteSelect from '../../../common/autoCompleteSelect';
import useDidMountEffect from '../../../common/useDidMountEffect';
import config from '../../../../config/config';

const LOCAL_URL = config.GET_ALL_URL

const PRODUCT_STATUS = "&productStatus=";
const IS_INACTIVE = "&isInActive="
let JWT;
const CustomerReportFilter = ( props ) => {

    // Selected Ids from the options list in AutoCompleteSelect.
    const [ typeId, setTypeId ] = useState('-1'); // You can set useState(Your default value to be selected in list).
    const [ genderId, setGenderId ] = useState('-1'); 
    const [ salesPersonId, setSalesPersonId ] = useState('-1');
    const [ regionId, setRegionId ] = useState('-1');
    const [ customerId, setCustomerId ] = useState('-1');
    const [ billingCityId, setBillingCityId ] = useState('-1');
    const [ stateId, setStateId ] = useState('-1');
    const [ billingCountryId, setBillingCountryId ] = useState('-1');
    const [ termsId, setTermsId ] = useState('-1');

    // Array that have data to sent to AutoCompleteSelect component to render options.
    const [ types, setTypes ] = useState([]);
    const [ genders, setGenders ] = useState([]); 
    const [ salesPersons, setSalesPersons ] = useState([]);
    const [ regions, setRegions ] = useState([]);
    const [ customers, setCustomers ] = useState([]);
    const [ billingCities, setBillingCities ] = useState([]);
    const [ states, setStates ] = useState([]);
    const [ billingCountries, setBillingCountries ] = useState([]);
    const [ terms, setTerms ] = useState([]);
    
    // Loading booleans to be set on AutoComplete.
    const [ typeLoading, setTypeLoading ] = useState(true);
    const [ gendersLoading, setGendersLoading ] = useState(true);
    const [ salesPersonLoading, setSalesPersonLoading ] = useState(true);
    const [ regionsLoading, setRegionsLoading ] = useState(true);
    const [ customerLoading, setCustomerLoading ] = useState(true);
    const [ citiesLoading, setCitiesLoading ] = useState(true);
    const [ statesLoading, setStatesLoading ] = useState(true);
    const [ countriesLoading, setCountriesLoading ] = useState(true);
    const [ termLoading, setTermLoading ] = useState(true);



    useState(() => {
        
        
        JWT = localStorage.getItem('access_token');
     },[]);

     function getNewState() {
        return { 
            typeId: typeId, 
            genderId: genderId, 
            salesPersonId: salesPersonId, 
            regionId: regionId, 
            customerId: customerId, 
            billCountryId: billingCountryId,
            billStateId: stateId,
            billCityId: billingCityId,
            termId: termsId,
        };
    }

    useEffect( () => {
        const newState = {
            typeId: '-1', 
            genderId: '-1', 
            salesPersonId: '-1', 
            regionId: '-1', 
            customerId: '-1', 
            billCountryId: '-1',
            billStateId: '-1',
            billCityId: '-1',
            termsId: '-1',
        }
        getType(newState);
    },[ props.isInActive, props.isEcom]);

     /**
     * Send back data to the parent component i.e CustomerReport whenever selected option changes.
     */
    useDidMountEffect( () => {
        const newState = getNewState();
        props.filterData(newState);
    },[ typeId, genderId, salesPersonId, regionId, customerId, billingCountryId, stateId, billingCityId, termsId ]);

    useDidMountEffect( () => {
        const newState = {
            typeId: typeId, 
            genderId: '-1', 
            salesPersonId: '-1', 
            regionId: '-1', 
            customerId: '-1', 
            billCountryId: '-1',
            billStateId: '-1',
            billCityId: '-1',
            termsId: '-1',
        }
        getGenders(newState);
    }, [typeId]);

    useDidMountEffect( () => {
        const newState = {
            typeId: typeId, 
            genderId: genderId, 
            salesPersonId: '-1', 
            regionId: '-1', 
            customerId: '-1', 
            billCountryId: '-1',
            billStateId: '-1',
            billCityId: '-1',
            termsId: '-1',
        }
        getSalesPerson(newState);
    }, [genderId]);

    useDidMountEffect( () => {
        const newState = {
            typeId: typeId, 
            genderId: genderId, 
            salesPersonId: salesPersonId, 
            regionId: '-1', 
            customerId: '-1', 
            billCountryId: '-1',
            billStateId: '-1',
            billCityId: '-1',
            termsId: '-1',
        }
        getRegion(newState);
    }, [salesPersonId]);

    useDidMountEffect( () => {
        const newState = {
            typeId: typeId, 
            genderId: genderId, 
            salesPersonId: salesPersonId, 
            regionId: regionId, 
            customerId: '-1', 
            billCountryId: '-1',
            billStateId: '-1',
            billCityId: '-1',
            termsId: '-1',
        }
        getCustomer(newState);
    }, [regionId]);

    useDidMountEffect( () => {
        const newState = {
            typeId: typeId, 
            genderId: genderId, 
            salesPersonId: salesPersonId, 
            regionId: regionId, 
            customerId: customerId, 
            billCountryId: '-1',
            billStateId: '-1',
            billCityId: '-1',
            termsId: '-1',
        }
        getBillingCountry(newState);
    }, [customerId]);

    useDidMountEffect( () => {
        const newState = {
            typeId: typeId, 
            genderId: genderId, 
            salesPersonId: salesPersonId, 
            regionId: regionId, 
            customerId: customerId, 
            billCountryId: billingCountryId,
            billStateId: '-1',
            billCityId: '-1',
            termsId: '-1',
        }
        getStates(newState);
    }, [billingCountryId]);

    useDidMountEffect( () => {
        const newState = {
            typeId: typeId, 
            genderId: genderId, 
            salesPersonId: salesPersonId, 
            regionId: regionId, 
            customerId: customerId, 
            billCountryId: billingCountryId,
            billStateId: stateId,
            billCityId: '-1',
            termsId: '-1',
        }
        getBillingCity(newState);
    }, [stateId]);

    useDidMountEffect( () => {
        const newState = {
            typeId: typeId, 
            genderId: genderId, 
            salesPersonId: salesPersonId, 
            regionId: regionId, 
            customerId: customerId, 
            billCountryId: billingCountryId,
            billStateId: stateId,
            billCityId: billingCityId,
            termsId: '-1',
        }
        getTerm(newState);
    }, [billingCityId]);

    // useDidMountEffect( () => {
    //     const newState = {
    //         typeId: typeId, 
    //         genderId: genderId, 
    //         salesPersonId: salesPersonId, 
    //         regionId: regionId, 
    //         customerId: customerId, 
    //         billCityId: billingCityId, 
    //         billCountryId: billingCountryId,
    //         termsId: termsId,
    //     }
    // }, [termsId]);

    function getType(state) {
        setTypeLoading(true);
        fetch( LOCAL_URL + "customerType" + IS_INACTIVE + props.isInActive ,{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setTypeLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.typeid, label: item.typename } );
            });
            setTypes( options ); // Set types array in the state so it can call next component to render the options.
        });
        getGenders(state);
    }

    function getGenders(state) {
        setGendersLoading(true);
        fetch( LOCAL_URL + "customerGenders" + IS_INACTIVE + props.isInActive ,{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setGendersLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.genderid, label: item.gendername } );
            });
            setGenders( options ); // Set types array in the state so it can call next component to render the options.
        });
        getSalesPerson(state);
    }

    function getSalesPerson(state) {
        setSalesPersonLoading(true);
        fetch( LOCAL_URL + "customerSalesPerson" + IS_INACTIVE + props.isInActive ,{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setSalesPersonLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.salespersonid, label: item.salespersonname } );
            });
            setSalesPersons( options ); // Set types array in the state so it can call next component to render the options.
        });
        getRegion(state);
    }

    function getRegion(state) {
        setRegionsLoading(true);
        fetch( LOCAL_URL + "customerRegion" + IS_INACTIVE + props.isInActive ,{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setRegionsLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.regionid, label: item.regionname } );
            });
            setRegions( options ); // Set types array in the state so it can call next component to render the options.
        });
        getCustomer(state);
    }

    function getCustomer(state) {
        setCustomerLoading(true);
        fetch( LOCAL_URL + "customerCustomers" + IS_INACTIVE + props.isInActive ,{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setCustomerLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.customerid, label: item.customername } );
            });
            setCustomers( options ); // Set types array in the state so it can call next component to render the options.
        });
        getBillingCountry(state);
    }

    function getBillingCountry(state) {
        setCountriesLoading(true);
        fetch( LOCAL_URL + "customerBillCountry" + IS_INACTIVE + props.isInActive ,{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setCountriesLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.billcountryid, label: item.billcountryname } );
            });
            setBillingCountries( options ); // Set types array in the state so it can call next component to render the options.
        });
        getStates(state);
    }

    function getStates(state) {
        setStatesLoading(true);
        fetch( LOCAL_URL + "customerBillState" + IS_INACTIVE + props.isInActive ,{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setStatesLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.billstateid, label: item.billstatename } );
            });
            setStates( options ); // Set types array in the state so it can call next component to render the options.
        });
        getBillingCity(state);
    }
    
    function getBillingCity(state) {
        setCitiesLoading(true);
        fetch( LOCAL_URL + "customerBillCity" + IS_INACTIVE + props.isInActive ,{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setCitiesLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.billcityid, label: item.billcityname } );
            });
            setBillingCities( options ); // Set types array in the state so it can call next component to render the options.
        });
        getTerm(state);
    }
    function getTerm(state) {
        setTermLoading(true);
        fetch( LOCAL_URL + "customerTerms" + IS_INACTIVE + props.isInActive ,{ method: 'POST', headers: { 'Authorization': JWT ,'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(state)} )
        .then( res => res.json() )
        .then( result => {
            setTermLoading( false );
            let options = [];
            // Set Items according to the requirement of AutoCompleteSelect component to render options.
            result.forEach( item => {
                options.push( { value: item.termid, label: item.termname } );
            });
            setTerms( options ); // Set types array in the state so it can call next component to render the options.
        });
    }

    return(
        <div className="slt-filters" id="mainfilters">
            <h3 className="filter-title">Filter</h3>
            <div>
                <span className="b-ftr-inr">
                    <label>Type</label>
                    <AutoCompleteSelect list={ types }  loading={ typeLoading } callBack={ (a) => { setTypeId(a) } } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Gender</label>
                    <AutoCompleteSelect  list={ genders } loading={ gendersLoading } callBack={ (a) => { setGenderId(a) } } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Sales Person</label>
                    <AutoCompleteSelect list={ salesPersons } loading={ salesPersonLoading } callBack={ (a) => { setSalesPersonId(a) } } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Region</label>
                    <AutoCompleteSelect  list={ regions } loading={ regionsLoading } callBack={ (a) => { setRegionId(a) } } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Customer</label>
                    <AutoCompleteSelect list={ customers } loading={ customerLoading } callBack={ (a) => setCustomerId(a) } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Billing Country</label>
                    <AutoCompleteSelect  list={ billingCountries } loading={ countriesLoading } callBack={ (a) => { setBillingCountryId(a) } } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Billing State</label>
                    <AutoCompleteSelect  list={ states } loading={ statesLoading } callBack={ (a) => { setStateId(a) } } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Billing City</label>
                    <AutoCompleteSelect  list={ billingCities } loading={ citiesLoading } callBack={ (a) => { setBillingCityId(a) } } />
                </span>
            </div>
            <div>
                <span className="b-ftr-inr">
                    <label>Terms</label>
                    <AutoCompleteSelect  list={ terms } loading={ termLoading } callBack={ (a) => { setTermsId(a) } } />
                </span>
            </div>
        </div>
    );
}

export default CustomerReportFilter;