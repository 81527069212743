import React from 'react';

const Active = (props) => {

    /**
     * @param event // Event object.
     * Send back id of the selected radio button to its parent component. 
     */
    function returnData( event ) {
        props.sendStatus( event.target.id );
    }

    return(
        <div className="status-filter">
            <h3 className="filter-title">Active</h3>
            <div className="status-buttons">
                <div className="btns-main">
                    <form>
                        <span className="st-btns">
                            <label><input id="active-1" type="radio" name="isInActive" className="isecom" onChange={ returnData } defaultChecked={ true }/><span>Yes</span></label>
                        </span>
                        <span className="st-btns">
                            <label><input id="active-2" type="radio" name="isInActive" className="isecom" onChange={ returnData }/><span>No</span></label>
                        </span>
                    </form>
                </div> 
            </div>
        </div>
    );
}


export default Active;