import React, {useEffect, useState} from "react";
import Header from "./../../../header/header";
import config from "./../../../../config/config";
import {NavLink} from "react-router-dom";
import {Redirect} from "react-router";
import moment from "moment";
import Table from "./../../../common/HTMLTable";
import {getExcelStandard} from "../../../../helper/excel";
import {
  montFromDate,
  monthToDate,
  yearFromDate,
  yearToDate,
} from "../../../../helper/date";

let JWT;
let fromDate;
let toDate;

const BookingByFitStyle = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [closeReport, setCloseReport] = useState(false);
  const [reportName, setReportName] = useState(props.reportName);
  const [reportType, setReportType] = useState(props.reportType);
  const [reportNumber, setReportNumber] = useState(props.reportNumber);
  const [genderId, setGenderId] = useState(props.genderId);
  const [formState, setFormState] = useState({genderId: genderId});
  let URL = config.BOOKING_BY_FIT_STYLE;

  if (props.querytype == "all fit style top sku") {
    URL = config.BOOKING_BY_FIT_STYLE_TOP_SKU;
  }

  if (props.querytype == "all fit style top size") {
    URL = config.BOOKING_BY_FIT_STYLE_TOP_SIZE;
  }

  const columnAttributes = {
    style: {
      link: true,
      tabs: false,
      endPoint: "dashboardBookingByFitStyleDetail",
      title: props.reportName + " - Detail",
      reportNumber: props.reportNumber,
      popup: {
        orderno: {
          link: true,
          tabs: false,
          endPoint: "dashboardBookingBySPOrderDetail",
          title: "Order Detail",
          reportNumber: props.reportNumber,
        },
      },
    },
  };

  useEffect(() => {
    JWT = localStorage.getItem("access_token");
    if (props.reportType) fetchData();
  }, [props]);

  async function fetchData() {
    setLoading(true);
    fromDate = reportType == "ytd" ? yearFromDate() : montFromDate();
    toDate = reportType == "ytd" ? yearToDate() : monthToDate();
    let url = URL;

    setFormState({
      fromDate: fromDate,
      toDate: toDate,
    });

    url +=
      "&fromDate=" +
      moment(fromDate).format("MM/DD/YYYY") +
      "&toDate=" +
      moment(toDate).format("MM/DD/YYYY");

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: JWT,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formState),
    });

    const data1 = await response.json();
    data1.reportName = "Booking Leaderboard - MTD";
    setData(data1);
    setLoading(false);
  }

  return (
    <div>
      <Header />
      <div className="dash-body ecommerce-dashbaord">
        <div style={{paddingLeft: "0%", paddingRight: "0%", marginTop: "-2%"}}>
          <div className="report-result-head">
            <h2>{reportName}</h2> <br />
            <h2>{reportNumber}</h2>
            <div className="navigation-area">
              <NavLink
                activeClassName="selected"
                className="link-react"
                to="bookings-by-fit-style-ytd"
              >
                ALL-FITSTYLE-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="bookings-by-fit-style-women-ytd"
              >
                W-FITSTYLE-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="bookings-by-fit-style-men-ytd"
              >
                M-FITSTYLE-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="bookings-by-fit-style-kids-ytd"
              >
                K-FITSTYLE-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="bookings-by-fit-style-pets-ytd"
              >
                P-FITSTYLE-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="bookings-by-fit-style-top-sku-women-ytd"
              >
                W-TOPSKU-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="bookings-by-fit-style-top-sku-men-ytd"
              >
                M-TOPSku-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="bookings-by-fit-style-top-sku-kids-ytd"
              >
                K-TOPSKU-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="bookings-by-fit-style-top-sku-pets-ytd"
              >
                P-TOPSKU-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react "
                to="bookings-by-fit-style-top-size-women-ytd"
              >
                W-TOPSIZE-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="bookings-by-fit-style-top-size-men-ytd"
              >
                M-TOPSIZE-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="bookings-by-fit-style-top-size-kids-ytd"
              >
                K-TOPSIZE YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="bookings-by-fit-style-top-size-pets-ytd"
              >
                P-TOPSize-YTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="bookings-by-fit-style-mtd"
              >
                ALL-FITSTYLE-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="bookings-by-fit-style-women-mtd"
              >
                W-FITSTYLE-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="bookings-by-fit-style-men-mtd"
              >
                M-FITSTYLE-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="bookings-by-fit-style-kids-mtd"
              >
                K-FITSTYLE-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="bookings-by-fit-style-pets-mtd"
              >
                P-FITSTYLE-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react "
                to="bookings-by-fit-style-top-sku-women-mtd"
              >
                W-TOPSKU-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="bookings-by-fit-style-top-sku-men-mtd"
              >
                M-TOPSKU-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="bookings-by-fit-style-top-sku-kids-mtd"
              >
                K-TOPSKU-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="bookings-by-fit-style-top-sku-pets-mtd"
              >
                P-TOPSKU-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react "
                to="bookings-by-fit-style-top-size-women-mtd"
              >
                W-TOPSIZE-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="bookings-by-fit-style-top-size-men-mtd"
              >
                M-TOPSIZE-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="bookings-by-fit-style-top-size-kids-mtd"
              >
                K-TOPSIZE-MTD
              </NavLink>

              <NavLink
                activeClassName="selected"
                className="link-react"
                to="bookings-by-fit-style-top-size-pets-mtd"
              >
                P-TOPSIZE-MTD
              </NavLink>
            </div>
            {props.reportType ? (
              <div className="report-result-right-top">
                <button
                  id="close-dialog-btn"
                  onClick={() => setCloseReport(true)}
                >
                  Close
                </button>
                <button
                  id="download-excel-button"
                  onClick={() => {
                    getExcelStandard(data);
                  }}
                >
                  Download EXCEL
                </button>
                {closeReport == true ? <Redirect to="/dashboard" /> : null}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="report-data">
            {props.reportType ? (
              <Table
                body={formState}
                display={true}
                columnAttributes={columnAttributes}
                data={data}
                loading={loading}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default BookingByFitStyle;
